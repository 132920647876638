import { IMeta } from 'common/common.interface'
import { IReceipt } from './receipts.interface'

export const ReceiptDataDummy: IReceipt[] = [
    {
        customerRef: 'CR-001',
        warehouseName: 'HarborView Warehouse',
        docketID: 'DK-01',
        bookingDate: '03-05-2024',
        arrivalDate: '17-05-2024',
        docketStatus: 'Pending',
        totalUnits: '100 units',
        totalPallets: '100',
        finalisedDate: '17-05-2024',
        serviceLevel: 'Standard',
    },
    {
        customerRef: 'CR-002',
        warehouseName: 'Summit Storage Solutions',
        docketID: 'DK-02',
        bookingDate: '15-06-2024',
        arrivalDate: '29-06-2024',
        docketStatus: 'Processing',
        totalUnits: '250 units',
        totalPallets: '250',
        finalisedDate: '29-06-2024',
        serviceLevel: 'Expedity',
    },
    {
        customerRef: 'CR-003',
        warehouseName: 'Riverside Logistics Center',
        docketID: 'DK-03',
        bookingDate: '21-07-2024',
        arrivalDate: '05-08-2024',
        docketStatus: 'In Transit',
        totalUnits: '500 units',
        totalPallets: '500',
        finalisedDate: '05-08-2024',
        serviceLevel: 'Economy',
    },
    {
        customerRef: 'CR-004',
        warehouseName: 'MetroWare Warehouse',
        docketID: 'DK-04',
        bookingDate: '09-08-2024',
        arrivalDate: '24-09-2024',
        docketStatus: 'On Hold',
        totalUnits: '75 units',
        totalPallets: '75',
        finalisedDate: '24-09-2024',
        serviceLevel: 'Priority',
    },
    {
        customerRef: 'CR-005',
        warehouseName: 'Valley Vista Warehouse',
        docketID: 'DK-05',
        bookingDate: '28-09-2024',
        arrivalDate: '13-11-2024',
        docketStatus: 'Delivered',
        totalUnits: '200 units',
        totalPallets: '200',
        finalisedDate: '13-11-2024',
        serviceLevel: 'Next-Day',
    },
    {
        customerRef: 'CR-006',
        warehouseName: 'Coastal Cargo Warehouse',
        docketID: 'DK-06',
        bookingDate: '10-10-2024',
        arrivalDate: '25-11-2024',
        docketStatus: 'Completed',
        totalUnits: '150 units',
        totalPallets: '150',
        finalisedDate: '25-11-2024',
        serviceLevel: 'Same-Day',
    },
    {
        customerRef: 'CR-007',
        warehouseName: 'Prime Storage Facility',
        docketID: 'DK-07',
        bookingDate: '05-11-2024',
        arrivalDate: '20-12-2024',
        docketStatus: 'Cancelled',
        totalUnits: '300 units',
        totalPallets: '300',
        finalisedDate: '20-12-2024',
        serviceLevel: 'Overnight',
    },
    {
        customerRef: 'CR-008',
        warehouseName: 'UrbanHub Distribution Center',
        docketID: 'DK-08',
        bookingDate: '17-12-2024',
        arrivalDate: '02-02-2025',
        docketStatus: 'Shipped',
        totalUnits: '400 units',
        totalPallets: '400',
        finalisedDate: '02-02-2025',
        serviceLevel: 'Rush',
    },
    {
        customerRef: 'CR-009',
        warehouseName: 'Skyline Storage Depot',
        docketID: 'DK-09',
        bookingDate: '22-01-2025',
        arrivalDate: '25-03-2025',
        docketStatus: 'Received',
        totalUnits: '50 units',
        totalPallets: '50',
        finalisedDate: '25-03-2025',
        serviceLevel: 'Basic',
    },
    {
        customerRef: 'CR-010',
        warehouseName: 'Pioneer Warehouse Solutions',
        docketID: 'DK-10',
        bookingDate: '14-02-2025',
        arrivalDate: '16-04-2025',
        docketStatus: 'Ready for Pickup',
        totalUnits: '300 units',
        totalPallets: '300',
        finalisedDate: '16-04-2025',
        serviceLevel: 'Premium',
    },
    {
        customerRef: 'CR-011',
        warehouseName: 'Evergreen Logistics Hub',
        docketID: 'DK-11',
        bookingDate: '26-03-2025',
        arrivalDate: '28-05-2025',
        docketStatus: 'Delayed',
        totalUnits: '400 units',
        totalPallets: '400',
        finalisedDate: '28-05-2025',
        serviceLevel: 'Express',
    },
    {
        customerRef: 'CR-012',
        warehouseName: 'Horizon Storage Services',
        docketID: 'DK-12',
        bookingDate: '08-04-2025',
        arrivalDate: '09-07-2025',
        docketStatus: 'Out for Delivery',
        totalUnits: '50 units',
        totalPallets: '50',
        finalisedDate: '09-07-2025',
        serviceLevel: 'Ground',
    },
    {
        customerRef: 'CR-013',
        warehouseName: 'Unity Warehouse Complex',
        docketID: 'DK-13',
        bookingDate: '19-05-2025',
        arrivalDate: '20-08-2025',
        docketStatus: 'Returned',
        totalUnits: '350 units',
        totalPallets: '350',
        finalisedDate: '20-08-2025',
        serviceLevel: 'Scheduled',
    },
    {
        customerRef: 'CR-014',
        warehouseName: 'SwiftStock Warehousing Solutions',
        docketID: 'DK-14',
        bookingDate: '13-07-2025',
        arrivalDate: '25-08-2025',
        docketStatus: 'In Transit',
        totalUnits: '600 units',
        totalPallets: '600',
        finalisedDate: '25-08-2025',
        serviceLevel: 'Standard',
    },
    {
        customerRef: 'CR-015',
        warehouseName: 'PeakLogix Distribution Center',
        docketID: 'DK-15',
        bookingDate: '05-08-2025',
        arrivalDate: '13-09-2025',
        docketStatus: 'Delayed',
        totalUnits: '125 units',
        totalPallets: '125',
        finalisedDate: '13-09-2025',
        serviceLevel: 'Expedity',
    },
]


export const containerData = [
    {
        containerNumber: 'C123456',
        sealNumber: 'S987654',
        type: '20ft',
        isPalletised: 'Yes',
        chargeable: 'Yes',
        items: '100',
        pallets: '10',
    },
    {
        containerNumber: 'C654321',
        sealNumber: 'S123456',
        type: '40ft',
        isPalletised: 'No',
        chargeable: 'No',
        items: '200',
        pallets: '0',
    },
    {
        containerNumber: 'C112233',
        sealNumber: 'S223344',
        type: '20ft',
        isPalletised: 'Yes',
        chargeable: 'Yes',
        items: '150',
        pallets: '15',
    },
    {
        containerNumber: 'C445566',
        sealNumber: 'S334455',
        type: '40ft',
        isPalletised: 'No',
        chargeable: 'Yes',
        items: '300',
        pallets: '20',
    },
    {
        containerNumber: 'C778899',
        sealNumber: 'S445566',
        type: '20ft',
        isPalletised: 'Yes',
        chargeable: 'No',
        items: '120',
        pallets: '12',
    },
];


export const linesData = [
    {
        partNumber: 'PN123456',
        description: 'Widget A',
        productCode: 'WIDG-A',
        packUQ: 'Box',
        quantity: '50',
        totalQuantity: '500',
    },
    {
        partNumber: 'PN789012',
        description: 'Widget B',
        productCode: 'WIDG-B',
        packUQ: 'Crate',
        quantity: '30',
        totalQuantity: '300',
    },
    {
        partNumber: 'PN345678',
        description: 'Gadget C',
        productCode: 'GADG-C',
        packUQ: 'Pallet',
        quantity: '20',
        totalQuantity: '200',
    },
    {
        partNumber: 'PN901234',
        description: 'Gadget D',
        productCode: 'GADG-D',
        packUQ: 'Box',
        quantity: '40',
        totalQuantity: '400',
    },
    {
        partNumber: 'PN567890',
        description: 'Device E',
        productCode: 'DEVI-E',
        packUQ: 'Carton',
        quantity: '25',
        totalQuantity: '250',
    },
];

export const reletedInvoiceData = [
    {
        transactionType: 'Purchase',
        terms: 'Net 30',
        invoicesDate: '2023-01-15',
        dueDate: '2023-02-14',
        fullyPaidDate: '2023-02-10',
        canceled: 'No',
        ledger: 'Accounts Payable',
    },
    {
        transactionType: 'Sale',
        terms: 'Net 45',
        invoicesDate: '2023-03-01',
        dueDate: '2023-04-15',
        fullyPaidDate: '2023-04-10',
        canceled: 'No',
        ledger: 'Accounts Receivable',
    },
    {
        transactionType: 'Service',
        terms: 'Net 60',
        invoicesDate: '2023-05-10',
        dueDate: '2023-07-09',
        fullyPaidDate: '2023-07-05',
        canceled: 'No',
        ledger: 'Service Revenue',
    },
    {
        transactionType: 'Return',
        terms: 'Net 15',
        invoicesDate: '2023-06-20',
        dueDate: '2023-07-05',
        fullyPaidDate: '2023-07-02',
        canceled: 'Yes',
        ledger: 'Sales Returns',
    },
    {
        transactionType: 'Adjustment',
        terms: 'Net 30',
        invoicesDate: '2023-07-01',
        dueDate: '2023-07-31',
        fullyPaidDate: '',
        canceled: 'No',
        ledger: 'Inventory Adjustments',
    },
];


export const metaDummy: IMeta = {
    current_page: 1,
    last_page: 1,
    from: 1,
    to: 50,
    total_Items: 15,
    per_page: 0,
    total_page: 1,
    index_end: 15,
    index_start: 1,
}
