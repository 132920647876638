import { ITabItem } from "components/tab/tab.interface"
import { useState } from "react"
import { useNavigate } from "react-router-dom"

const useQuotationDetails = () => {
    const navigate = useNavigate()
    const [tabItems, setTabItems] = useState<ITabItem[]>()
    const [tabFilter, setTabFilter] = useState<ITabItem>({
        label: 'Quotation Details',
        totalData: 0,
        key: 'status',
        value: 'quotationDetails',
        className: 'brand',
    })

    return { tabItems, tabFilter, navigate, setTabFilter, setTabItems }
}

export default useQuotationDetails