import Button from "components/button/button.component"
import SimpleTable from "components/simple-table/simple-table.component"
import { useProductsDetails } from "../products-details.service"
import { documentsData, metaDummy } from "../../products.dummy"
import { IDocumentsProduct } from "../../products.interface"
import { useTranslation } from "react-i18next"

const Documents = () => {
    const { t } = useTranslation()
    const {
        allModalDocuments,
        headersDocuments,
        ConfigureColumnsModalServiceDocuments,
    } = useProductsDetails()

    return (
        <div className="h-[calc(100vh-13.5rem)]">
            <SimpleTable<IDocumentsProduct>
                headers={headersDocuments}
                data={documentsData}
                usePagination
                nextHandling={(page) => {
                    console.log('hit Next API -> ' + page)
                }}
                previousHandling={(page) => {
                    console.log('hit Prev API -> ' + page)
                }}
                meta={metaDummy}
                additionalButtonBottom={
                    <>
                        <Button
                            onClick={() => ConfigureColumnsModalServiceDocuments.openModalHandling()}
                            label={t('additional.configure_column')}
                            className="w-[10rem] ml-0"
                            variant="brand-inverse"
                        />
                    </>
                }
            />
            {allModalDocuments}
        </div>
    )
}

export default Documents
