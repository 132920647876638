/* eslint-disable no-unused-vars */
import { useState } from 'react'

interface IPills<T = {}> {
    items: IPillItem<T>[]
    onChange: (data: IPillItem<T>) => void
    unActiveVariant?: 'white' | 'gray'
}

export interface IPillItem<T = {}> {
    label: string
    value: keyof T
}

const Pills = <T,>({ items, onChange, unActiveVariant }: IPills<T>) => {
    const [activeIndex, setActiveIndex] = useState<number>(0)
    const bgVariantUnActive =
        unActiveVariant === 'white' ? 'themes-bg-white' : 'themes-bg-gray-v2'

    return (
        <div className="pills overflow-auto" style={{ scrollbarWidth: 'none' }}>
            <div className="p-3 flex gap-x-2 w-max">
                {items?.map((x, idx: number) => {
                    const isActive =
                        activeIndex === idx
                            ? 'themes-bg-brand-v5 themes-text-white'
                            : bgVariantUnActive
                    return (
                        <div
                            key={`pill-${idx}`}
                            className={`${isActive} p-2 cursor-pointer rounded-full z-[0]`}
                            onClick={() => {
                                setActiveIndex(idx)
                                onChange(x)
                            }}
                        >
                            {x?.label}
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default Pills
