import Button from "components/button/button.component"
import SimpleTable from "components/simple-table/simple-table.component"
import { useReceiptsDetails } from "../receipts-details.service"
import { metaDummy } from "../../receipts.dummy"
import { documentsData } from "pages/ifb/products/products.dummy"
import { IDocumentsReceipts } from "../../receipts.interface"

const Documents = () => {
    const {
        allModalDocuments,
        headersDocuments,
        ConfigureColumnsModalServiceDocuments,
    } = useReceiptsDetails()

    return (
        <div className="h-[calc(100vh-13.5rem)]">
            <SimpleTable<IDocumentsReceipts>
                headers={headersDocuments}
                data={documentsData}
                usePagination
                nextHandling={(page) => {
                    console.log('hit Next API -> ' + page)
                }}
                previousHandling={(page) => {
                    console.log('hit Prev API -> ' + page)
                }}
                meta={metaDummy}
                additionalButtonBottom={
                    <>
                        <Button
                            onClick={() => ConfigureColumnsModalServiceDocuments.openModalHandling()}
                            label="Configure Column"
                            className="w-[10rem] ml-0"
                            variant="brand-inverse"
                        />
                    </>
                }
            />
            {allModalDocuments}
        </div>
    )
}

export default Documents
