import { IMeasurement } from 'components/measurement-input/measurement-input.interface'
import * as Yup from 'yup'

// interface for validation
export interface ICreateBokingValidation {
    consignorPickupAddress: {
        company: string
        street: string
        additionalStreet: string
        city: string
        state: string
        postcode: string
        country: string
        contactName: string
        contactEmailAddress: string
        contactPhone: string
        contactMobile: string
    }
    consigneePickupAddress: {
        company: string
        street: string
        additionalStreet: string
        city: string
        state: string
        postcode: string
        country: string
        contactName: string
        contactEmailAddress: string
        contactPhone: string
        contactMobile: string
    }
    originPort: string
    pickupRequestedBy: string
    pickupEquipmentNeeded: string
    destinationPort: string
    deliveryRequestedBy: string
    deliveryEquipmentNeeded: string
    shippersRef: string
    serviceLevel: string
    incoTerm: string
    additionalTerms: string
    goodsValue: string
    goodsValueCurrency: string
    insuranceValue: string
    insuranceValueCurrency: string
    packs: string
    outerPackUnit: string
    volumeMeasure: {
        qty: string
        unit: string
    }
    weightMeasure: {
        qty: string
        unit: string
    }
    goodsDescription: string
    marksAndNumbers: string
}

export interface ICreateBookingPackLinesValidation {
    id: number
    packs: string
    packageType?: string
    lengthMeasure: IMeasurement
    widthMeasure: IMeasurement
    heightMeasure: IMeasurement
    weightMeasure: IMeasurement
    volumeMeasure: IMeasurement
    goodsDescription: string
}

// form validation YUP for formik
export const createBookingValidation = Yup.object().shape({
    consignorPickupAddress: Yup.object().shape({
        company: Yup.string().required('Company is required'),
    }),
    consigneePickupAddress: Yup.object().shape({
        company: Yup.string().required('Company is required'),
    }),
})

export const createBookingPackLinesValidation = Yup.object().shape({
    packs: Yup.string().required('Packs is required'),
})

// form login initial data
export const createBookingInitialValue: ICreateBokingValidation = {
    consignorPickupAddress: {
        company: '',
        street: '',
        additionalStreet: '',
        city: '',
        state: '',
        postcode: '',
        country: '',
        contactName: '',
        contactEmailAddress: '',
        contactPhone: '',
        contactMobile: '',
    },
    consigneePickupAddress: {
        company: '',
        street: '',
        additionalStreet: '',
        city: '',
        state: '',
        postcode: '',
        country: '',
        contactName: '',
        contactEmailAddress: '',
        contactPhone: '',
        contactMobile: '',
    },
    originPort: '',
    pickupRequestedBy: '',
    pickupEquipmentNeeded: '',
    destinationPort: '',
    deliveryRequestedBy: '',
    deliveryEquipmentNeeded: '',
    shippersRef: '',
    serviceLevel: '',
    incoTerm: '',
    additionalTerms: '',
    goodsValue: '0.0000',
    goodsValueCurrency: '',
    insuranceValue: '0.0000',
    insuranceValueCurrency: '',
    packs: '0',
    outerPackUnit: '',
    volumeMeasure: {
        qty: '0.000',
        unit: 'M3',
    },
    weightMeasure: {
        qty: '0.000',
        unit: 'KG',
    },
    goodsDescription: '',
    marksAndNumbers: '',
}

export const createBookingPackLinesInitialValue: ICreateBookingPackLinesValidation =
    {
        id: 1,
        packs: '',
        packageType: '',
        lengthMeasure: {
            qty: '0',
            unit: 'M',
        },
        widthMeasure: {
            qty: '0',
            unit: 'M',
        },
        heightMeasure: {
            qty: '0',
            unit: 'M',
        },
        weightMeasure: {
            qty: '0',
            unit: 'KG',
        },
        volumeMeasure: {
            qty: '0',
            unit: 'M3',
        },
        goodsDescription: '',
    }
