interface ISortButton {
    sortOrder: 'asc' | 'desc'
    columnKey: string
    sortKey: string

    // eslint-disable-next-line no-unused-vars
    onClick: (accessor: string) => void
}

const SortButton = ({
    sortOrder,
    columnKey,
    sortKey,
    onClick,
}: ISortButton) => {
    const isActive = sortKey === columnKey
    return (
        <button
            onClick={() => onClick(columnKey)}
            className={`sort-button ${!isActive
                ? 'themes-border-color'
                : 'themes-border-brand-v5'
                }`}
        >
            <i
                className={`${sortKey !== columnKey
                    ? 'ri-subtract-line'
                    : sortKey === columnKey && sortOrder === 'desc'
                        ? 'ri-arrow-down-s-line active'
                        : ''
                    }`}
            ></i>
            <i
                className={`${sortKey !== columnKey
                    ? ''
                    : sortKey === columnKey && sortOrder === 'desc'
                        ? ''
                        : 'ri-arrow-up-s-line active'
                    }`}
            ></i>
        </button>
    )
}

export default SortButton
