export const commonEndpoints = {
    login: 'Authentication',
    country: 'Country',
    organization: 'Organization',
    staff_get: 'Staff',
    staff_getAllStatus: 'Staff/CountByStatus',
    staff_create: 'Staff',
    staff_update: 'Staff',
    staff_delete: 'Staff/delete',
    staff_unsuspend: 'Staff/unsuspended',
    staff_updateModule: 'Staff/updateModuleAccess',
    staff_forgotPassword: 'Staff/forgot-password',
    staff_resetPassword: 'Staff/reset-password',
    staff_changePassword: 'Staff/ChangePassword',
    role_getAll: 'role',
    role_getAllModule: 'role/ModuleByRole/',
    staff_current: 'Staff/CurrentStaff',
    activity_logs: 'Staff/activities',
}
