import AvatarCircle from 'components/avatar-circle/avatar-circle.component'
import Button from 'components/button/button.component'
import Dropdown from 'components/dropdown/dropdown.component'
import Textarea from 'components/text-area/text-area.component'
import { useTranslation } from 'react-i18next'

const Messages = () => {
    const { t } = useTranslation()
    return (
        <div className="p-3 flex gap-3">
            <div className="w-1/4 flex flex-col gap-3">
                <Textarea label={t('bookings.messages')} containerClassName="h-[165px]" />
                <Button
                    variant="brand-inverse"
                    label={t('action.send')}
                    onClick={() => { }}
                    className="w-full"
                />
            </div>
            <div className="w-1/4 flex flex-col gap-3">
                <Dropdown
                    options={[
                        {
                            label: 'Jane Doe',
                            value: 'Jane Doe',
                        },
                    ]}
                    label={t('bookings.added')}
                    onClick={() => { }}
                    placeholder="Select here"
                    isClearable={true}
                />
                <div className="flex justify-between p-3 items-center border rounded">
                    <div className="flex gap-3 items-center">
                        <AvatarCircle name={'Jane Doe'} />
                        <div>
                            Jane Doe{' '}
                            <span className="themes-text-gray-v3">You</span>
                        </div>
                    </div>
                    <i className="ri-delete-back-2-line" />
                </div>
            </div>
        </div>
    )
}

export default Messages
