import { IDropdownItem } from "components/dropdown/dropdown.interface"
import { IMeta } from "common/common.interface";
import { ITransit } from "./performances.interface";
import { faker } from "@faker-js/faker"

export const optionDates =
    [
        {
            value: 'yesterday',
            label: 'Yesterday',
        },
        {
            value: 'week',
            label: 'Last Week',
        },
        {
            value: '7D',
            label: 'Last 7 Days',
        },
        {
            value: '14D',
            label: 'Last 14 Days',
        },
        {
            value: 'last',
            label: 'Last Month',
        },
        {
            value: 'Month',
            label: 'Last Calendar Month',
        },
        {
            value: '2',
            label: 'Last 2 Mths.',
        },
        {
            value: '3',
            label: 'Last 3 Mths.',
        },
        {
            value: '6',
            label: 'Last 6 Mths.',
        },
        {
            value: '12',
            label: 'Last 12 Mths.',
        },
        {
            value: 'past',
            label: 'In the Past',
        },
    ]


export const optionDisplay: IDropdownItem[] =
    [
        {
            value: 'sea',
            label: 'SEA',
        },
        {
            value: 'land',
            label: 'LAND',
        },
        {
            value: 'air',
            label: 'AIR',
        },
    ]

export const PeformanceSummary = [
    {
        title: "Number of Shipments",
        total: "121,000",
        isIncrease: true,
        summaryTotal: '+120',
        icon: "ri-truck-line",
    },
    {
        title: "Average Delay",
        total: "7,0",
        isIncrease: false,
        summaryTotal: '-7,548',
        icon: "ri-ship-2-line",
    },
    {
        title: "On Time",
        total: "30%",
        isIncrease: false,
        summaryTotal: "-9,802",
        icon: "ri-box-3-line",
    },
    {
        title: "1 Week Delay",
        total: "31%",
        isIncrease: true,
        summaryTotal: "+1,536",
        icon: "ri-building-4-line",
    },
    {
        title: "1 Week + Delay",
        total: "39%",
        isIncrease: true,
        summaryTotal: "+1,410",
        icon: "ri-building-3-line",
    },
    {
        title: "1 Month Delay",
        total: "40",
        isIncrease: true,
        summaryTotal: "+1,410",
        icon: "ri-building-3-line",
    },
];


export const progressDataChart = [
    {
        value: 4,
        color: 'brand-v2',
        textTopRight: 'Gete In',
        textBotRight: 'Empty Pickup',
    },
    {
        value: 8,
        color: 'brand-v3',
        textBotRight: 'Loaded',
    },
    {
        value: 0.5,
        color: 'brand-v4',
        textTop: 'Vassel Depature',
    },
    {
        value: 36,
        color: 'brand-v5',
        textTopLeft: 'Vassel Depature',
        textTopRight: 'Vassel Arival',
    },
    {
        value: 1,
        color: 'gray-v8'
    },
    {
        value: 7,
        color: 'gray-v6',
        textTopRight: 'Gate Out',
        textBotLeft: 'Unloaded',
    },
    {
        value: 5,
        color: 'gray-v4',
        textBotRight: 'Empty Return',
    },
]

export const DataDummy: ITransit[] = [
    {
        description: "Ho Chi Minh, Vietnam",
        arivalPort: "Hamburg, Germany",
        totalShipment: faker.number.int({ min: 3, max: 30 }),
        status: 'doorToDoor',
        typeTransport: 'SEA',
        mediaTransit: [
            {
                value: faker.number.int({ min: 3, max: 10 }),
                color: 'yellow-v2',
            },
            {
                value: faker.number.int({ min: 3, max: 10 }),
                color: 'yellow-v3',
            },
            {
                value: faker.number.int({ min: 3, max: 50 }),
                color: 'brand-v3',
            },
            {
                value: faker.number.int({ min: 25, max: 50 }),
                color: 'brand-v4',
            },
            {
                value: faker.number.int({ min: 3, max: 20 }),
                color: 'green-v2',
            },
            {
                value: faker.number.int({ min: 3, max: 20 }),
                color: 'green-v1',
            }
        ]
    },
    {
        description: "Tuticoria, India",
        arivalPort: "Hamburg, Germany",
        totalShipment: faker.number.int({ min: 3, max: 30 }),
        status: 'deferred',
        typeTransport: 'TRUCK',
        mediaTransit: [
            {
                value: faker.number.int({ min: 3, max: 10 }),
                color: 'yellow-v2',
            },
            {
                value: faker.number.int({ min: 3, max: 10 }),
                color: 'yellow-v3',
            },
            {
                value: faker.number.int({ min: 3, max: 50 }),
                color: 'brand-v3',
            },
            {
                value: faker.number.int({ min: 25, max: 50 }),
                color: 'brand-v4',
            },
            {
                value: faker.number.int({ min: 3, max: 20 }),
                color: 'green-v2',
            },
            {
                value: faker.number.int({ min: 3, max: 20 }),
                color: 'green-v1',
            }
        ]
    },
    {
        description: "Yantian, China",
        arivalPort: "FRA - Frankfurt - Germany",
        totalShipment: faker.number.int({ min: 3, max: 30 }),
        status: 'direct',
        typeTransport: 'SEA',
        mediaTransit: [
            {
                value: faker.number.int({ min: 3, max: 10 }),
                color: 'yellow-v2',
            },
            {
                value: faker.number.int({ min: 3, max: 10 }),
                color: 'yellow-v3',
            },
            {
                value: faker.number.int({ min: 3, max: 50 }),
                color: 'brand-v3',
            },
            {
                value: faker.number.int({ min: 25, max: 50 }),
                color: 'brand-v4',
            },
            {
                value: faker.number.int({ min: 3, max: 20 }),
                color: 'green-v2',
            },
            {
                value: faker.number.int({ min: 3, max: 20 }),
                color: 'green-v1',
            }
        ]
    },
    {
        description: "SGN - Ho Chi Minh City - Vietnam",
        arivalPort: "Hamburg, Germany",
        totalShipment: faker.number.int({ min: 3, max: 30 }),
        status: 'standard',
        typeTransport: 'SEA',
        mediaTransit: [
            {
                value: faker.number.int({ min: 3, max: 10 }),
                color: 'yellow-v2',
            },
            {
                value: faker.number.int({ min: 3, max: 10 }),
                color: 'yellow-v3',
            },
            {
                value: faker.number.int({ min: 3, max: 50 }),
                color: 'brand-v3',
            },
            {
                value: faker.number.int({ min: 25, max: 50 }),
                color: 'brand-v4',
            },
            {
                value: faker.number.int({ min: 3, max: 20 }),
                color: 'green-v2',
            },
            {
                value: faker.number.int({ min: 3, max: 20 }),
                color: 'green-v1',
            }
        ]
    },
    {
        description: "Ho Chi Minh, Vietnam",
        arivalPort: "Hamburg, Germany",
        totalShipment: faker.number.int({ min: 3, max: 30 }),
        status: 'doorToDoor',
        typeTransport: 'SEA',
        mediaTransit: [
            {
                value: faker.number.int({ min: 3, max: 10 }),
                color: 'yellow-v2',
            },
            {
                value: faker.number.int({ min: 3, max: 10 }),
                color: 'yellow-v3',
            },
            {
                value: faker.number.int({ min: 3, max: 50 }),
                color: 'brand-v3',
            },
            {
                value: faker.number.int({ min: 25, max: 50 }),
                color: 'brand-v4',
            },
            {
                value: faker.number.int({ min: 3, max: 20 }),
                color: 'green-v2',
            },
            {
                value: faker.number.int({ min: 3, max: 20 }),
                color: 'green-v1',
            }
        ]
    },
    {
        description: "Cai Mep, Vietnam (Int. Terminal)",
        arivalPort: "FRA - Frankfurt - Germany",
        totalShipment: faker.number.int({ min: 3, max: 30 }),
        status: 'transhipment',
        typeTransport: 'AIR',
        mediaTransit: [
            {
                value: faker.number.int({ min: 3, max: 10 }),
                color: 'yellow-v2',
            },
            {
                value: faker.number.int({ min: 3, max: 10 }),
                color: 'yellow-v3',
            },
            {
                value: faker.number.int({ min: 3, max: 50 }),
                color: 'brand-v3',
            },
            {
                value: faker.number.int({ min: 25, max: 50 }),
                color: 'brand-v4',
            },
            {
                value: faker.number.int({ min: 3, max: 20 }),
                color: 'green-v2',
            },
            {
                value: faker.number.int({ min: 3, max: 20 }),
                color: 'green-v1',
            }
        ]
    },
    {
        description: "Johor Baru, Malaysia",
        arivalPort: "Hamburg, Germany",
        totalShipment: faker.number.int({ min: 3, max: 30 }),
        status: 'doorToDoor',
        typeTransport: 'AIR',
        mediaTransit: [
            {
                value: faker.number.int({ min: 3, max: 10 }),
                color: 'yellow-v2',
            },
            {
                value: faker.number.int({ min: 3, max: 10 }),
                color: 'yellow-v3',
            },
            {
                value: faker.number.int({ min: 3, max: 50 }),
                color: 'brand-v3',
            },
            {
                value: faker.number.int({ min: 25, max: 50 }),
                color: 'brand-v4',
            },
            {
                value: faker.number.int({ min: 3, max: 20 }),
                color: 'green-v2',
            },
            {
                value: faker.number.int({ min: 3, max: 20 }),
                color: 'green-v1',
            }
        ]
    }, {
        description: "Ho Chi Minh, Vietnam",
        arivalPort: "Hamburg, Germany",
        totalShipment: faker.number.int({ min: 3, max: 30 }),
        status: 'doorToDoor',
        typeTransport: 'SEA',
        mediaTransit: [
            {
                value: faker.number.int({ min: 3, max: 10 }),
                color: 'yellow-v2',
            },
            {
                value: faker.number.int({ min: 3, max: 10 }),
                color: 'yellow-v3',
            },
            {
                value: faker.number.int({ min: 3, max: 50 }),
                color: 'brand-v3',
            },
            {
                value: faker.number.int({ min: 25, max: 50 }),
                color: 'brand-v4',
            },
            {
                value: faker.number.int({ min: 3, max: 20 }),
                color: 'green-v2',
            },
            {
                value: faker.number.int({ min: 3, max: 20 }),
                color: 'green-v1',
            }
        ]
    },
    {
        description: "Tuticoria, India",
        arivalPort: "Hamburg, Germany",
        totalShipment: faker.number.int({ min: 3, max: 30 }),
        status: 'deferred',
        typeTransport: 'TRUCK',
        mediaTransit: [
            {
                value: faker.number.int({ min: 3, max: 10 }),
                color: 'yellow-v2',
            },
            {
                value: faker.number.int({ min: 3, max: 10 }),
                color: 'yellow-v3',
            },
            {
                value: faker.number.int({ min: 3, max: 50 }),
                color: 'brand-v3',
            },
            {
                value: faker.number.int({ min: 25, max: 50 }),
                color: 'brand-v4',
            },
            {
                value: faker.number.int({ min: 3, max: 20 }),
                color: 'green-v2',
            },
            {
                value: faker.number.int({ min: 3, max: 20 }),
                color: 'green-v1',
            }
        ]
    },
    {
        description: "Yantian, China",
        arivalPort: "FRA - Frankfurt - Germany",
        totalShipment: faker.number.int({ min: 3, max: 30 }),
        status: 'direct',
        typeTransport: 'SEA',
        mediaTransit: [
            {
                value: faker.number.int({ min: 3, max: 10 }),
                color: 'yellow-v2',
            },
            {
                value: faker.number.int({ min: 3, max: 10 }),
                color: 'yellow-v3',
            },
            {
                value: faker.number.int({ min: 3, max: 50 }),
                color: 'brand-v3',
            },
            {
                value: faker.number.int({ min: 25, max: 50 }),
                color: 'brand-v4',
            },
            {
                value: faker.number.int({ min: 3, max: 20 }),
                color: 'green-v2',
            },
            {
                value: faker.number.int({ min: 3, max: 20 }),
                color: 'green-v1',
            }
        ]
    },
    {
        description: "SGN - Ho Chi Minh City - Vietnam",
        arivalPort: "Hamburg, Germany",
        totalShipment: faker.number.int({ min: 3, max: 30 }),
        status: 'standard',
        typeTransport: 'SEA',
        mediaTransit: [
            {
                value: faker.number.int({ min: 3, max: 10 }),
                color: 'yellow-v2',
            },
            {
                value: faker.number.int({ min: 3, max: 10 }),
                color: 'yellow-v3',
            },
            {
                value: faker.number.int({ min: 3, max: 50 }),
                color: 'brand-v3',
            },
            {
                value: faker.number.int({ min: 25, max: 50 }),
                color: 'brand-v4',
            },
            {
                value: faker.number.int({ min: 3, max: 20 }),
                color: 'green-v2',
            },
            {
                value: faker.number.int({ min: 3, max: 20 }),
                color: 'green-v1',
            }
        ]
    },
    {
        description: "Ho Chi Minh, Vietnam",
        arivalPort: "Hamburg, Germany",
        totalShipment: faker.number.int({ min: 3, max: 30 }),
        status: 'doorToDoor',
        typeTransport: 'SEA',
        mediaTransit: [
            {
                value: faker.number.int({ min: 3, max: 10 }),
                color: 'yellow-v2',
            },
            {
                value: faker.number.int({ min: 3, max: 10 }),
                color: 'yellow-v3',
            },
            {
                value: faker.number.int({ min: 3, max: 50 }),
                color: 'brand-v3',
            },
            {
                value: faker.number.int({ min: 25, max: 50 }),
                color: 'brand-v4',
            },
            {
                value: faker.number.int({ min: 3, max: 20 }),
                color: 'green-v2',
            },
            {
                value: faker.number.int({ min: 3, max: 20 }),
                color: 'green-v1',
            }
        ]
    },
    {
        description: "Cai Mep, Vietnam (Int. Terminal)",
        arivalPort: "FRA - Frankfurt - Germany",
        totalShipment: faker.number.int({ min: 3, max: 30 }),
        status: 'transhipment',
        typeTransport: 'AIR',
        mediaTransit: [
            {
                value: faker.number.int({ min: 3, max: 10 }),
                color: 'yellow-v2',
            },
            {
                value: faker.number.int({ min: 3, max: 10 }),
                color: 'yellow-v3',
            },
            {
                value: faker.number.int({ min: 3, max: 50 }),
                color: 'brand-v3',
            },
            {
                value: faker.number.int({ min: 25, max: 50 }),
                color: 'brand-v4',
            },
            {
                value: faker.number.int({ min: 3, max: 20 }),
                color: 'green-v2',
            },
            {
                value: faker.number.int({ min: 3, max: 20 }),
                color: 'green-v1',
            }
        ]
    },
    {
        description: "Johor Baru, Malaysia",
        arivalPort: "Hamburg, Germany",
        totalShipment: faker.number.int({ min: 3, max: 30 }),
        status: 'doorToDoor',
        typeTransport: 'AIR',
        mediaTransit: [
            {
                value: faker.number.int({ min: 3, max: 10 }),
                color: 'yellow-v2',
            },
            {
                value: faker.number.int({ min: 3, max: 10 }),
                color: 'yellow-v3',
            },
            {
                value: faker.number.int({ min: 3, max: 50 }),
                color: 'brand-v3',
            },
            {
                value: faker.number.int({ min: 25, max: 50 }),
                color: 'brand-v4',
            },
            {
                value: faker.number.int({ min: 3, max: 20 }),
                color: 'green-v2',
            },
            {
                value: faker.number.int({ min: 3, max: 20 }),
                color: 'green-v1',
            }
        ]
    }, {
        description: "Ho Chi Minh, Vietnam",
        arivalPort: "Hamburg, Germany",
        totalShipment: faker.number.int({ min: 3, max: 30 }),
        status: 'doorToDoor',
        typeTransport: 'SEA',
        mediaTransit: [
            {
                value: faker.number.int({ min: 3, max: 10 }),
                color: 'yellow-v2',
            },
            {
                value: faker.number.int({ min: 3, max: 10 }),
                color: 'yellow-v3',
            },
            {
                value: faker.number.int({ min: 3, max: 50 }),
                color: 'brand-v3',
            },
            {
                value: faker.number.int({ min: 25, max: 50 }),
                color: 'brand-v4',
            },
            {
                value: faker.number.int({ min: 3, max: 20 }),
                color: 'green-v2',
            },
            {
                value: faker.number.int({ min: 3, max: 20 }),
                color: 'green-v1',
            }
        ]
    },
    {
        description: "Tuticoria, India",
        arivalPort: "Hamburg, Germany",
        totalShipment: faker.number.int({ min: 3, max: 30 }),
        status: 'deferred',
        typeTransport: 'TRUCK',
        mediaTransit: [
            {
                value: faker.number.int({ min: 3, max: 10 }),
                color: 'yellow-v2',
            },
            {
                value: faker.number.int({ min: 3, max: 10 }),
                color: 'yellow-v3',
            },
            {
                value: faker.number.int({ min: 3, max: 50 }),
                color: 'brand-v3',
            },
            {
                value: faker.number.int({ min: 25, max: 50 }),
                color: 'brand-v4',
            },
            {
                value: faker.number.int({ min: 3, max: 20 }),
                color: 'green-v2',
            },
            {
                value: faker.number.int({ min: 3, max: 20 }),
                color: 'green-v1',
            }
        ]
    },
    {
        description: "Yantian, China",
        arivalPort: "FRA - Frankfurt - Germany",
        totalShipment: faker.number.int({ min: 3, max: 30 }),
        status: 'direct',
        typeTransport: 'SEA',
        mediaTransit: [
            {
                value: faker.number.int({ min: 3, max: 10 }),
                color: 'yellow-v2',
            },
            {
                value: faker.number.int({ min: 3, max: 10 }),
                color: 'yellow-v3',
            },
            {
                value: faker.number.int({ min: 3, max: 50 }),
                color: 'brand-v3',
            },
            {
                value: faker.number.int({ min: 25, max: 50 }),
                color: 'brand-v4',
            },
            {
                value: faker.number.int({ min: 3, max: 20 }),
                color: 'green-v2',
            },
            {
                value: faker.number.int({ min: 3, max: 20 }),
                color: 'green-v1',
            }
        ]
    },
    {
        description: "SGN - Ho Chi Minh City - Vietnam",
        arivalPort: "Hamburg, Germany",
        totalShipment: faker.number.int({ min: 3, max: 30 }),
        status: 'standard',
        typeTransport: 'SEA',
        mediaTransit: [
            {
                value: faker.number.int({ min: 3, max: 10 }),
                color: 'yellow-v2',
            },
            {
                value: faker.number.int({ min: 3, max: 10 }),
                color: 'yellow-v3',
            },
            {
                value: faker.number.int({ min: 3, max: 50 }),
                color: 'brand-v3',
            },
            {
                value: faker.number.int({ min: 25, max: 50 }),
                color: 'brand-v4',
            },
            {
                value: faker.number.int({ min: 3, max: 20 }),
                color: 'green-v2',
            },
            {
                value: faker.number.int({ min: 3, max: 20 }),
                color: 'green-v1',
            }
        ]
    },
    {
        description: "Ho Chi Minh, Vietnam",
        arivalPort: "Hamburg, Germany",
        totalShipment: faker.number.int({ min: 3, max: 30 }),
        status: 'doorToDoor',
        typeTransport: 'SEA',
        mediaTransit: [
            {
                value: faker.number.int({ min: 3, max: 10 }),
                color: 'yellow-v2',
            },
            {
                value: faker.number.int({ min: 3, max: 10 }),
                color: 'yellow-v3',
            },
            {
                value: faker.number.int({ min: 3, max: 50 }),
                color: 'brand-v3',
            },
            {
                value: faker.number.int({ min: 25, max: 50 }),
                color: 'brand-v4',
            },
            {
                value: faker.number.int({ min: 3, max: 20 }),
                color: 'green-v2',
            },
            {
                value: faker.number.int({ min: 3, max: 20 }),
                color: 'green-v1',
            }
        ]
    },
    {
        description: "Cai Mep, Vietnam (Int. Terminal)",
        arivalPort: "FRA - Frankfurt - Germany",
        totalShipment: faker.number.int({ min: 3, max: 30 }),
        status: 'transhipment',
        typeTransport: 'AIR',
        mediaTransit: [
            {
                value: faker.number.int({ min: 3, max: 10 }),
                color: 'yellow-v2',
            },
            {
                value: faker.number.int({ min: 3, max: 10 }),
                color: 'yellow-v3',
            },
            {
                value: faker.number.int({ min: 3, max: 50 }),
                color: 'brand-v3',
            },
            {
                value: faker.number.int({ min: 25, max: 50 }),
                color: 'brand-v4',
            },
            {
                value: faker.number.int({ min: 3, max: 20 }),
                color: 'green-v2',
            },
            {
                value: faker.number.int({ min: 3, max: 20 }),
                color: 'green-v1',
            }
        ]
    },
    {
        description: "Johor Baru, Malaysia",
        arivalPort: "Hamburg, Germany",
        totalShipment: faker.number.int({ min: 3, max: 30 }),
        status: 'doorToDoor',
        typeTransport: 'AIR',
        mediaTransit: [
            {
                value: faker.number.int({ min: 3, max: 10 }),
                color: 'yellow-v2',
            },
            {
                value: faker.number.int({ min: 3, max: 10 }),
                color: 'yellow-v3',
            },
            {
                value: faker.number.int({ min: 3, max: 50 }),
                color: 'brand-v3',
            },
            {
                value: faker.number.int({ min: 25, max: 50 }),
                color: 'brand-v4',
            },
            {
                value: faker.number.int({ min: 3, max: 20 }),
                color: 'green-v2',
            },
            {
                value: faker.number.int({ min: 3, max: 20 }),
                color: 'green-v1',
            }
        ]
    }, {
        description: "Ho Chi Minh, Vietnam",
        arivalPort: "Hamburg, Germany",
        totalShipment: faker.number.int({ min: 3, max: 30 }),
        status: 'doorToDoor',
        typeTransport: 'SEA',
        mediaTransit: [
            {
                value: faker.number.int({ min: 3, max: 10 }),
                color: 'yellow-v2',
            },
            {
                value: faker.number.int({ min: 3, max: 10 }),
                color: 'yellow-v3',
            },
            {
                value: faker.number.int({ min: 3, max: 50 }),
                color: 'brand-v3',
            },
            {
                value: faker.number.int({ min: 25, max: 50 }),
                color: 'brand-v4',
            },
            {
                value: faker.number.int({ min: 3, max: 20 }),
                color: 'green-v2',
            },
            {
                value: faker.number.int({ min: 3, max: 20 }),
                color: 'green-v1',
            }
        ]
    },
    {
        description: "Tuticoria, India",
        arivalPort: "Hamburg, Germany",
        totalShipment: faker.number.int({ min: 3, max: 30 }),
        status: 'deferred',
        typeTransport: 'TRUCK',
        mediaTransit: [
            {
                value: faker.number.int({ min: 3, max: 10 }),
                color: 'yellow-v2',
            },
            {
                value: faker.number.int({ min: 3, max: 10 }),
                color: 'yellow-v3',
            },
            {
                value: faker.number.int({ min: 3, max: 50 }),
                color: 'brand-v3',
            },
            {
                value: faker.number.int({ min: 25, max: 50 }),
                color: 'brand-v4',
            },
            {
                value: faker.number.int({ min: 3, max: 20 }),
                color: 'green-v2',
            },
            {
                value: faker.number.int({ min: 3, max: 20 }),
                color: 'green-v1',
            }
        ]
    },
    {
        description: "Yantian, China",
        arivalPort: "FRA - Frankfurt - Germany",
        totalShipment: faker.number.int({ min: 3, max: 30 }),
        status: 'direct',
        typeTransport: 'SEA',
        mediaTransit: [
            {
                value: faker.number.int({ min: 3, max: 10 }),
                color: 'yellow-v2',
            },
            {
                value: faker.number.int({ min: 3, max: 10 }),
                color: 'yellow-v3',
            },
            {
                value: faker.number.int({ min: 3, max: 50 }),
                color: 'brand-v3',
            },
            {
                value: faker.number.int({ min: 25, max: 50 }),
                color: 'brand-v4',
            },
            {
                value: faker.number.int({ min: 3, max: 20 }),
                color: 'green-v2',
            },
            {
                value: faker.number.int({ min: 3, max: 20 }),
                color: 'green-v1',
            }
        ]
    },
    {
        description: "SGN - Ho Chi Minh City - Vietnam",
        arivalPort: "Hamburg, Germany",
        totalShipment: faker.number.int({ min: 3, max: 30 }),
        status: 'standard',
        typeTransport: 'SEA',
        mediaTransit: [
            {
                value: faker.number.int({ min: 3, max: 10 }),
                color: 'yellow-v2',
            },
            {
                value: faker.number.int({ min: 3, max: 10 }),
                color: 'yellow-v3',
            },
            {
                value: faker.number.int({ min: 3, max: 50 }),
                color: 'brand-v3',
            },
            {
                value: faker.number.int({ min: 25, max: 50 }),
                color: 'brand-v4',
            },
            {
                value: faker.number.int({ min: 3, max: 20 }),
                color: 'green-v2',
            },
            {
                value: faker.number.int({ min: 3, max: 20 }),
                color: 'green-v1',
            }
        ]
    },
    {
        description: "Ho Chi Minh, Vietnam",
        arivalPort: "Hamburg, Germany",
        totalShipment: faker.number.int({ min: 3, max: 30 }),
        status: 'doorToDoor',
        typeTransport: 'SEA',
        mediaTransit: [
            {
                value: faker.number.int({ min: 3, max: 10 }),
                color: 'yellow-v2',
            },
            {
                value: faker.number.int({ min: 3, max: 10 }),
                color: 'yellow-v3',
            },
            {
                value: faker.number.int({ min: 3, max: 50 }),
                color: 'brand-v3',
            },
            {
                value: faker.number.int({ min: 25, max: 50 }),
                color: 'brand-v4',
            },
            {
                value: faker.number.int({ min: 3, max: 20 }),
                color: 'green-v2',
            },
            {
                value: faker.number.int({ min: 3, max: 20 }),
                color: 'green-v1',
            }
        ]
    },
    {
        description: "Cai Mep, Vietnam (Int. Terminal)",
        arivalPort: "FRA - Frankfurt - Germany",
        totalShipment: faker.number.int({ min: 3, max: 30 }),
        status: 'transhipment',
        typeTransport: 'AIR',
        mediaTransit: [
            {
                value: faker.number.int({ min: 3, max: 10 }),
                color: 'yellow-v2',
            },
            {
                value: faker.number.int({ min: 3, max: 10 }),
                color: 'yellow-v3',
            },
            {
                value: faker.number.int({ min: 3, max: 50 }),
                color: 'brand-v3',
            },
            {
                value: faker.number.int({ min: 25, max: 50 }),
                color: 'brand-v4',
            },
            {
                value: faker.number.int({ min: 3, max: 20 }),
                color: 'green-v2',
            },
            {
                value: faker.number.int({ min: 3, max: 20 }),
                color: 'green-v1',
            }
        ]
    },
    {
        description: "Johor Baru, Malaysia",
        arivalPort: "Hamburg, Germany",
        totalShipment: faker.number.int({ min: 3, max: 30 }),
        status: 'doorToDoor',
        typeTransport: 'AIR',
        mediaTransit: [
            {
                value: faker.number.int({ min: 3, max: 10 }),
                color: 'yellow-v2',
            },
            {
                value: faker.number.int({ min: 3, max: 10 }),
                color: 'yellow-v3',
            },
            {
                value: faker.number.int({ min: 3, max: 50 }),
                color: 'brand-v3',
            },
            {
                value: faker.number.int({ min: 25, max: 50 }),
                color: 'brand-v4',
            },
            {
                value: faker.number.int({ min: 3, max: 20 }),
                color: 'green-v2',
            },
            {
                value: faker.number.int({ min: 3, max: 20 }),
                color: 'green-v1',
            }
        ]
    },
]
export const metaDummy: IMeta = {
    current_page: 1,
    last_page: 1,
    from: 1,
    to: 50,
    total_Items: 15,
    per_page: 0,
    total_page: 1,
    index_end: 15,
    index_start: 1,
}
