import { ITabItem } from 'components/tab/tab.interface'
import { IShipmentDetails } from 'repository/ifb/interface/shipment.interface'

export const tabDetailHeaders: ITabItem[] = [
    {
        label: 'Details',
        labelTranslationCode: 'tab_details.details',
        key: 'status',
        value: 'details',
        className: 'brand',
    },
    {
        label: 'Pack Lines',
        labelTranslationCode: 'tab_details.pack_lines',
        key: 'status',
        value: 'packLines',
        className: 'brand',
    },
    {
        label: 'Orders',
        labelTranslationCode: 'tab_details.order',
        key: 'status',
        value: 'orders',
        className: 'brand',
    },
    {
        label: 'Related Invoices',
        labelTranslationCode: 'tab_details.related_nvoices',
        key: 'status',
        value: 'relatedInvoices',
        className: 'brand',
    },
    {
        label: 'Documents',
        labelTranslationCode: 'tab_details.documents',
        key: 'status',
        value: 'documents',
        className: 'brand',
    },
    {
        label: 'eDocs',
        labelTranslationCode: 'tab_details.edocs',
        key: 'status',
        value: 'eDocs',
        className: 'brand',
    },
    {
        label: 'Messages',
        labelTranslationCode: 'tab_details.messages',
        key: 'status',
        value: 'messages',
        className: 'brand',
    },
    {
        label: 'Notes',
        labelTranslationCode: 'tab_details.notes',
        key: 'status',
        value: 'notes',
        className: 'brand',
    },
    {
        label: 'Milestones',
        labelTranslationCode: 'tab_details.milestones',
        key: 'status',
        value: 'milestones',
        className: 'brand',
    },
    {
        label: 'Events',
        labelTranslationCode: 'tab_details.events',
        key: 'status',
        value: 'events',
        className: 'brand',
    },
    {
        label: 'Tracking',
        labelTranslationCode: 'tab_details.tracking',
        key: 'status',
        value: 'tracking',
        className: 'brand',
    },
]

export const intialValueShipmentDetails: IShipmentDetails = {
    shipmentID: '',
    transport: '',
    origin: '',
    destination: '',
    shipper: '',
    consignee: '',
    etd: '',
    eta: '',
    ata: null,
    atd: null,
    weight: 0,
    uw: '',
    volume: 0,
    uv: '',
    houseBill: '',
    chargeable: 0,
    goodValue: 0,
    goodValueCurrency: '',
    containerMode: '',
    shipmentType: '',
    containerType: null,
    voyageOrFlight: null,
    wv: null,
    inners: 0,
    innersType: '',
    insuranceValue: 0,
    insuranceValueCurrency: '',
    description: '',
    marksAndNumber: null,
    incoterm: '',
    additionalTerm: '',
    supplierInvoice: null,
    routeCode: '',
    spotRate: 0,
    spotRateCurrency: '',
    serviceLevel: '',
    entryDetails: null,
    issueDate: null,
    expiryDate: null,
    releaseType: '',
    chargeApply: '',
    packs: 0,
    packsType: '',
    screeningStatus: '',
    shipmentStatus: '',
    phase: null,
    efreightStatus: null,
    size: 0,
    milestone: [],
    goodOrPacks: [],
    containers: [],
    poAttached: null,
    transports: [],
    addressDetails: [],
    eDocumentationDetails: [],
    relatedInvoices: [],
}
