import useOverlay from "components/overlay/overlay.service"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { OrderDataDummy } from "./orders.dummy"
import { ITabItem } from "components/tab/tab.interface"
import { useModal } from "components/modal/modal.service"
import { IOrder } from "./orders.interface"
import ConfigureColumnsModal from "components/configure-columns-modal/configure-columns-modal.component"
import { ITableColumn } from "components/table/table.interface"
import { OrderConfigureColumns } from "./orders.static"

const useOrder = () => {
    const navigate = useNavigate()

    // overlay
    const filterOverlayService = useOverlay()

    // modal
    const generateReportModalService = useModal()
    const ConfigureColumnsModalService = useModal()

    // state
    const [data, setData] = useState(OrderDataDummy)
    const [loading, setLoading] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string | undefined>()
    const [tabItems, setTabItems] = useState<ITabItem[]>()
    const [headers, setHeaders] = useState<ITableColumn<IOrder>[]>([])
    const [tabFilter, setTabFilter] = useState<ITabItem>({
        label: 'All Orders',
        totalData: OrderDataDummy.length,
        key: 'status',
        value: 'allOrders',
        className: 'brand',
    })

    // get data quotations
    useEffect(() => {
        loadData()
    }, [tabFilter])

    // Get Data Function
    const loadData = async () => {
        try {
            setLoading(true)
            setData(
                tabFilter.value === 'allOrders'
                    ? OrderDataDummy
                    : OrderDataDummy.filter(
                        (x) => x.orderStatus === tabFilter.value,
                    ),
            )
            setLoading(false)
        } catch (e) {
            setLoading(false)
            const errorMessage = typeof e !== 'string' ? 'Something wrong' : e
            setErrorMessage(errorMessage)
        }
    }

    // segment general
    const allModal = (
        <>
            <ConfigureColumnsModal<IOrder>
                modalService={ConfigureColumnsModalService}
                columns={OrderConfigureColumns}
                setHeaders={setHeaders}
            />
        </>
    )

    return {
        filterOverlayService,
        generateReportModalService,
        tabItems,
        tabFilter,
        data,
        loading,
        errorMessage,
        setData,
        setLoading,
        setErrorMessage,
        navigate,
        setTabFilter,
        setTabItems,
        ConfigureColumnsModalService,
        allModal,
        headers
    }
}

export default useOrder