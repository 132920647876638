/* eslint-disable no-unused-vars */
import FormInput from 'components/form-input/form-input.component'
import Tooltip from 'components/tooltip/tooltip.component'
import { FormikProvider, useFormik } from 'formik'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import DraggabbleList from './draggable-list.component'
import { useTranslation } from 'react-i18next'

/* eslint-disable no-unused-vars */
export interface IList {
    header: string
    items: IListItem[]
    onSearchSubmit?: (search: string) => void
    moveItem: (dragIndex: number, hoverIndex: number) => void
    moveItemUp: (index: number) => void
    moveItemDown: (index: number) => void
    isDraggable?: boolean
}

export interface IListItem {
    title: string
    content: JSX.Element
}

const List = ({
    header,
    items,
    onSearchSubmit,
    moveItem,
    moveItemUp,
    moveItemDown,
    isDraggable = false,
}: IList) => {
    const { t } = useTranslation()
    const formik = useFormik<{ searchTerm: string }>({
        initialValues: { searchTerm: '' },
        onSubmit: (values) => {
            onSearchSubmit && onSearchSubmit(values.searchTerm)
        },
        validate: (values) => {
            const errors: any = {}
            if (
                values.searchTerm.length < 3 &&
                values.searchTerm.length !== 0
            ) {
                errors.searchTerm = 'Search term must be at least 3 characters'
            }
            return errors
        },
    })
    const heightContainer = onSearchSubmit ? 'h-[calc(100vh-20rem)]' : 'h-full'

    return (
        <div className={`${heightContainer}`}>
            {onSearchSubmit && (
                <FormikProvider value={formik}>
                    <form
                        className="w-full flex justify-end mb-3"
                        onSubmit={(e) => {
                            e.preventDefault()
                            formik.handleSubmit()
                        }}
                    >
                        <Tooltip
                            text={'Search term must be at least 3 characters'}
                            isShow={formik.errors.searchTerm ? true : false}
                        >
                            <FormInput
                                autoComplete="auto-off"
                                hideError={true}
                                placeholder={t('action.search', {
                                    value: t('additional.items'),
                                })}
                                parentDivClassName="w-full min-w-[240px] mobile:min-w-0 mobile:w-full !mb-0"
                                icon="ri-search-line"
                                name="searchTerm"
                                onKeyDown={(
                                    event: React.KeyboardEvent<HTMLInputElement>,
                                ) => {
                                    if (
                                        event.key === 'Backspace' &&
                                        formik.values.searchTerm.length === 1
                                    ) {
                                        formik.values.searchTerm = ''
                                        formik.handleSubmit()
                                    }
                                }}
                            />
                        </Tooltip>
                    </form>
                </FormikProvider>
            )}
            <div className="border rounded h-full flex flex-col">
                <div className="border-b p-3 font-bold themes-text-gray-v5">
                    {header}
                </div>
                {isDraggable ? (
                    <DndProvider backend={HTML5Backend}>
                        <div className="h-full overflow-auto">
                            {items?.map((item, index) => {
                                return (
                                    <DraggabbleList
                                        item={item}
                                        index={index}
                                        moveItem={moveItem}
                                        moveItemUp={moveItemUp}
                                        moveItemDown={moveItemDown}
                                        itemLength={items.length}
                                    />
                                )
                            })}
                        </div>
                    </DndProvider>
                ) : (
                    <div className="">
                        {items.map((item, index) => {
                            const lastRowClass =
                                index + 1 === items?.length
                                    ? 'border-b-0'
                                    : ' border-b'
                            return (
                                <div
                                    key={index}
                                    className={`${lastRowClass} p-3`}
                                >
                                    {item.content}
                                </div>
                            )
                        })}
                    </div>
                )}
            </div>
        </div>
    )
}

export default List
