import { IMeta } from 'common/common.interface'
import { IInvoices } from './invoices.interface'

export const InvoicesDataDummy: IInvoices[] = [
    {
        jobInvoiceNo: 'S00001731/E',
        transactionType: 'Invoice',
        invoiceDate: '15-03-2023',
        dueDate: '15-03-2023',
        fullyPaidDate: '',
        invoiceAmount: '100.0000',
        transactionCurrency: 'AUD - Australian Dollar',
        amount: '100.0000',
        companyName: 'Internasional Freightbridge',
        currencyCode: 'AUD',
        status: 'unpaid',
    },
    {
        jobInvoiceNo: 'S00001014/B',
        transactionType: 'Credit Note',
        invoiceDate: '25-09-2022',
        dueDate: '15-03-2023',
        fullyPaidDate: '',
        invoiceAmount: '500.0000',
        transactionCurrency: 'JPY - Japanese Yen',
        amount: '5200.0000',
        companyName: 'OneByOne Logistical Pty Ltd',
        currencyCode: 'JPY',
        status: 'due next 7 days',
    },
    {
        jobInvoiceNo: 'STHOSE2300001801/B',
        transactionType: 'Invoice',
        invoiceDate: '03-12-2023',
        dueDate: '03-12-2023',
        fullyPaidDate: '03-12-2023',
        invoiceAmount: '7,5000.0000',
        transactionCurrency: 'USD - United States Dollar',
        amount: '7,5000.0000',
        companyName: 'Internasional Freightbridge',
        currencyCode: 'USD',
        status: 'overdue',
    },
    {
        jobInvoiceNo: 'S00001727/A',
        transactionType: 'Credit Note',
        invoiceDate: '20-05-2022',
        dueDate: '20-05-2022',
        fullyPaidDate: '',
        invoiceAmount: '-200.0000',
        transactionCurrency: 'AUD - Australian Dollar',
        amount: '-200.0000',
        companyName: 'OneByOne Logistical Pty Ltd',
        currencyCode: 'AUD',
        status: 'unpaid',
    },
    {
        jobInvoiceNo: 'S00001731/B',
        transactionType: 'Invoice',
        invoiceDate: '10-10-2023',
        dueDate: '10-10-2023',
        fullyPaidDate: '',
        invoiceAmount: '100.0000',
        transactionCurrency: 'AUD - Australian Dollar',
        amount: '110.0000',
        companyName: 'Internasional Freightbridge',
        currencyCode: 'AUD',
        status: 'due next 7 days',
    },
    {
        jobInvoiceNo: 'STHOSE2300001801',
        transactionType: 'Credit Note',
        invoiceDate: '28-07-2022',
        dueDate: '28-07-2022',
        fullyPaidDate: '28-07-2022',
        invoiceAmount: '500.0000',
        transactionCurrency: 'USD - United States Dollar',
        amount: '500.0000',
        companyName: 'OneByOne Logistical Pty Ltd',
        currencyCode: 'USD',
        status: 'overdue',
    },
    {
        jobInvoiceNo: 'S00001370/G',
        transactionType: 'Invoice',
        invoiceDate: '05-03-2023',
        dueDate: '05-03-2023',
        fullyPaidDate: '',
        invoiceAmount: '7,500.0000',
        transactionCurrency: 'AUD - Australian Dollar',
        amount: '7,500.0000',
        companyName: 'Internasional Freightbridge',
        currencyCode: 'AUD',
        status: 'others',
    },
    {
        jobInvoiceNo: 'S00001359/B',
        transactionType: 'Credit Note',
        invoiceDate: '18-12-2023',
        dueDate: '18-12-2023',
        fullyPaidDate: '',
        invoiceAmount: '-200.0000',
        transactionCurrency: 'JPY - Japanese Yen',
        amount: '-200.0000',
        companyName: 'OneByOne Logistical Pty Ltd',
        currencyCode: 'JPY',
        status: 'unpaid',
    },
    {
        jobInvoiceNo: 'S00001711/A',
        transactionType: 'Invoice',
        invoiceDate: '23-08-2023',
        dueDate: '23-08-2023',
        fullyPaidDate: '',
        invoiceAmount: '100.0000',
        transactionCurrency: 'USD - United States Dollar',
        amount: '130.0000',
        companyName: 'Internasional Freightbridge',
        currencyCode: 'USD',
        status: 'unpaid',
    },
    {
        jobInvoiceNo: 'S00001004/D',
        transactionType: 'Credit Note',
        invoiceDate: '13-11-2022',
        dueDate: '13-11-2022',
        fullyPaidDate: '',
        invoiceAmount: '500.0000',
        transactionCurrency: 'AUD - Australian Dollar',
        amount: '500.0000',
        companyName: 'OneByOne Logistical Pty Ltd',
        currencyCode: 'AUD',
        status: 'overdue',
    },
    {
        jobInvoiceNo: 'S00001014/C',
        transactionType: 'Invoice',
        invoiceDate: '08-06-2022',
        dueDate: '08-06-2022',
        fullyPaidDate: '',
        invoiceAmount: '7,500.0000',
        transactionCurrency: 'JPY - Japanese Yen',
        amount: '7,500.0000',
        companyName: 'Internasional Freightbridge',
        currencyCode: 'JPY',
        status: 'unpaid',
    },
    {
        jobInvoiceNo: 'S00002091',
        transactionType: 'Credit Note',
        invoiceDate: '15-03-2023',
        dueDate: '15-03-2023',
        fullyPaidDate: '',
        invoiceAmount: '-200.0000',
        transactionCurrency: 'USD - United States Dollar',
        amount: '-250.0000',
        companyName: 'OneByOne Logistical Pty Ltd',
        currencyCode: 'USD',
        status: 'due next 7 days',
    },
    {
        jobInvoiceNo: 'S00001014/E',
        transactionType: 'Invoice',
        invoiceDate: '15-03-2023',
        dueDate: '15-03-2023',
        fullyPaidDate: '',
        invoiceAmount: '100.0000',
        transactionCurrency: 'AUD - Australian Dollar',
        amount: '100.0000',
        companyName: 'Internasional Freightbridge',
        currencyCode: 'AUD',
        status: 'overdue',
    },
]

export const metaDummy: IMeta = {
    current_page: 1,
    last_page: 1,
    from: 1,
    to: 50,
    total_Items: 15,
    per_page: 0,
    total_page: 1,
    index_end: 15,
    index_start: 1,
}
