import Content from 'components/layout/content.component'
import Header from 'components/layout/header.component'
import Sidebar from 'components/layout/sidebar.component'
import { useEffect } from 'react'
import { Outlet } from 'react-router'
import { IUserAuth } from 'repository/common/interface/user-auth.interface'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { userDataSelector } from 'pages/common/login/login.slice'

// styling
import './components/layout/layout.style.css'
import './themes/common.themes.css'
import './themes/ifb.themes.css'
import './themes/loglines.themes.css'

function App(): React.ReactElement {
    const navigate = useNavigate()
    const user: IUserAuth = useSelector(userDataSelector)
    useEffect(() => {
        if (!user.token) {
            navigate('/login', {
                replace: true,
            })
        }
    }, [])
    return (
        <>
            <div className="logistical-layout">
                <div className="flex">
                    <div className="flex-grow flex flex-col order-2 overflow-auto">
                        <div className="logistical-content order-last">
                            <Content>
                                <Outlet />
                            </Content>
                        </div>
                        <Header />
                    </div>
                    <Sidebar />
                </div>
            </div>
        </>
    )
}

export default App
