import { IMeasurement } from 'components/measurement-input/measurement-input.interface'
import * as Yup from 'yup'

// interface for validation
export interface IEditBokingValidation {
    consignorPickupAddress: {
        company: string
        street: string
        additionalStreet: string
        city: string
        state: string
        postcode: string
        country: string
        contactName: string
        contactEmailAddress: string
        contactPhone: string
        contactMobile: string
    }
    consignorDocumentaryAddress: {
        company: string
        street: string
        additionalStreet: string
        city: string
        state: string
        postcode: string
        country: string
        contactName: string
        contactEmailAddress: string
        contactPhone: string
        contactMobile: string
    }
    consigneePickupAddress: {
        company: string
        street: string
        additionalStreet: string
        city: string
        state: string
        postcode: string
        country: string
        contactName: string
        contactEmailAddress: string
        contactPhone: string
        contactMobile: string
    }
    consigneeDocumentaryAddress: {
        company: string
        street: string
        additionalStreet: string
        city: string
        state: string
        postcode: string
        country: string
        contactName: string
        contactEmailAddress: string
        contactPhone: string
        contactMobile: string
    }
    requestBillToParty: {
        company: string
        street: string
        additionalStreet: string
        city: string
        state: string
        postcode: string
        country: string
        contactName: string
        contactEmailAddress: string
        contactPhone: string
        contactMobile: string
    }
    originPort: string
    pickupRequestedBy: string
    pickupEquipmentNeeded: string
    destinationPort: string
    deliveryRequestedBy: string
    deliveryEquipmentNeeded: string
    shippersRef: string
    serviceLevel: string
    incoTerm: string
    additionalTerms: string
    goodsValue: string
    goodsValueCurrency: string
    insuranceValue: string
    insuranceValueCurrency: string
    packs: string
    outerPackUnit: string
    volumeMeasure: IMeasurement
    weightMeasure: {
        qty: string
        unit: string
    }
    chargeableWeightOrVolume: {
        qty: string
        unit: string
    }
    goodsDescription: string
    marksAndNumbers: string
    startDate: string
}

// form validation YUP for formik
export const editBookingValidation = Yup.object().shape({
    consignorPickupAddress: Yup.object().shape({
        company: Yup.string().required('Company is required'),
    }),
    consigneePickupAddress: Yup.object().shape({
        company: Yup.string().required('Company is required'),
    }),
})

// form login initial data
export const editBookingInitialValue: IEditBokingValidation = {
    consignorPickupAddress: {
        company: '',
        street: '',
        additionalStreet: '',
        city: '',
        state: '',
        postcode: '',
        country: '',
        contactName: '',
        contactEmailAddress: '',
        contactPhone: '',
        contactMobile: '',
    },
    consignorDocumentaryAddress: {
        company: '',
        street: '',
        additionalStreet: '',
        city: '',
        state: '',
        postcode: '',
        country: '',
        contactName: '',
        contactEmailAddress: '',
        contactPhone: '',
        contactMobile: '',
    },
    consigneePickupAddress: {
        company: '',
        street: '',
        additionalStreet: '',
        city: '',
        state: '',
        postcode: '',
        country: '',
        contactName: '',
        contactEmailAddress: '',
        contactPhone: '',
        contactMobile: '',
    },
    consigneeDocumentaryAddress: {
        company: '',
        street: '',
        additionalStreet: '',
        city: '',
        state: '',
        postcode: '',
        country: '',
        contactName: '',
        contactEmailAddress: '',
        contactPhone: '',
        contactMobile: '',
    },
    requestBillToParty: {
        company: '',
        street: '',
        additionalStreet: '',
        city: '',
        state: '',
        postcode: '',
        country: '',
        contactName: '',
        contactEmailAddress: '',
        contactPhone: '',
        contactMobile: '',
    },
    originPort: '',
    pickupRequestedBy: '',
    pickupEquipmentNeeded: '',
    destinationPort: '',
    deliveryRequestedBy: '',
    deliveryEquipmentNeeded: '',
    shippersRef: '',
    serviceLevel: '',
    incoTerm: '',
    additionalTerms: '',
    goodsValue: '0.0000',
    goodsValueCurrency: '',
    insuranceValue: '0.0000',
    insuranceValueCurrency: '',
    packs: '0',
    outerPackUnit: '',
    volumeMeasure: {
        qty: '',
        unit: 'M3',
    },
    weightMeasure: {
        qty: '0.000',
        unit: 'KG',
    },
    chargeableWeightOrVolume: {
        qty: '0.000',
        unit: 'KG',
    },
    goodsDescription: '',
    marksAndNumbers: '',
    startDate: '',
}
