/* eslint-disable no-unused-vars */
import { useState } from 'react'
import { ITabItem } from 'components/tab/tab.interface'
import Details from './contents/details.component'
import {
    tabConsigneeOrConsignorHeaders,
    tabDetailHeaders,
} from './booking-details.static'
import FormPickupOrDocumentaryAddress from './components/form-pickup-or-documentary-address'
import { IBookingDetails } from './booking-details.interface'
import { useNavigate } from 'react-router-dom'
import PackLines from './contents/pack-lines.component'
import Documents from './contents/documents.component'
import EDocs from './contents/e-docs.component'
import Messages from './contents/messages.component'
import Notes from './contents/notes.component'
import Milestones from './contents/milestones.components'
import Events from './contents/events.components'
import RelatedOrders from './contents/related-orders.component'
import Tracking from './contents/tracking.component'
import { bookingDetails } from './booking-details.dummy'

const useBookingDetails = () => {
    const navigate = useNavigate()

    // states
    const [detailsData] = useState<IBookingDetails>(bookingDetails)
    const [tabFilter, setTabFilter] = useState<ITabItem>(tabDetailHeaders[0])
    const [consignorTabFilter, setConsignorTabFilter] = useState<ITabItem>(
        tabConsigneeOrConsignorHeaders[0],
    )
    const [consigneeTabFilter, setConsigneeTabFilter] = useState<ITabItem>(
        tabConsigneeOrConsignorHeaders[0],
    )

    // functions
    const getContent = () => {
        const contents: Record<string, JSX.Element> = {
            details: (
                <Details
                    consignorTabFilter={consignorTabFilter}
                    setConsignorTabFilter={(data) =>
                        setConsignorTabFilter(data)
                    }
                    setConsigneeTabFilter={(data) =>
                        setConsigneeTabFilter(data)
                    }
                    consigneeTabFilter={consigneeTabFilter}
                    getDetailsConsignorContent={getDetailsConsignorContent}
                    getDetailsConsigneeContent={getDetailsConsigneeContent}
                    data={detailsData}
                />
            ),
            packLines: <PackLines />,
            documents: <Documents />,
            eDocs: <EDocs />,
            messages: <Messages />,
            notes: <Notes />,
            milestones: <Milestones />,
            events: <Events />,
            tracking: <Tracking />,
            relatedOrders: <RelatedOrders />,
        }
        return contents[tabFilter.value]
    }

    // functions Details
    const getDetailsConsignorContent = () => {
        const contents: Record<string, JSX.Element> = {
            pickupAddress: (
                <FormPickupOrDocumentaryAddress
                    data={detailsData.consignorPickupAddress}
                />
            ),
            documentaryAddress: (
                <FormPickupOrDocumentaryAddress
                    data={detailsData.consignorDocumentaryAddress}
                />
            ),
        }
        return contents[consignorTabFilter.value]
    }
    const getDetailsConsigneeContent = () => {
        const contents: Record<string, JSX.Element> = {
            pickupAddress: (
                <FormPickupOrDocumentaryAddress
                    data={detailsData.consigneePickupAddress}
                />
            ),
            documentaryAddress: (
                <FormPickupOrDocumentaryAddress
                    data={detailsData.consigneeDocumentaryAddress}
                />
            ),
        }
        return contents[consigneeTabFilter.value]
    }

    return {
        detailsData,
        tabFilter,
        navigate,
        setTabFilter,
        getContent,
    }
}

export default useBookingDetails
