import Button from 'components/button/button.component'
import DatePicker from 'components/date-picker/date-picker.component'
import Dropdown from 'components/dropdown/dropdown.component'
import Input from 'components/input/input.component'
import { IPOFormLineInitial } from './reports.static'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

const Reports = () => {
    const { t } = useTranslation()
    const [formLine, setFromLine] = useState<IPOFormLineInitial[]>([
        {
            emailTo: '',
            emailSubject: '',
            uom: '',
            bcc: '',
            cc: '',
        },
    ])

    const handleAddItem = () => {
        const newFormLine = [...formLine]
        newFormLine.push({
            emailTo: '',
            emailSubject: '',
            uom: '',
            bcc: '',
            cc: '',
        })
        setFromLine(newFormLine)
    }

    const handleDeleteItem = (index: number) => {
        const newFormLine = [...formLine]
        newFormLine.splice(index, 1)
        setFromLine(newFormLine)
    }

    return (
        <div className="container-global content-full-height flex flex-col">
            <div className="flex flex-grow flex-col p-4 gap-4 overflow-auto">
                <div className="flex flex-col w-full gap-3">
                    <div className="flex">
                        <span className="text-size-L font-bold">{t('menu.reports')}</span>
                    </div>
                    <div className="grid grid-cols-4 gap-3">
                        <div className="flex w-full">
                            <Dropdown
                                useUppercaseLabel={true}
                                label={t('reports.available_reports')}
                                placeholder={t("action.select", { value: t("reports.available_reports") })}
                                options={[
                                    {
                                        label: 'Reconcilation Report',
                                        value: 'Reconcilation Report',
                                    },
                                ]}
                            />
                        </div>
                        <div className="flex col-span-3"></div>
                    </div>
                </div>
                <div className="border-b border-[#D9DDE1]" />
                <div className="flex flex-col w-full gap-3">
                    <div className="flex">
                        <span className="text-size-L font-bold">
                            {t('reports.reports_filters')}
                        </span>
                    </div>
                    <div className="grid grid-cols-4 gap-3">
                        <div className="flex w-full">
                            <Dropdown
                                useUppercaseLabel={true}
                                label={t('reports.importer_of_record')}
                                placeholder={t("action.select", { value: t("reports.importer_of_record") })}
                                options={[
                                    {
                                        label: 'BANGKOK LOGISTICS SOLUTION',
                                        value: 'Bangkok Logistics Solution',
                                    },
                                ]}
                            />
                        </div>
                        <div className="flex w-full">
                            <Dropdown
                                useUppercaseLabel={true}
                                label={t('reports.filling_report')}
                                placeholder={t("action.select", { value: t("reports.filling_report") })}
                                options={[
                                    {
                                        label: '5550 BORDER PATROL SECTOR HQ',
                                        value: '5550 BORDER PATROL SECTOR HQ',
                                    },
                                ]}
                            />
                        </div>
                        <div className="flex col-span-2"></div>
                    </div>
                    <div className="grid grid-cols-4 gap-3">
                        <div className="flex w-full">
                            <Dropdown
                                useUppercaseLabel={true}
                                label={t('reports.issue_code')}
                                placeholder={t("action.select", { value: t("reports.issue_code") })}
                                options={[
                                    {
                                        label: '98 9802 Recon',
                                        value: '98 9802 Recon',
                                    },
                                ]}
                            />
                        </div>
                        <div className="flex w-full">
                            <Dropdown
                                useUppercaseLabel={true}
                                label={t('reports.message_status')}
                                placeholder={t("action.select", { value: t("reports.message_status") })}
                                options={[
                                    {
                                        label: 'ARD Awaiting Recon Delete',
                                        value: 'ARD Awaiting Recon Delete',
                                    },
                                ]}
                            />
                        </div>
                    </div>
                    <div className="grid grid-cols-4 gap-3">
                        <div className="flex !w-full">
                            <DatePicker
                                isRange={false}
                                label={t('reports.estimated_recon_date_from')}
                                iconPosition="right"
                                className="!w-full"
                            />
                        </div>
                        <div className="flex w-full">
                            <DatePicker
                                isRange={false}
                                label={t('reports.estimated_recon_date_to')}
                                iconPosition="right"
                                className="w-full"
                            />
                        </div>
                    </div>
                </div>
                <div className="border-b border-[#D9DDE1]" />
                <div className="flex flex-col w-full gap-3">
                    <div className="flex">
                        <span className="text-size-L font-bold">
                            {t('reports.options')}
                        </span>
                    </div>
                    <div className="grid grid-cols-4 gap-3">
                        <div className="flex w-full">
                            <Dropdown
                                useUppercaseLabel={true}
                                label={t('reports.sort_report_by')}
                                placeholder={t("action.select", { value: t("reports.sort_report_by") })}
                                options={[
                                    {
                                        label: 'Reconcilation Job Number',
                                        value: 'Reconcilation Job Number',
                                    },
                                ]}
                            />
                        </div>
                    </div>
                </div>
                <div className="border-b border-[#D9DDE1]" />
                <div className="flex flex-col w-full gap-3">
                    <div className="flex w-full justify-between">
                        <div className="flex">
                            <span className="text-size-L font-bold">
                                {t('reports.recipients')}
                            </span>
                        </div>
                        <div>
                            <Button
                                label={t('reports.add_recipient')}
                                className="!w-full"
                                onClick={() => handleAddItem()}
                            />
                        </div>
                    </div>

                    {formLine.map((data, idx) => {
                        return (
                            <div
                                key={`recipient-${idx}`}
                                className="flex w-full gap-3"
                            >
                                <div className="grid grid-cols-4 gap-3 w-full">
                                    <div className="flex w-full">
                                        <Input
                                            useUppercaseLabel={true}
                                            label={t('reports.email_to')}
                                            placeholder={t("action.enter", { value: t("users.email") })}
                                            type="email"
                                        />
                                    </div>
                                    <div className="flex w-full">
                                        <Input
                                            useUppercaseLabel={true}
                                            label={t('reports.email_subject')}
                                            placeholder={t("action.enter", { value: t("reports.email_subject") })}
                                        />
                                    </div>
                                    <div className="flex w-full">
                                        <Input
                                            useUppercaseLabel={true}
                                            label={t('reports.bcc')}
                                            placeholder={t("action.enter", { value: t("reports.bcc") })}
                                        />
                                    </div>
                                    <div className="flex w-full">
                                        <Input
                                            useUppercaseLabel={true}
                                            label={t('reports.cc')}
                                            placeholder={t("action.enter", { value: t("reports.cc") })}
                                        />
                                    </div>
                                </div>
                                <div className="flex-none w-10">
                                    <Button
                                        onClick={() => handleDeleteItem(idx)}
                                        icon="ri-delete-bin-line"
                                        className={`w-btnIconWidth ${idx === 0 && '!opacity-0'}`}
                                        isDisabled={idx === 0 && true}
                                    />
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
            <div className="border-b border-logistical-gray-ver7" />
            <div className="flex p-4 items-center justify-end font-bold text-size-L">
                <Button
                    useUpperCase={true}
                    className="w-btnRegularWidth"
                    label={t('reports.deliver_reports')}
                    onClick={() => { }}
                />
            </div>
        </div>
    )
}

export default Reports
