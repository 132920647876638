import './pagination.style.css'
import { IPagination } from './pagination.interface'
import Button from 'components/button/button.component'
import { useTranslation } from 'react-i18next'

function Pagination({ meta, ...props }: IPagination) {
    const { t } = useTranslation()
    const indexStart = meta?.total_Items
        ? meta?.total_Items === 0
            ? 0
            : meta?.index_start
        : 0
    const indexEnd = meta?.index_end ?? 0
    const totalItems = meta?.total_Items ?? 0
    const disabled = meta?.current_page === meta?.last_page
    const nextHandling = meta?.current_page ? meta.current_page + 1 : 0
    const previousHandling = meta?.current_page ? meta?.current_page - 1 : 0
    return (
        <div className="pagination-container flex-none">
            <div className="flex flex-row">
                <ul className="pagination">
                    <Button
                        icon="ri-arrow-left-s-line"
                        variant="default-pagination"
                        type="button"
                        isDisabled={indexStart <= 1}
                        className={`w-btnIconWidth mx-4`}
                        onClick={() =>
                            props.nextHandling &&
                            props.nextHandling(previousHandling)
                        }
                    />
                    <li className="page-of font-[1000]">{`${indexStart} - ${indexEnd} `}</li>
                    <li className="page-of font-[10]">{t('additional.of')}</li>
                    <li className="page-of font-[1000]">{`${totalItems}`}</li>
                    <li className="page-of font-[10]">
                        {t('additional.list')}
                    </li>
                    <Button
                        icon="ri-arrow-right-s-line"
                        variant="default-pagination"
                        type="button"
                        isDisabled={disabled}
                        className={`w-btnIconWidth mx-4`}
                        onClick={() =>
                            props.nextHandling &&
                            props.nextHandling(nextHandling)
                        }
                    />
                </ul>
            </div>
        </div>
    )
}

export default Pagination
