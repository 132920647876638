/* eslint-disable no-unused-vars */
import { DayPicker } from 'react-day-picker'
import DatePickerHeader from './date-picker-header.component'
import { IDatePicker } from './date-picker.interface'
import { useDatePicker } from './date-picker.service'
import './date-picker.style.css'
import DatePickerInput from './date-picker-input.component'

const DatePicker = ({
    placeholder,
    disable,
    isRange = false,
    ...props
}: IDatePicker) => {
    const {
        dropdownRef,
        isFutureDate,
        isPastDate,
        showDatePicker,
        setShowDatePicker,
        changeDateHandler,
        date,
        adjustDateRange,
    } = useDatePicker({
        selectedRange: props?.selectedRange,
        selectedDate: props?.selectedDate,
    })

    const disabledBackground = disable ? 'themes-bg-gray-v5' : 'themes-bg-white'

    return (
        <div className="w-full">
            <div ref={dropdownRef} className={`w-full`}>
                <DatePickerInput
                    selectedRange={props?.selectedRange}
                    selectedDate={props?.selectedDate}
                    disable={disable}
                    isRange={isRange}
                    label={props?.label}
                    onClear={() => {
                        props.onClear && props.onClear()
                        setShowDatePicker(false)
                    }}
                    setShowDatePicker={setShowDatePicker}
                    iconPosition={props?.iconPosition}
                    containerInputClassName={props.containerInputClassName}
                />
                {showDatePicker && (
                    <div
                        className={`${isRange && 'flex justify-center'} w-fit`}
                    >
                        <div
                            className={`pickerStyle ${disabledBackground} absolute`}
                        >
                            {!isRange ? (
                                <DayPicker
                                    month={new Date(date)}
                                    disabled={
                                        !props.disableDays
                                            ? undefined
                                            : props.disableDays === 'future'
                                              ? isFutureDate
                                              : isPastDate
                                    }
                                    onDayClick={(date) => {
                                        props?.setSelectedDate &&
                                            props.setSelectedDate(date)
                                    }}
                                    selected={props.selectedDate}
                                    components={{
                                        Caption: (props) =>
                                            DatePickerHeader({
                                                ...props,
                                                changeDateHandler,
                                            }),
                                    }}
                                />
                            ) : (
                                <DayPicker
                                    month={new Date(date)}
                                    mode={'range'}
                                    disabled={
                                        !props.disableDays
                                            ? undefined
                                            : props.disableDays === 'future'
                                              ? isFutureDate
                                              : isPastDate
                                    }
                                    components={{
                                        Caption: (props) =>
                                            DatePickerHeader({
                                                ...props,
                                                changeDateHandler,
                                            }),
                                    }}
                                    selected={props?.selectedRange}
                                    onSelect={(range) => {
                                        const adjustedRange =
                                            adjustDateRange(range)
                                        if (props?.setSelectedRange) {
                                            props.setSelectedRange(
                                                adjustedRange,
                                            )
                                        }
                                    }}
                                />
                            )}
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default DatePicker
