/* eslint-disable no-unused-vars */
import { useEffect, useRef, useState } from 'react'
import { IInput } from './input.interface'
import './input.style.css'

const Input = ({
    disabled = false,
    readonly = false,
    required = false,
    type = 'text',
    disabledVariant = 'gray',
    useUppercaseLabel,
    ...props
}: IInput) => {
    const inputRef = useRef<HTMLInputElement>(null)
    const [focus, setFocus] = useState(false)
    const showLabelClass = !props.label || props.label === '' ? 'hidden' : ''
    const isRequired = required ? 'required' : ''
    const labelFocus = focus ? 'themes-text-brand-v5' : ''
    const isDisabled = disabled ? `disabled-${disabledVariant}` : ''
    const iconWithAction = ['ri-file-copy-line', 'ri-mail-line']
    const iconClassDisabled =
        props?.icon &&
            disabled &&
            props.value &&
            iconWithAction.includes(props?.icon)
            ? 'cursor-pointer'
            : 'opacity-70'

    const handleFocusEvent = () => {
        setFocus(true)
    }

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                inputRef.current &&
                !inputRef.current.contains(event.target as Node)
            ) {
                setFocus(false)
            }
        }

        document.addEventListener('click', handleClickOutside)

        return () => {
            document.removeEventListener('click', handleClickOutside)
        }
    }, [])

    return (
        <div
            className={` ${props?.parentInputClassName || ''} inputParent-style mt-auto`}
        >
            <label className={`${showLabelClass} text-size-XS`}>
                <div className={`${isRequired} ${labelFocus} themes-text-gray-v5 h-[9px]`}>
                    {useUppercaseLabel ? props.label?.toUpperCase() : props.label}
                </div>
            </label>
            <div
                className={`${props.descriptionValue && 'input-group'} flex w-full items-center ${props.descriptionValue && disabled && 'themes-bg-gray-v1'}`}
                id={props?.id}
            >
                {props.descriptionValue && (
                    <div
                        className={`themes-text-gray-v4 text-size-S mx-2 flex item gap-2 `}
                    >
                        <div className={`themes-text-gray-v4 text-size-S`}>
                            {props.descriptionValue}
                        </div>
                        <div className="border-r"></div>
                    </div>
                )}
                <input
                    {...props}
                    ref={inputRef}
                    disabled={disabled}
                    className={`${props.className} ${isDisabled} `}
                    placeholder={props.placeholder}
                    type={type}
                    value={disabled && !props.value ? '-' : props.value}
                    readOnly={readonly}
                    onChange={(e) => {
                        if (props.onChange) props.onChange(e)
                    }}
                    onFocus={handleFocusEvent}
                />
                {props?.icon && (
                    <i
                        className={`${props.icon} ${iconClassDisabled} input-icon`}
                        onClick={() => {
                            props?.onIconClick && props?.onIconClick()
                        }}
                    ></i>
                )}
            </div>
        </div>
    )
}

export default Input
