import { useEffect, useState } from 'react'
import { IFilterPerformances, IFilterPerformancesItems } from './performances.interface';
import { DataDummy } from './performances.dummy';

const usePerformances = () => {
    const [formLine, setFromLine] = useState<IFilterPerformances[]>([
        {
            filterType: '',
            filter: {
                value: '',
                label: ''
            },
        }
    ]);
    const [tabFilter, setTabFilter] = useState<string>('performance');
    const [tabTotalCoast, setTabTotalCoast] = useState<string>('routes');
    const [tabTransit, setTabTransit] = useState<string>('doorToDoor');
    const [data, setData] = useState(DataDummy)
    const [loading, setLoading] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string | undefined>()


    // get data quotations
    useEffect(() => {
        loadData()
    }, [tabTransit])

    // Get Data Function
    const loadData = async () => {
        try {
            setLoading(true)
            setData(DataDummy.filter(
                (x) => x.status === tabTransit,
            ),
            )
            setLoading(false)
        } catch (e) {
            setLoading(false)
            const errorMessage = typeof e !== 'string' ? 'Something wrong' : e
            setErrorMessage(errorMessage)
        }
    }


    const deleteFormLine = (index: number) => {
        const updatedFormLine = [...formLine];
        updatedFormLine.splice(index, 1);

        setFromLine(updatedFormLine);
    };

    const updateFilterType = (index: number, newFilterType: IFilterPerformancesItems) => {
        const updatedFormLine = [...formLine];

        updatedFormLine[index] = {
            ...updatedFormLine[index],
            filterType: newFilterType.value,
            filter: {
                value: newFilterType.value,
                label: newFilterType.label,
            }
        };

        if (index === updatedFormLine.length - 1) {
            updatedFormLine.push({
                filterType: '',
                filter: {
                    value: '',
                    label: ''
                },
            });
        }

        setFromLine(updatedFormLine);
    };


    return {
        data,
        tabFilter,
        tabTotalCoast,
        tabTransit,
        errorMessage,
        loading,
        setTabTransit,
        setTabTotalCoast,
        setTabFilter,
        formLine,
        deleteFormLine,
        updateFilterType
    }
}

export default usePerformances
