import Header from '../components/header.component'
import { useState } from 'react'
import Detail from '../detail.component'
import { totalShipmentMode, totalVolumeByMode } from './row2.dummy'
import FilterSection from '../components/filter-section.component'
import BarChartCustom from 'components/rechart-component/bar/bar-chart.component'
import { useTranslation } from 'react-i18next'

function BarChartAnalytics() {
    const [isFilter, setIsFilter] = useState<boolean>(false)
    const { t } = useTranslation()
    return (
        <div className="grid grid-rows-12 h-full">
            <Header
                className="h-5 row-span-1"
                title={t('dashboard.total_shipments_&_volume_by_mode')}
                filter={` ${t('dashboard.last_days', {
                    value: 7,
                })}(17/03/24 - 23/03/24)`}
                isFilter={isFilter}
                setIsFilter={setIsFilter}
            />
            {isFilter ? (
                <FilterSection
                    className="grid gap-3 mt-6"
                    displayedInform={true}
                    filterBy={true}
                    dateRange={true}
                />
            ) : (
                <>
                    <Detail
                        className="row-span-3 grid-cols-6 h-14 self-center content-center"
                        data={totalVolumeByMode}
                    />
                    <BarChartCustom
                        data={totalShipmentMode}
                        className="row-span-8"
                        tooltipSingle="Total Shipments"
                    />
                </>
            )}
        </div>
    )
}

export default BarChartAnalytics
