import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { IInvoices } from "./invoices.interface"
import { RootState } from "store"

// interface
export interface IInvoiceFilter {
    search?: string
}

interface IInvoicesSlice {
    data: IInvoices[]
    filter: IInvoiceFilter

}

export const initialState: IInvoicesSlice = {
    data: [],
    filter: {
        search: ''
    }
}

const invoicesSlice = createSlice({
    name: 'invoicesIFB',
    initialState: initialState,
    reducers: {
        setFilterSearchInvoice(state, action: PayloadAction<string>) {
            const search = action.payload
            const filter = { ...state.filter, search }
            console.log('setFilterSearchInvoice', {
                ...state,
                filter,
            })
            return {
                ...state,
                filter,
            }
        },
    }
})

// all selector
export const filterSelector = (state: RootState) => state.invoiceIFB.filter || {}

// all actions
export const {
    setFilterSearchInvoice
} = invoicesSlice.actions

export default invoicesSlice.reducer