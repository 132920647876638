import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'
import { ITogglePasswordButton } from './toggle-password.interface'
import './toggle-password.style.css'

const TogglePassword = ({
    isPasswordVisible,
    toggleVisibility,
    variant = 'JPL',
}: ITogglePasswordButton) => {
    return (
        <button
            type="button"
            className={`button-${variant}`}
            onClick={toggleVisibility}
        >
            {isPasswordVisible ? (
                <AiOutlineEye className={`icon-${variant}`} />
            ) : (
                <AiOutlineEyeInvisible className={`icon-${variant}`} />
            )}
        </button>
    )
}

export default TogglePassword
