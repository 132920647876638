import { faker } from "@faker-js/faker"
import { IOptionsFilterVolumes } from "./volumes.interface"

export const optionsFilterVolumes: IOptionsFilterVolumes[] = [
    {
        label: 'Displayed Information',
        labelTranslation: "volumes.filtered_by",
        value: 'displayed',
    },
    {
        label: 'Filtered By',
        labelTranslation: "volumes.filtered_by",
        value: 'filtered',
    },
    {
        label: 'Duration',
        labelTranslation: "volumes.duration",
        value: 'totalVolume',
    },
    {
        label: 'Total Weight',
        labelTranslation: "volumes.total_weight",
        value: 'weight',
    },
]

export const ShipmentMode = [
    {
        toolTipName: 'Eastern Asia - Western Europe',
        name: 'Eastern Asia - Western Europe',
        number: faker.number.int({ min: 20001, max: 49000 }),
    },
    {
        toolTipName: 'Eastern Asia - Scutheast Asia',
        name: 'Eastern Asia - Scutheast Asia',
        number: faker.number.int({ min: 20001, max: 49000 }),
    },
    {
        toolTipName: 'Western Europe - Eastern Asia',
        name: 'Western Europe - Eastern Asia',
        number: faker.number.int({ min: 20001, max: 49000 }),
    },
    {
        toolTipName: 'Western Europe - Eastern America',
        name: 'Western Europe - Eastern America',
        number: faker.number.int({ min: 20001, max: 49000 }),
    },
    {
        toolTipName: 'Western Europe - Western Asia',
        name: 'Western Europe - Western Asia',
        number: faker.number.int({ min: 20001, max: 49000 }),
    },
    {
        toolTipName: 'Western Europe - Western Europe ',
        name: 'Western Europe - Western Europe ',
        number: faker.number.int({ min: 20001, max: 49000 }),
    },
    {
        toolTipName: 'Western Europe - Western Africa ',
        name: 'Western Europe - Western Africa ',
        number: faker.number.int({ min: 20001, max: 49000 }),
    },
]   