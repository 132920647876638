import { useModal } from 'components/modal/modal.service'
import useOverlay from 'components/overlay/overlay.service'
import { ITabItem } from 'components/tab/tab.interface'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { TrackingDataDummy } from './trackings.dummy'

const useTrackings = () => {
    const navigate = useNavigate()

    // overlay
    const filterOverlayService = useOverlay()

    // modal
    const generateReportModalService = useModal()

    // state
    const [data, setData] = useState(TrackingDataDummy)
    const [loading, setLoading] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string | undefined>()
    const [tabItems, setTabItems] = useState<ITabItem[]>()
    const [tabFilter, setTabFilter] = useState<ITabItem>({
        label: 'All Trackings',
        totalData: TrackingDataDummy.length,
        key: 'status',
        value: 'allTrackings',
        className: 'brand',
    })

    // get data quotations
    useEffect(() => {
        loadData()
    }, [tabFilter])

    // Get Data Function
    const loadData = async () => {
        try {
            setLoading(true)
            setData(
                tabFilter.value === 'allTrackings'
                    ? TrackingDataDummy
                    : TrackingDataDummy.filter(
                        (x) => x.typeTransport === tabFilter.value,
                    ),
            )
            setLoading(false)
        } catch (e) {
            setLoading(false)
            const errorMessage = typeof e !== 'string' ? 'Something wrong' : e
            setErrorMessage(errorMessage)
        }
    }

    return {
        filterOverlayService,
        generateReportModalService,
        tabItems,
        tabFilter,
        data,
        loading,
        errorMessage,
        setData,
        setLoading,
        setErrorMessage,
        navigate,
        setTabFilter,
        setTabItems,
    }
}

export default useTrackings
