// import DatePicker from 'components/date-picker/date-picker.component'
import DatePicker from 'components/date-picker/date-picker.component'
import Dropdown from 'components/dropdown/dropdown.component'
import Input from 'components/input/input.component'

const Address = () => {
    return (
        <div className="flex w-full p-4 gap-4 h-[calc(100vh-280px)] overflow-y-auto">
            <div className="flex-col w-1/4 space-y-4">
                <span className="text-size-L font-bold">Shipper</span>
                <Dropdown
                    label={'COMPANY'}
                    options={[
                        {
                            label: 'IFB Freightbridge',
                            value: 'IFB Freightbridge',
                        },
                    ]}
                    placeholder="Select Shipper Company"
                />
                <Input label="STREET" placeholder="Input Street" />
                <Input label="CITY" placeholder="Input City" />
                <Input label="STATE" placeholder="Input State" />
                <Input label="POST CODE" placeholder="Input Post Code" />
                <Input label="COUNTRY" placeholder="Input Country" />
                <Dropdown
                    label={'CONTACT'}
                    options={[
                        {
                            label: 'Christian',
                            value: 'Christian',
                        },
                    ]}
                    placeholder="Select Shipper Contact"
                />
                <Input label="PHONE" placeholder="Input Phone Number" />
                <Input label="MOBILE" placeholder="Input Mobile Number" />
                <Input label="EMAIL" placeholder="Input Email Address" />
            </div>
            <div className="flex-col w-1/4 space-y-4">
                <span className="text-size-L font-bold">Consignee</span>
                <Dropdown
                    label={'COMPANY'}
                    options={[
                        {
                            label: 'OneByOne Logistical',
                            value: 'OneByOne Logistical',
                        },
                    ]}
                    placeholder="Select Consignee Company"
                />
                <Input label="STREET" placeholder="Input Street" />
                <Input label="CITY" placeholder="Input City" />
                <Input label="STATE" placeholder="Input State" />
                <Input label="POST CODE" placeholder="Input Post Code" />
                <Input label="COUNTRY" placeholder="Input Country" />
                <Dropdown
                    label={'CONTACT'}
                    options={[
                        {
                            label: 'Anthony',
                            value: 'Anthony',
                        },
                    ]}
                    placeholder="Select Consignee Contact"
                />
                <Input label="PHONE" placeholder="Input Phone Number" />
                <Input label="MOBILE" placeholder="Input Mobile Number" />
                <Input label="EMAIL" placeholder="Input Email Address" />
            </div>
            <div className="flex-col w-1/4 space-y-4">
                <span className="text-size-L font-bold">Origin</span>
                <Input label="ORIGIN PORT" placeholder="Input Origin Port" />
                <div className='ml-[3px]'>
                    <DatePicker
                        isRange={false}
                        label="PIC. REQUESTED BY"
                        iconPosition="left"
                    />
                </div>
                <Dropdown
                    label={'PICKUP EQUIPMENT NEEDED'}
                    options={[
                        {
                            label: 'Premise Supplies Lift',
                            value: 'Premise Supplies Lift',
                        },
                    ]}
                    placeholder="Select Pickup Equipment Needed"
                />
            </div>
            <div className="flex-col w-1/4 space-y-4">
                <span className="text-size-L font-bold">Destination</span>
                <Input label="DESTINATION PORT" placeholder="Input Destination Port" />
                <div className='ml-[3px]'>
                    <DatePicker
                        isRange={false}
                        label="DLV. REQUESTED BY"
                        iconPosition="left"
                    />
                </div>
                <Dropdown
                    label={'DELIVERY EQUIPMENT NEEDED'}
                    options={[
                        {
                            label: 'Premise Supplies Lift',
                            value: 'Premise Supplies Lift',
                        },
                    ]}
                    placeholder="Select Delivery Equipment Needed"
                />
            </div>
        </div>
    )
}

export default Address
