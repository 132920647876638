interface ISummaryProps {
    className: string;
    title: string;
    isIncrease: boolean;
    total: number;
    percentage: number
    unit: string
}

function Summary({ className, title, isIncrease, total, percentage, unit }: ISummaryProps) {
    return (
        <div className={`${className} grid lg:grid-rows-6 transition-opacity gap-[12px]`}>
            <div className="grid content-start lg:row-span-2 text-gray-400 lg:text-[16px] md:text-[32px] font-bold font-['Basis Grotesque Arabic Pro']">
                {title}
            </div>
            <div className="lg:row-span-4 grid grid-cols-6 content-start">
                <div className="col-span-4 lg:text-[20px] md:text-[32px] text-gray-500 font-bold font-['Basis Grotesque Arabic Pro']">
                    {total+' '+unit}
                </div>
                <div
                    className={`col-span-2 p-[5px] w-fit ${isIncrease ? 'bg-green-100' : 'bg-red-100'} px-1.5 rounded flex-col justify-center items-center gap-2.5 inline-flex`}
                >
                    <div className="justify-start items-center inline-flex">
                        <div className="text-zinc-600 lg:text-[14px] md:text-[20px] font-bold font-['Basis Grotesque Arabic Pro'] leading-[18px]">
                            {percentage}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Summary