import { TranslationCode } from "../../../../@types/translation-code";

export interface IDashboardItem {
    title: TranslationCode;
    labelTranslation?: TranslationCode
    total: string;
    isIncrease: boolean;
    summaryTotal: string;
    icon: string;
    detail?: IDetailItem
}
export interface IDetailItem {
    detail1: string;
    detail2: string;
    detail3: string;
}

export const DashboardCardDefault: IDashboardItem[] = [
    {
        title: 'dashboard.bookings_sea',
        total: "50,250",
        isIncrease: true,
        summaryTotal: '+120',
        icon: "ri-truck-line",
        detail: {
            detail1: '43003',
            detail2: '298',
            detail3: '98'
        }
    },
    {
        title: 'dashboard.shipment_status',
        total: "145,003",
        isIncrease: false,
        summaryTotal: '-7,548',
        icon: "ri-ship-2-line",
        detail: {
            detail1: '23,003',
            detail2: '12,359',
            detail3: '10,025'
        }
    },
    {
        title: 'dashboard.schedule_changes',
        total: "198,900",
        isIncrease: false,
        summaryTotal: "-9,802",
        icon: "ri-box-3-line",
        detail: {
            detail1: '32,897',
            detail2: '18,020',
            detail3: '11,840'
        }
    },
    {
        title: 'dashboard.warehouse',
        total: "75,965",
        isIncrease: true,
        summaryTotal: "+1,536",
        icon: "ri-building-4-line",
        detail: {
            detail1: '48,089',
            detail2: '12,660',
            detail3: '1,064'
        }
    },
    {
        title: 'dashboard.c02e_emission',
        total: "103,389t",
        isIncrease: true,
        summaryTotal: "+1,410",
        icon: "ri-building-3    -line",
        detail: {
            detail1: '23,690t',
            detail2: '18,054t',
            detail3: '3,010t'
        }
    },
    {
        title: 'dashboard.scfi_index',
        total: "US$ 40,340",
        isIncrease: true,
        summaryTotal: "+4,120",
        icon: "ri-money-dollar-box-line",
    },
];
