import { formatDate } from 'common/common.service'
import { IHeaderExport } from 'components/button-export/button-export.interface'
import { ITableColumn } from 'components/table/table.interface'
import { IShipment } from './shipments.interface'

export const shipmentHeaders: ITableColumn<IShipment>[] = [
    {
        label: 'Shipment ID',
        accessor: 'shipmentID',
    },
    {
        label: 'Transport',
        accessor: 'transport',
    },
    {
        label: 'Origin',
        accessor: 'origin',
    },
    {
        label: 'Destination',
        accessor: 'destination',
    },
    {
        label: 'Shipper',
        accessor: 'shipper',
    },
    {
        label: 'Consignee',
        accessor: 'consignee',
    },
    {
        label: 'ETD',
        accessor: 'etd',
    },
    {
        label: 'ATD',
        accessor: 'atd',
    },
    {
        label: 'ETA',
        accessor: 'eta',
    },
    {
        label: 'ATA',
        accessor: 'ata',
    },
    {
        label: 'Weight',
        accessor: 'weight',
    },
    {
        label: 'UW',
        accessor: 'uw',
    },
    {
        label: 'Volume',
        accessor: 'volume',
    },
    {
        label: 'UV',
        accessor: 'uv',
    },
    {
        label: 'Last Updated',
        accessor: 'lastUpdated',
    },
]

export const transportScheduleOption = [
    {
        value: 'etd',
        label: 'ETD (Estimation Time Delivery)',
    },
    {
        value: 'eta',
        label: 'ETA (Estimation Time Arrival)',
    },
    {
        value: 'atd',
        label: 'ATD (Actual Time Delivery)',
    },
    {
        value: 'ata',
        label: 'ATA (Actual Time Arrival)',
    },
]

export const shipmentSortByOption = [
    {
        label: 'Shipment ID',
        value: 'shipmentID',
    },
    {
        label: 'Transport',
        value: 'transport',
    },
    {
        label: 'Origin',
        value: 'origin',
    },
    {
        label: 'Destination',
        value: 'destination',
    },
    {
        label: 'Shipper',
        value: 'shipper',
    },
    {
        label: 'Consignee',
        value: 'consignee',
    },
    {
        value: 'etd',
        label: 'ETD (Estimation Time Delivery)',
    },
    {
        value: 'eta',
        label: 'ETA (Estimation Time Arrival)',
    },
    {
        value: 'atd',
        label: 'ATD (Actual Time Delivery)',
    },
    {
        value: 'ata',
        label: 'ATA (Actual Time Arrival)',
    },
]

export const ShipmentGenerateReportHeader: IHeaderExport[] = [
    {
        label: 'Shipment ID',
        accessor: 'shipmentID',
        widthCol: '25',
    },
    {
        label: 'Transport',
        accessor: 'transport',
        widthCol: '25',
    },
    {
        label: 'Origin',
        accessor: 'origin',
        widthCol: '25',
    },
    {
        label: 'Destination',
        accessor: 'destination',
        widthCol: '25',
    },
    {
        label: 'Shipper',
        accessor: 'shipper',
        widthCol: '25',
    },
    {
        label: 'Consignee',
        accessor: 'consignee',
        widthCol: '25',
    },
    {
        label: 'Weight',
        accessor: 'weight',
        widthCol: '25',
    },
    {
        label: 'UW',
        accessor: 'uw',
        widthCol: '25',
    },
    {
        label: 'Volume',
        accessor: 'volume',
        widthCol: '25',
    },
    {
        label: 'UV',
        accessor: 'uv',
        widthCol: '25',
    },
    {
        label: 'Last Updated',
        accessor: 'lastUpdated',
        widthCol: '25',
        customBuild: (data) => (data ? formatDate(data) : ''),
    },
    {
        label: 'ETD',
        accessor: 'etd',
        widthCol: '25',
        customBuild: (data) => (data ? formatDate(data) : ''),
    },
    {
        label: 'ATD',
        accessor: 'atd',
        widthCol: '25',
        customBuild: (data) => (data ? formatDate(data) : ''),
    },
    {
        label: 'ETA',
        accessor: 'eta',
        widthCol: '25',
        customBuild: (data) => (data ? formatDate(data) : ''),
    },
    {
        label: 'ATA',
        accessor: 'ata',
        widthCol: '25',
        customBuild: (data) => (data ? formatDate(data) : ''),
    },
]
