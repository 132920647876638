import { ITabItem } from 'components/tab/tab.interface'
import { IDocumentsProduct, IProductDocs } from '../products.interface'
import { ISTColumn } from 'components/simple-table/simple-table.interface'
import { IConfigureColumns } from 'components/configure-columns-modal/configure-column-modal.interface'

export const TabProduct: ITabItem[] = [
    {
        label: 'Details',
        labelTranslationCode: 'tab_details.details',
        key: 'status',
        value: 'details',
        className: 'brand',
    },
    {
        label: 'Documents',
        labelTranslationCode: 'tab_details.documents',
        key: 'status',
        value: 'documents',
        className: 'brand',
    },
    {
        label: 'eDocs',
        labelTranslationCode: 'tab_details.edocs',
        key: 'status',
        value: 'eDocs',
        className: 'brand',
    },
]

export const productEDocsHeaders: ISTColumn<IProductDocs>[] = [
    {
        label: 'File Name',
        labelTranslation: 'eDocs.file_name',
        accessor: 'fileName',
    },
    {
        label: 'Description',
        labelTranslation: 'eDocs.description',
        accessor: 'description',
    },
    {
        label: 'Added',
        labelTranslation: 'eDocs.added',
        accessor: 'added',
    },
]

export const productEDocsDummy: IProductDocs[] = [
    {
        fileName: 'invoice_12345.pdf',
        description: 'Invoice for order #12345',
        added: '01 May 2024',
    },
    {
        fileName: 'packing_list_67890.pdf',
        description: 'Packing list for shipment #67890',
        added: '02 May 2024',
    },
    {
        fileName: 'certificate_of_origin.pdf',
        description: 'Certificate of Origin for goods',
        added: '03 May 2024',
    },
    {
        fileName: 'bill_of_lading.pdf',
        description: 'Bill of Lading for container #98765',
        added: '04 May 2024',
    },
    {
        fileName: 'insurance_certificate.pdf',
        description: 'Insurance certificate for shipment',
        added: '05 May 2024',
    },
]

export const DocumentsProductConfigureColumns: IConfigureColumns<IDocumentsProduct>[] =
    [
        {
            title: 'Flags',
            columns: [
                {
                    statusColumn: 'selectedColumns',
                    accessor: 'packs',
                    label: 'Packs',
                    labelTranslation: 'products.packs',
                },
                {
                    statusColumn: 'selectedColumns',
                    accessor: 'weightMeasure',
                    label: 'Weight Measure',
                    labelTranslation: 'products.weight_measure',
                },
                {
                    statusColumn: 'selectedColumns',
                    accessor: 'goodsDescription',
                    label: 'Goods Description',
                    labelTranslation: 'products.goods_description',
                },
                {
                    statusColumn: 'selectedColumns',
                    accessor: 'lengthMeasure',
                    label: 'Length Measure',
                    labelTranslation: 'products.length_measure',
                },
                {
                    statusColumn: 'selectedColumns',
                    accessor: 'widthMeasure',
                    label: 'Width Measure',
                    labelTranslation: 'products.width_measure',
                },
                {
                    statusColumn: 'selectedColumns',
                    accessor: 'heightMeasure',
                    label: 'Height Measure',
                    labelTranslation: 'products.height_measure',
                },
                {
                    statusColumn: 'selectedColumns',
                    accessor: 'volumeMeasure',
                    label: 'Volume Measure',
                    labelTranslation: 'products.volume_measure',
                },
            ],
        },
    ]
