import { useState } from 'react'
import { IFilterVolumeItems, IFilterVolumes } from './volumes.interface'

const useVolumes = () => {
    const [formLine, setFromLine] = useState<IFilterVolumes[]>([
        {
            filterType: '',
            filter: {
                value: '',
                label: ''
            },
        }
    ]);

    const deleteFormLine = (index: number) => {
        const updatedFormLine = [...formLine];
        updatedFormLine.splice(index, 1);

        setFromLine(updatedFormLine);
    };

    const updateFilterType = (index: number, newFilterType: IFilterVolumeItems) => {
        const updatedFormLine = [...formLine];

        updatedFormLine[index] = {
            ...updatedFormLine[index],
            filterType: newFilterType.value,
            filter: {
                value: newFilterType.value,
                label: newFilterType.label,
            }
        };

        if (index === updatedFormLine.length - 1) {
            updatedFormLine.push({
                filterType: '',
                filter: {
                    value: '',
                    label: ''
                },
            });
        }

        setFromLine(updatedFormLine);
    };

    return {
        formLine,
        deleteFormLine,
        updateFilterType
    }
}

export default useVolumes
