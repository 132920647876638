import Input from 'components/input/input.component'
import Textarea from 'components/text-area/text-area.component'
import { useTranslation } from 'react-i18next'

const Details = () => {
    const { t } = useTranslation()
    return (
        <div className="grid grid-cols-4 gap-3 px-5 py-4">
            <div className="flex flex-col">
                <div className="text-[16px] themes-text-gray-v5">
                    {t('tracking.container_information')}
                </div>
                <div className="flex flex-col h-full gap-3 pt-2">
                    <Input
                        label={t('tracking.container_number')}
                        placeholder="Not Available"
                        disabled
                    />
                    <Input
                        label={t('tracking.master_bill')}
                        placeholder="Not Available"
                        disabled
                    />
                    <Input
                        label={t('tracking.delivery_mode')}
                        placeholder="Not Available"
                        disabled
                    />
                    <Input
                        label={t('tracking.container_type')}
                        placeholder="Not Available"
                        disabled
                    />
                    <Input
                        label={t('tracking.gross_weight_measure')}
                        placeholder="Not Available"
                        disabled
                    />
                    <Input
                        label={t('tracking.departure_cartage_ref')}
                        placeholder="Not Available"
                        disabled
                    />
                    <Input
                        label={t('tracking.port_of_loading')}
                        placeholder="Not Available"
                        disabled
                    />
                    <Textarea
                        containerClassName={`flex-grow flex flex-col`}
                        value={''}
                        onChange={() => { }}
                        label={t('tracking.special_instructions')}
                        placeholder="Not Available"
                        className="w-full !flex-grow min-h-[100px]"
                        resize
                        disabled
                    />
                </div>
            </div>
            <div>
                <div className="text-[16px] themes-text-gray-v5">
                    {t('tracking.status_dates')}
                </div>
                <div className="flex flex-col gap-3 pt-2">
                    <Input
                        label={t('tracking.container_status')}
                        placeholder="Not Availabel"
                        disabled
                    />
                    <Input
                        label={t('tracking.estimated_full_delivery')}
                        placeholder="Not Availabel"
                        disabled
                    />
                    <Input
                        label={t('tracking.empty_ready')}
                        placeholder="Not Availabel"
                        disabled
                    />
                    <Input
                        label={t('tracking.port_transport_booked')}
                        placeholder="Not Availabel"
                        disabled
                    />
                    <Input
                        label={t('tracking.empty_return_by')}
                        placeholder="Not Availabel"
                        disabled
                    />
                    <Input
                        label={t('tracking.actual_full_delivery')}
                        placeholder="Not Availabel"
                        disabled
                    />
                    <Input
                        label={t('tracking.container_yard_empty_return_gate_in')}
                        placeholder="Not Availabel"
                        disabled
                    />
                </div>
                <div className="text-[16px] themes-text-gray-v5 h-[30px] flex items-center pt-1">
                    {t('tracking.seals')}
                </div>
                <div className="flex flex-col gap-3 pt-2">
                    <div className="grid grid-cols-2 gap-3">
                        <Input
                            label={t('tracking.seal_number')}
                            placeholder="Not Availabel"
                            disabled
                        />
                        <Input
                            label={t('tracking.sealed_by')}
                            placeholder="Not Availabel"
                            disabled
                        />
                    </div>
                    <div className="grid grid-cols-2 gap-3">
                        <Input
                            label={t('tracking.second_seal_number')}
                            placeholder="Not Availabel"
                            disabled
                        />
                        <Input
                            label={t('tracking.second_sealed_by')}
                            placeholder="Not Availabel"
                            disabled
                        />
                    </div>
                    <div className="grid grid-cols-2 gap-3">
                        <Input
                            label={t('tracking.third_seal_number')}
                            placeholder="Not Availabel"
                            disabled
                        />
                        <Input
                            label={t('tracking.third_sealed_by')}
                            placeholder="Not Availabel"
                            disabled
                        />
                    </div>
                </div>
            </div>
            <div>
                <div className="text-[16px] themes-text-gray-v5">{t('tracking.vgm')}</div>
                <div className="flex flex-col gap-3 pt-2">
                    <Input
                        label={t('tracking.gross_weight_measure')}
                        placeholder="Not Availabel"
                        disabled
                    />
                    <Input
                        label={t('tracking.vgm_verified_date')}
                        placeholder="Not Availabel"
                        disabled
                    />
                    <Input
                        label={t('tracking.gross_weight_verification')}
                        placeholder="Not Availabel"
                        disabled
                    />
                    <Input
                        label={t('tracking.vgm_status')}
                        placeholder="Not Availabel"
                        disabled
                    />
                    <Input
                        label={t('tracking.verified_by_address_company_name')}
                        placeholder="Not Availabel"
                        disabled
                    />
                    <Input
                        label={t('tracking.verified_by_address_contact_name')}
                        placeholder="Not Availabel"
                        disabled
                    />
                    <Input
                        label={t('tracking.verified_by_address_phone')}
                        placeholder="Not Availabel"
                        disabled
                    />
                    <Input
                        label={t('tracking.verified_by_email_address')}
                        placeholder="Not Availabel"
                        disabled
                    />
                </div>
            </div>
        </div>
    )
}

export default Details
