import * as Yup from 'yup'
import { requiredMessage } from '../validation-message.static'

export interface IResetPasswordValidation {
    newPassword: string
    passwordConfirmation: string
}

export interface IResetPassword {
    newPassword: string
    passwordConfirmation: string
    passwordResetToken: string
}

export const resetPasswordValidation: Yup.ObjectSchema<IResetPasswordValidation> =
    Yup.object().shape({
        newPassword: Yup.string()
            .required('Password is required')
            .min(8, 'Password must be at least 8 characters')
            .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
            .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
            .matches(/[0-9]/, 'Password must contain at least one number')
            .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Password must contain at least one special character'),
        passwordConfirmation: Yup.string()
            .required(requiredMessage('Confirm Password'))
            .min(8, 'Password must be at least 8 characters long'),
    })

export const resetPasswordInitialValue: IResetPasswordValidation = {
    newPassword: '',
    passwordConfirmation: '',
}
