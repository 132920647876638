import FormDropdown from 'components/form-dropdown/form-dropdown.component'
import FormInput from 'components/form-input/form-input.component'
import { useTranslation } from 'react-i18next'

const DestinationForm = () => {
    const { t } = useTranslation()
    return (
        <div>
            <div>{t('bookings.destination')}</div>
            <div className="flex flex-col gap-3 mt-3">
                <FormDropdown
                    label={t('bookings.destination_port')}
                    name={`details.destinationPort`}
                    placeholder={t('action.enter', {
                        value: t('bookings.destination_port'),
                    })}
                    parentDivClassName="!mb-0"
                    options={[
                        {
                            label: 'Batam',
                            value: 'Batam',
                        },
                    ]}
                />
                <FormInput
                    label={t('bookings.delivery_requested_by')}
                    name={`details.deliveryRequestedBy`}
                    placeholder={t('action.enter', {
                        value: t('bookings.delivery_requested_by'),
                    })}
                    parentDivClassName="!mb-0"
                />
                <FormInput
                    label={t('bookings.delivery_equipment_needed')}
                    name={`details.deliveryEquipmentNeeded`}
                    placeholder={t('action.enter', {
                        value: t('bookings.delivery_equipment_needed'),
                    })}
                    parentDivClassName="!mb-0"
                />
            </div>
        </div>
    )
}

export default DestinationForm
