import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'

export interface IIFBLoginValidation {
    email: string
    password: string
}

export const useLoginValidation = () => {
    const { t } = useTranslation()

    // form validation YUP for formik
    const IFBLoginValidation: Yup.ObjectSchema<IIFBLoginValidation> =
        Yup.object().shape({
            email: Yup.string()
                .email(t('message.email_invalid'))
                .required(
                    t('message.required', {
                        value: t('login.email_address'),
                    }),
                ),
            password: Yup.string().required(
                t('message.required', {
                    value: t('login.password'),
                }),
            ),
        })

    // form login initial data
    const IFBLoginInitialValue: IIFBLoginValidation = {
        email: '',
        password: '',
    }

    return {
        IFBLoginValidation,
        IFBLoginInitialValue,
    }
}
