import { useState } from 'react'
import Details from './content/details.component'
import { ITabItem } from 'components/tab/tab.interface'
import { useModal } from 'components/modal/modal.service'
import EDocs from './content/e-docs.component'
import ConfigureColumnsModal from 'components/configure-columns-modal/configure-columns-modal.component'
import { IDocumentsProduct } from '../products.interface'
import { ISTColumn } from 'components/simple-table/simple-table.interface'
import { DocumentsProductConfigureColumns } from './products-details.static'
import Documents from './content/documents.component'

export const useProductsDetails = () => {
    const [headersDocuments, setHeadersDocuments] = useState<
        ISTColumn<IDocumentsProduct>[]
    >([])
    const [tabFilter, setTabFilter] = useState<ITabItem>({
        label: 'View Products Details',
        key: 'status',
        value: 'details',
        className: 'brand',
    })

    // modal
    const ConfigureColumnsModalServiceDocuments = useModal()

    const getContentProductsDetails = (tabFilter: ITabItem) => {
        const items: Record<string, JSX.Element> = {
            details: Details(),
            eDocs: EDocs(),
            documents: Documents(),
        }
        return items[tabFilter?.value] || <></>
    }

    // // segment general
    const allModalDocuments = (
        <>
            <ConfigureColumnsModal<IDocumentsProduct>
                modalService={ConfigureColumnsModalServiceDocuments}
                columns={DocumentsProductConfigureColumns}
                setHeaders={setHeadersDocuments}
            />
        </>
    )

    return {
        getContentProductsDetails,
        setTabFilter,
        tabFilter,
        allModalDocuments,
        headersDocuments,
        ConfigureColumnsModalServiceDocuments,
    }
}
