/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react'
import { IAccordionItem } from 'components/accordion/accordion.component'
import { IListItem } from 'components/list/list.component'
import Button from 'components/button/button.component'
import { IConfigureColumns } from './configure-column-modal.interface'
import { useTranslation } from 'react-i18next'

const useConfigureColumnsModalService = <T,>(
    columns: IConfigureColumns<T>[],
    setHeaders: (headers: any) => void,
) => {
    const { t } = useTranslation()
    const [selectedColumns, setSelectedColumns] = useState<IListItem[]>([])
    const [availableColumns, setAvailableColumns] = useState<IAccordionItem[]>(
        [],
    )
    const [configureColumns, setConfigureColumns] =
        useState<IConfigureColumns<T>[]>(columns)

    useEffect(() => {
        loadAvailableColumns()
        loadSelectedColumns()
        loadHeaders()
    }, [configureColumns])

    const loadHeaders = () => {
        const data = configureColumns.flatMap((item) =>
            item.columns
                .filter((subItem) => subItem.statusColumn === 'selectedColumns')
                .map(({ statusColumn, ...rest }) => rest),
        )
        setHeaders(data)
    }

    const loadSelectedColumns = () => {
        const data = configureColumns.flatMap((item) =>
            item.columns
                .filter((subItem) => subItem.statusColumn === 'selectedColumns')
                .map((subItem) => ({
                    title: subItem.label,
                    content: selectedColumnComponent(
                        subItem.labelTranslation
                            ? t(subItem.labelTranslation)
                            : subItem.label,
                    ),
                })),
        )
        setSelectedColumns(data)
    }

    const loadAvailableColumns = () => {
        const data = configureColumns.map((item) => ({
            title: item.title,
            content: item.columns
                .filter(
                    (subItem) => subItem.statusColumn === 'availableColumns',
                )
                .map((subItem) => ({
                    subTitle: subItem.label,
                    subContent: availableColumnComponent(subItem.label),
                })),
        }))
        setAvailableColumns(data)
    }

    const handleSearchAvailableColumns = (search: string) => {
        if (search) {
            const searchData = availableColumns
                .map((item) => {
                    const filteredContent = item.content.filter((subItem) =>
                        subItem.subTitle
                            .toLowerCase()
                            .includes(search.toLowerCase()),
                    )

                    if (filteredContent.length > 0) {
                        return { ...item, content: filteredContent }
                    }
                    return null
                })
                .filter((item): item is IAccordionItem => item !== null)

            searchData.length && setAvailableColumns(searchData)
        } else {
            loadAvailableColumns()
        }
    }

    const availableColumnComponent = (subTitle: string) => (
        <div className="flex justify-between items-center" key={subTitle}>
            <div>{subTitle}</div>
            <Button
                onClick={() => {
                    changeStatusColumn(subTitle, 'selectedColumns')
                }}
                icon="ri-arrow-right-s-fill text-size-XL"
                className="w-btnIconWidth"
            />
        </div>
    )

    const changeStatusColumn = (item: string, changeTo: string) => {
        const updateData = configureColumns.map((section) => ({
            ...section,
            columns: section.columns.map((column) =>
                column.label === item
                    ? { ...column, statusColumn: changeTo }
                    : column,
            ),
        }))
        setConfigureColumns(updateData)
    }

    const selectedColumnComponent = (title: string) => (
        <div className="flex gap-3 items-center">
            <Button
                onClick={() => {
                    changeStatusColumn(title, 'availableColumns')
                }}
                icon="ri-arrow-drop-left-fill text-size-XL"
                className="w-btnIconWidth"
                isDisabled={title === 'Shipment ID'}
            />
            <div>{title}</div>
        </div>
    )

    const sortHeader = (newItems: IListItem[]) => {
        const titleIndexMap = new Map(
            newItems.map((item, index) => [item.title, index]),
        )
        const updateData = configureColumns.flatMap((item) =>
            item.columns
                .filter((subItem) => subItem.statusColumn === 'selectedColumns')
                .sort((a, b) => {
                    const indexA = titleIndexMap.get(a.label) ?? -1
                    const indexB = titleIndexMap.get(b.label) ?? -1
                    return indexA - indexB
                }),
        )
        setHeaders(updateData)
    }

    const handleSearchSelectedColumns = (search: string) => {
        if (search && selectedColumns.length) {
            const searchData = selectedColumns.filter((item) =>
                item.title.toLowerCase().includes(search.toLowerCase()),
            )

            searchData.length && setSelectedColumns(searchData)
        } else {
            loadSelectedColumns()
        }
    }

    const moveItem = (dragIndex: number, hoverIndex: number) => {
        const dragItem = selectedColumns[dragIndex]
        const newItems = [...selectedColumns]
        newItems.splice(dragIndex, 1)
        newItems.splice(hoverIndex, 0, dragItem)
        setSelectedColumns(newItems)
        sortHeader(newItems)
    }

    const moveItemUp = (index: number) => {
        if (index > 0 && index < selectedColumns.length) {
            const newItems = [...selectedColumns]
            const item = newItems[index]
            newItems.splice(index, 1)
            newItems.splice(index - 1, 0, item)
            setSelectedColumns(newItems)
            sortHeader(newItems)
        }
    }

    const moveItemDown = (index: number) => {
        if (index >= 0 && index < selectedColumns.length - 1) {
            const newItems = [...selectedColumns]
            const item = newItems[index]
            newItems.splice(index, 1)
            newItems.splice(index + 1, 0, item)
            setSelectedColumns(newItems)
            sortHeader(newItems)
        }
    }

    return {
        selectedColumns,
        availableColumns,
        configureColumns,
        setConfigureColumns,
        handleSearchAvailableColumns,
        handleSearchSelectedColumns,
        moveItem,
        moveItemUp,
        moveItemDown,
        loadHeaders,
    }
}

export default useConfigureColumnsModalService
