import Button from "components/button/button.component"

interface HeaderProps {
    className: string;
    title: string;
    filter: string;
    isFilter: boolean;
    setIsFilter: React.Dispatch<React.SetStateAction<boolean>>;
}


const Header = ({ className, title, filter, isFilter, setIsFilter }: HeaderProps) => {
    return (
        <div
            className={`${className} content-center  flex justify-between max-h-max`}
        >
            <div className="justify-self-start self-center text-gray-400 lg:text-[12px] md:text-xs">
                <div className={`self-end  font-bold   ${isFilter ? 'themes-text-brand-v5' : 'text-gray-400'}`}>{title}</div>
                <div className={`self-start font-normal  ${isFilter ? 'themes-text-brand-v5' : 'text-gray-400'}`}>
                    {filter}
                </div>
            </div>
            <div className="self-center justify-self-end">
                <Button
                    onClick={() => { setIsFilter(!isFilter) }}
                    label={isFilter ? "Save" : "Filter"}
                    variant={isFilter ? "brand-v2" : "default"}
                    className="w-[60px] !h-5 !pt-[1px] rounded justify-center items-center gap-2.5 inline-flex  lg:text-[12px] md:text-xs font-bold"
                />
            </div>
        </div>
    )
}

export default Header
