import { IResponseData } from 'common/common.interface'
import { fetch } from 'common/common.service'
import { IRole } from './interface/role.interface'
import { IRoleModule } from './interface/role-module.interface'
import { endpoints } from 'common/endpoints'

export const getRole = async () => {
    try {
        let response = await fetch<IResponseData<IRole[]>, any>({
            endpoint: endpoints.common.role_getAll,
        })

        return response
    } catch (error: any) {
        console.error(error)
        const err = error as IResponseData<IRole[]>
        err.isSuccess = false
        return err
    }
}

export const getRoleModule = async (roleId: string) => {
    try {
        const response = await fetch<IResponseData<IRoleModule[]>, any>({
            endpoint: endpoints.common.role_getAllModule + roleId,
        })
        return response
    } catch (error: any) {
        console.error(error)
        const err = error as IResponseData<IRoleModule[]>
        err.isSuccess = false
        return err
    }
}
