import Input from 'components/input/input.component'
import Spinner from 'components/spinner/spinner.component'
import Textarea from 'components/text-area/text-area.component'
import { useTranslation } from 'react-i18next'
import { IShipmentDetails } from 'repository/ifb/interface/shipment.interface'

const Details = ({
    data,
    laoding,
}: {
    data: IShipmentDetails
    laoding: boolean
}) => {
    const spinnerShow = laoding ? '' : 'hidden'
    const detailShow = !laoding ? 'opacity-100' : 'opacity-0'
    const { t } = useTranslation()
    return (
        <div>
            <div
                className={`${spinnerShow} flex w-full items-center justify-center content-full-height`}
            >
                <Spinner label="Please Wait..." />
            </div>
            <div className="h-full grid grid-cols-4 gap-3 p-3">
                <div className={`flex flex-col gap-3 ${detailShow}`}>
                    <div>{t('shipments.shipments_information')}</div>
                    <div className="grid grid-cols-2 gap-3">
                        <Input
                            label={t('shipments.shippers_ref')}
                            value={data.shipper}
                            disabled={true}
                            parentInputClassName="col-span-2"
                        />
                        <Input
                            label={t('shipments.transport_mode')}
                            value={data.transport}
                            disabled={true}
                        />
                        <Input
                            label={t('shipments.container_mode')}
                            value={data.containerMode}
                            disabled={true}
                        />
                        <Input
                            label={t('shipments.service_level')}
                            value={data.containerMode}
                            disabled={true}
                            parentInputClassName="col-span-2"
                        />
                        <Input label={t('shipments.packs')} disabled={true} value={data.packs} />
                        <Input
                            label={t('shipments.outer_pack_unit')}
                            value={data.packsType}
                            disabled={true}
                        />
                        <Input
                            label={t('shipments.volume_measure')}
                            value={`${data.volume} ${data.uv}`}
                            disabled={true}
                            parentInputClassName="col-span-2"
                        />
                        <Textarea
                            label={t('shipments.goods_description')}
                            disabled={true}
                            containerClassName="col-span-2"
                        />
                        <Input
                            label={t('shipments.house_bill')}
                            value={data.houseBill}
                            disabled={true}
                            parentInputClassName="col-span-2"
                        />
                    </div>
                </div>
                <div className="flex flex-col gap-3">
                    <div>{t('shipments.consignee_pickup_address')}</div>
                    <div className="grid grid-cols-2 gap-3">
                        <Input
                            label={t('shipments.company')}
                            disabled={true}
                            parentInputClassName="col-span-2"
                        />
                        <Input
                            label={t('shipments.address')}
                            disabled={true}
                            parentInputClassName="col-span-2"
                        />
                        <Input
                            label={t('shipments.additional_address')}
                            disabled={true}
                            parentInputClassName="col-span-2"
                        />
                        <Input
                            label={t('shipments.contact_name')}
                            disabled={true}
                            parentInputClassName="col-span-2"
                        />
                        <Input label={t('shipments.contact_phone')} disabled={true} value={''} />
                        <Input label={t('shipments.contact_mobile')} value={''} disabled={true} />
                        <Input
                            label={t('shipments.contact_email_address')}
                            value={''}
                            disabled={true}
                            parentInputClassName="col-span-2"
                        />
                    </div>
                    <div>{t('shipments.origin')}</div>
                    <div className="grid grid-cols-2 gap-3">
                        <Input
                            label={t('shipments.port_name')}
                            value={data.origin}
                            disabled={true}
                            parentInputClassName="col-span-2"
                        />
                        <Input label={t('shipments.etd_shipment')} value={data.etd} disabled={true} />
                        <Input
                            label={t('shipments.estimated_pickup')}
                            value={''}
                            disabled={true}
                        />
                        <Input
                            label={t('shipments.pickup_requested_by')}
                            disabled={true}
                            parentInputClassName="col-span-2"
                        />
                        <Input
                            label={t('shipments.port_transport_advised')}
                            disabled={true}
                            value={''}
                            parentInputClassName="col-span-2"
                        />
                        <Input
                            label={t('shipments.actual_pickup')}
                            value={''}
                            disabled={true}
                            parentInputClassName="col-span-2"
                        />
                    </div>
                </div>
                <div className="flex flex-col gap-3">
                    <div> {t('shipments.consignee_pickup_address')}</div>
                    <div className="grid grid-cols-2 gap-3">
                        <Input
                            label={t('shipments.company')}
                            disabled={true}
                            parentInputClassName="col-span-2"
                        />
                        <Input
                            label={t('shipments.address')}
                            disabled={true}
                            parentInputClassName="col-span-2"
                        />
                        <Input
                            label={t('shipments.additional_address')}
                            value={''}
                            disabled={true}
                            parentInputClassName="col-span-2"
                        />
                        <Input
                            label={t('shipments.contact_name')}
                            value={''}
                            disabled={true}
                            parentInputClassName="col-span-2"
                        />
                        <Input label={t('shipments.contact_phone')} disabled={true} value={''} />
                        <Input label={t('shipments.contact_mobile')} value={''} disabled={true} />
                        <Input
                            label={t('shipments.contact_email_address')}
                            disabled={true}
                            parentInputClassName="col-span-2"
                        />
                    </div>
                    <div>{t('shipments.destination')}</div>
                    <div className="grid grid-cols-2 gap-3">
                        <Input
                            label={t('shipments.port_name')}
                            value={data.destination}
                            disabled={true}
                            parentInputClassName="col-span-2"
                        />
                        <Input label={t('shipments.etd_shipment')} value={data.eta} disabled={true} />
                        <Input
                            label={t('shipments.estimated_delivery')}
                            value={''}
                            disabled={true}
                        />
                        <Input
                            label={t('shipments.delivery_requested_by')}
                            value={''}
                            disabled={true}
                            parentInputClassName="col-span-2"
                        />
                        <Input
                            label={t('shipments.delivery_port_transport_advised')}
                            disabled={true}
                            value={''}
                            parentInputClassName="col-span-2"
                        />
                        <Input
                            label={t('shipments.delivery_date')}
                            value={''}
                            disabled={true}
                            parentInputClassName="col-span-2"
                        />
                    </div>
                </div>
                <div className="flex flex-col gap-3">
                    <div>{t('shipments.charges')}</div>
                    <div className="grid grid-cols-2 gap-3">
                        <Input
                            label={t('shipments.inco_term')}
                            value={data.incoterm}
                            disabled={true}
                            parentInputClassName="col-span-2"
                        />
                        <Input
                            label={t('shipments.goods_value')}
                            value={data.goodValue}
                            disabled={true}
                            parentInputClassName="col-span-2"
                        />
                        <Input
                            label={t('shipments.goods_value_currency')}
                            value={data.goodValueCurrency}
                            disabled={true}
                            parentInputClassName="col-span-2"
                        />
                        <Input
                            label={t('shipments.additional_terms')}
                            value={data.additionalTerm}
                            disabled={true}
                            parentInputClassName="col-span-2"
                        />
                        <Input
                            label={t('shipments.insurance_value')}
                            value={data.insuranceValue}
                            disabled={true}
                            parentInputClassName="col-span-2"
                        />
                        <Input
                            label={t('shipments.insurance_value_currency')}
                            value={data.insuranceValueCurrency}
                            disabled={true}
                            parentInputClassName="col-span-2"
                        />
                        <Input
                            label={t('shipments.charges_apply')}
                            value={data.chargeApply}
                            disabled={true}
                            parentInputClassName="col-span-2"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Details
