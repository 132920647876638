import Button from 'components/button/button.component'
import { IButtonExport } from './button-export.interface'
import useButtonExport from './button-export.service'
import { useTranslation } from 'react-i18next'

function ButtonExport({
    module,
    headers,
    onExport,
    exportType,
    getDataGenerate,
    generateReportData,
}: IButtonExport) {
    const { t } = useTranslation()
    const { exportPdf, exportXlxs } = useButtonExport({
        headers,
        module,
        getDataGenerate,
        generateReportData,
    })

    return (
        <>
            <Button
                label={t('action.generate_report')}
                onClick={async () => {
                    if (exportType === 'pdf') exportPdf()
                    if (exportType === 'xlxs') exportXlxs()
                    onExport && onExport()
                }}
                className="w-full"
            />
        </>
    )
}
export default ButtonExport
