import React, { FC, useCallback } from 'react';
import {
    BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer
} from 'recharts';
import { IBarChartStackedProps, IRoundedBarProps } from '../rechart-interface';

const BarChartStacked: FC<IBarChartStackedProps> = ({ data, useLabel = false }) => {
    const renderCustomizedLabel = useCallback((props: any) => {
        const { x, y, width, value, index, height } = props;

        const dataItem = data.data[index] as unknown as Record<string, number>;

        const totalF = data.key.reduce((sum, keyItem) => {
            return sum + (dataItem[keyItem.id] as number);
        }, 0);

        const percentage = ((value / totalF) * 100).toFixed(2);

        return (
            <text x={x + width / 2} y={y + height / 2} fill="#465A6C" textAnchor="middle" fontSize={12} fontWeight="bold">
                {percentage}%
            </text>
        );
    }, [data]);

    const RoundedTopBar = (props: IRoundedBarProps) => {
        const { x, y, width, height, fill, index } = props;
        const isTopBar = index === data.key.length - 1;

        return (
            <g>
                <rect
                    x={x}
                    y={y}
                    width={width}
                    height={height}
                    fill={fill}
                />
                {isTopBar && (
                    <rect
                        x={x}
                        y={y}
                        width={width}
                        height={10}
                        fill={fill}
                        rx={10}
                        ry={10}
                    />
                )}
            </g>
        );
    };

    return (
        <ResponsiveContainer width="100%" height="100%">
            <BarChart
                width={500}
                height={300}
                data={data.data}
                margin={{
                    top: 0,
                    right: 0,
                    left: -20,
                    bottom: -5,
                }}
                barCategoryGap="10%"
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                    dataKey="name"
                    tick={{ fill: '#666' }}
                    style={{ fontSize: '12px' }}
                />
                <YAxis
                    tick={{ fill: '#666' }}
                    style={{ fontSize: '12px' }}
                />
                <Tooltip />
                {data.key.map((item, idx) => (
                    <Bar
                        key={`item-bar-${idx}`}
                        dataKey={item.id}
                        stackId="a"
                        fill={item.color}
                        label={useLabel ? renderCustomizedLabel : undefined}
                        shape={<RoundedTopBar index={idx} />}
                    />
                ))}
            </BarChart>
        </ResponsiveContainer>
    );
};

export default BarChartStacked;
