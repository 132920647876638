import { ErrorMessage, useField } from 'formik'
import Textarea from 'components/text-area/text-area.component'
import { IFormTextarea } from './form-textarea.interface'

const FormTextarea = ({
    readonly = false,
    parentDivClassName = '',
    hideError = false,
    ...props
}: IFormTextarea) => {
    //formik
    const [field, meta] = useField(props.name)
    const borderColorClass =
        meta.error && meta.touched ? 'themes-border-red' : ''
    const isError = !(!meta.error || (meta.error && !meta.touched))
    const errorClassMessage = isError && !hideError ? '' : 'hidden'

    return (
        <div className={`${parentDivClassName}`}>
            <div className="flex relative h-full">
                <Textarea
                    {...props}
                    value={field.value}
                    onChange={field.onChange}
                    placeholder={props.placeholder}
                    className={`${borderColorClass} ${props.className}`}
                    readonly={readonly}
                />
            </div>

            <div
                className={`ml-small-x text-size-XS themes-text-red-v3 ${errorClassMessage}`}
            >
                <ErrorMessage name={props.name} component="p" />
            </div>
        </div>
    )
}

export default FormTextarea
