import React, { PureComponent } from 'react';
import {
    ScatterChart,
    Scatter,
    XAxis,
    YAxis,
    ZAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
    LabelList,
} from 'recharts';
import { IJointLineScatterChartProps, IkeyBarChart } from '../rechart-interface';

export default class JointLineScatterChart extends PureComponent<IJointLineScatterChartProps> {
    render() {
        const { data } = this.props;
        const keyItem = data.key;

        const monthFormatter = (tick: number) => {
            const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
            return months[tick - 1];
        };

        return (
            <ResponsiveContainer width="100%" height="100%">
                <ScatterChart
                    margin={{
                        right: 10,
                        left: -40
                    }}
                >
                    <CartesianGrid />
                    <XAxis
                        type="number"
                        dataKey="x"
                        name="month"
                        unit=""
                        domain={[1, 12]}
                        tickFormatter={monthFormatter}
                        ticks={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]}
                        axisLine={{ stroke: 'rgba(0, 0, 0, 0.3)', strokeWidth: 1 }}
                        tickLine={false}
                    />
                    <YAxis
                        type="number"
                        dataKey="y"
                        name="value"
                        unit=""
                        axisLine={{ stroke: 'rgba(0, 0, 0, 0.3)', strokeWidth: 1 }}
                    />
                    <ZAxis type="number" range={[20]} />
                    <Tooltip cursor={{ strokeDasharray: '3 3' }} />
                    {keyItem.map((item: IkeyBarChart, idx: number) => (
                        <Scatter key={`data-chart-item-${idx}`} name={item.id} data={data[item.id]} fill={item.color} line>
                            <LabelList dataKey="y" position="top" />
                        </Scatter>
                    ))}
                </ScatterChart>
            </ResponsiveContainer>
        );
    }
}
