import { Bar, BarChart, CartesianGrid, ResponsiveContainer, XAxis, YAxis, Tooltip, Cell } from 'recharts'
import { useEffect, useState } from 'react';
import { IBarChartCustomProps, ICustomTooltipProps, ICustomizedXAxisTickProps, IRoundedBarProps } from '../rechart-interface';
import { useTranslation } from 'react-i18next';
import { TranslationCode } from '../../../@types/translation-code';

function BarChartCustom({ data, tooltipSingle, className }: IBarChartCustomProps) {
    const barColors = ["#D9DDE1", "#D9DDE1", "#D9DDE1", "#D9DDE1", "#D9DDE1", "#D9DDE1", "#D8E7FF"]
    const [widthSize, setWidthSize] = useState(window.innerWidth);
    const [heightSize, setHeightSize] = useState(window.innerHeight);
    const { t } = useTranslation()
    const [dimension, setDimension] = useState({
        width: widthSize,
        height: 235
    });

    const updateDimensions = () => {
        setWidthSize(window.innerWidth);
        setHeightSize(window.innerHeight);
        const newDimension = { ...dimension }
        if (heightSize === 1080) newDimension.height = 250
        else if (heightSize < 1440 && heightSize > 1300) {
            newDimension.height = 440
        }
        else if (heightSize === 911) newDimension.height = 320
        setDimension(newDimension)
    };

    useEffect(() => {
        window.addEventListener('resize', updateDimensions);
        return () => {
            window.removeEventListener('resize', updateDimensions);
        };
    });

    const formatXAxis = (tickFormat: number) => {
        return tickFormat !== 0 ? tickFormat / 1000 + "" : '0';
    };

    const CustomTooltip = ({ active, payload }: ICustomTooltipProps) => {
        if (active && payload && payload.length) {
            return (
                <div className="flex flex-row gap-2 bg-gray-700 rounded-lg text-white text-xs font-normal px-4 py-2 font-['Basis Grotesque Arabic Pro']">
                    <p className="w-2 h-2 bg-blue-100 rounded-full self-center"></p>
                    <p className="label">
                        {`${payload[0]?.payload?.toolTipName ? payload[0]?.payload?.toolTipName : ''} ${tooltipSingle ? tooltipSingle : ''} : ${payload[0].value}`}
                    </p>
                </div>
            );
        }

        return null;
    };

    const RoundedBar = (props: IRoundedBarProps) => {
        const { x, y, width, height } = props;
        return (
            <g>
                <rect
                    x={x}
                    y={y}
                    width={width}
                    height={height}
                    fill={props.fill}
                    rx={5}
                    ry={5}
                />
            </g>
        );
    };

    const CustomizedXAxisTick = ({ x, y, payload, width }: ICustomizedXAxisTickProps) => {
        if (payload === undefined) {
            if (width === undefined) width = 0;
            payload = { value: "" };
        }
        const words = payload.value.split(" ");
        const maxWordsPerLine = 3;
        let lines = [];
        for (let i = 0; i < words.length; i += maxWordsPerLine) {
            lines.push(words.slice(i, i + maxWordsPerLine).join(" "));
        }

        const isTextOverlapping = width ? width < lines.join("").length * 15 : 0;

        return (
            <g transform={`translate(${x},${y})`}>
                {isTextOverlapping ? null : (
                    lines.map((line, index) => {
                        const nameLine: TranslationCode = line as TranslationCode;
                        return (
                            <text
                                key={index}
                                x={width ? width / 1000 : 0}
                                y={index * 16}
                                dy={16}
                                textAnchor="middle"
                                fill="#666"
                                fontSize={heightSize < 900 ? 9 : 12}
                            >
                                {t(nameLine)}
                            </text>
                        )
                    })
                )}
            </g>
        );
    };

    return (
        <ResponsiveContainer
            width="100%"
            height="100%"
            className={className}
        >
            <BarChart width={150} height={40} data={data} margin={{
                top: 5,
                right: 5,
                left: -25,
                bottom: -10,
            }}>
                <CartesianGrid stroke="#f5f5f5" />
                <XAxis
                    dataKey="name"
                    interval={0}
                    height={70}
                    tick={<CustomizedXAxisTick />} />
                <YAxis
                    tickFormatter={(tick) => formatXAxis(tick)}
                    type={'number'}
                />
                <Tooltip content={<CustomTooltip />} />
                <Bar dataKey="number" fill="#D9DDE1" shape={<RoundedBar />}>
                    {
                        data.map((_entry, index: number) => (
                            <Cell key={`cell-${index}`} fill={barColors[index % 20]} />
                        ))
                    }
                </Bar>
            </BarChart>
        </ResponsiveContainer>
    )
}
export default BarChartCustom