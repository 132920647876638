/* eslint-disable no-unused-vars */
import { IUseModal } from 'components/modal/modal.service'
import { eDocumentationHeader } from '../shipments-details.static'
import SimpleTable from 'components/simple-table/simple-table.component'
import { IEDocumentationDetails } from 'pages/jpl/shipments/shipments.interface'

const EDocumentation = ({
    modalComponent,
    modalService,
    data,
    component,
}: {
    modalComponent: JSX.Element
    modalService: IUseModal
    data: IEDocumentationDetails[]
    component: (data: IEDocumentationDetails) => JSX.Element
}) => {
    return (
        <>
            <SimpleTable<IEDocumentationDetails>
                headers={eDocumentationHeader}
                data={data || []}
                isAddRow={true}
                addRowClick={() => {
                    modalService.openModalHandling()
                }}
                loading={false}
                customBuildParam2={component}
            />
            {modalComponent}
        </>
    )
}

export default EDocumentation
