import { IMeta } from 'common/common.interface'
import { IDocumentsProduct, IProduct } from './products.interface'

export const ProductDataDummy: IProduct[] = [
    {
        partNumber: 'PN-001',
        partDescription: 'Widget Assembly',
        model: 'WidgetModel X1',
        weight: '10.5 KG',
    },
    {
        partNumber: 'PN-002',
        partDescription: 'Gearbox Unit',
        model: 'GearboxModel Y2',
        weight: '7.2 KG',
    },
    {
        partNumber: 'PN-003',
        partDescription: 'Electronic Circuit Board',
        model: 'CircuitBoardModel Z3',
        weight: '15.8 KG',
    },
    {
        partNumber: 'PN-004',
        partDescription: 'Stainless Steel Shaft',
        model: 'SteelShaftModel S4',
        weight: '3.9 KG',
    },
    {
        partNumber: 'PN-005',
        partDescription: 'Rubber Gasket Set',
        model: 'RubberGasketModel R5',
        weight: '20.1 KG',
    },
    {
        partNumber: 'PN-006',
        partDescription: 'Aluminum Frame',
        model: 'AluminumFrameModel A6',
        weight: '12.3 KG',
    },
    {
        partNumber: 'PN-007',
        partDescription: 'Plastic Enclosure',
        model: 'PlasticEnclosureModel P7',
        weight: '9.0 KG',
    },
    {
        partNumber: 'PN-008',
        partDescription: 'Glass Display Panel',
        model: 'GlassPanelModel G8',
        weight: '18.7 KG',
    },
    {
        partNumber: 'PN-009',
        partDescription: 'Copper Wire Bundle',
        model: 'CopperWireModel C9',
        weight: '4.4 KG',
    },
    {
        partNumber: 'PN-010',
        partDescription: 'Nylon Fasteners Kit',
        model: 'FastenersKitModel F10',
        weight: '11.2 KG',
    },
    {
        partNumber: 'PN-011',
        partDescription: 'Carbon Fiber Component',
        model: 'CarbonFiberModel CF11',
        weight: '8.5 KG',
    },
    {
        partNumber: 'PN-012',
        partDescription: 'Ceramic Heating Element',
        model: 'CeramicHeaterModel CH12',
        weight: '14.6 KG',
    },
    {
        partNumber: 'PN-013',
        partDescription: 'LED Light Module',
        model: 'LEDModuleModel L13',
        weight: '11.5 KG',
    },
    {
        partNumber: 'PN-014',
        partDescription: 'LCD Screen',
        model: 'LCDScreen L14',
        weight: '8.2 KG',
    },
    {
        partNumber: 'PN-015',
        partDescription: 'OLED Display',
        model: 'OLEDDisplay D15',
        weight: '16.8 KG',
    },
]


export const documentsData: IDocumentsProduct[] = [
    {
        packs: '10',
        weightMeasure: '15kg',
        goodsDescription: 'Electronics',
        lengthMeasure: '100cm',
        widthMeasure: '50cm',
        heightMeasure: '30cm',
        volumeMeasure: '150000cm³',
    },
    {
        packs: '5',
        weightMeasure: '8kg',
        goodsDescription: 'Books',
        lengthMeasure: '40cm',
        widthMeasure: '30cm',
        heightMeasure: '20cm',
        volumeMeasure: '24000cm³',
    },
    {
        packs: '20',
        weightMeasure: '50kg',
        goodsDescription: 'Furniture',
        lengthMeasure: '200cm',
        widthMeasure: '100cm',
        heightMeasure: '150cm',
        volumeMeasure: '3000000cm³',
    },
    {
        packs: '12',
        weightMeasure: '22kg',
        goodsDescription: 'Clothing',
        lengthMeasure: '60cm',
        widthMeasure: '40cm',
        heightMeasure: '25cm',
        volumeMeasure: '60000cm³',
    },
    {
        packs: '8',
        weightMeasure: '12kg',
        goodsDescription: 'Toys',
        lengthMeasure: '50cm',
        widthMeasure: '35cm',
        heightMeasure: '20cm',
        volumeMeasure: '35000cm³',
    },
];


export const metaDummy: IMeta = {
    current_page: 1,
    last_page: 1,
    from: 1,
    to: 50,
    total_Items: 15,
    per_page: 0,
    total_page: 1,
    index_end: 15,
    index_start: 1,
}
