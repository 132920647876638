/* eslint-disable no-unused-vars */
import Accordion from 'components/accordion/accordion.component'
import Button from 'components/button/button.component'
import List from 'components/list/list.component'
import Modal from 'components/modal/modal.component'
import { IUseModal } from 'components/modal/modal.service'
import useConfigureColumnsModalService from './configure-columns-modal.service'
import { IConfigureColumns } from './configure-column-modal.interface'
import { useTranslation } from 'react-i18next'

const ConfigureColumnsModal = <T,>({
    modalService,
    columns,
    setHeaders,
}: {
    modalService: IUseModal
    columns: IConfigureColumns<T>[]
    setHeaders: (headers: any) => void
}) => {
    const { t } = useTranslation()
    const {
        selectedColumns,
        availableColumns,
        configureColumns,
        setConfigureColumns,
        handleSearchAvailableColumns,
        handleSearchSelectedColumns,
        moveItem,
        moveItemUp,
        moveItemDown,
        loadHeaders,
    } = useConfigureColumnsModalService(columns, setHeaders)

    const configureColumnsAccordion = (
        <Accordion
            header={t('additional.available_column')}
            items={availableColumns}
            onSearchSubmit={handleSearchAvailableColumns}
        />
    )

    const configureColumnsList = (
        <List
            header={t('additional.selected_column')}
            items={selectedColumns}
            onSearchSubmit={handleSearchSelectedColumns}
            moveItem={moveItem}
            moveItemUp={moveItemUp}
            moveItemDown={moveItemDown}
            isDraggable={true}
        />
    )

    return (
        <Modal isModalOpen={modalService.isModalOpen} className="!w-1/2 px-0">
            <div>
                <div className="flex justify-between items-center px-3 pb-3">
                    <div>{t('additional.configure_column')}</div>
                    <Button
                        onClick={() => modalService.closeModalHandling()}
                        icon="ri-close-fill"
                        className="border-none w-btnIconWidth"
                    />
                </div>
                <div className="border-b"></div>
                <div className="p-3 flex gap-3 h-[calc(100vh-15rem)]">
                    <div className="w-1/2">{configureColumnsAccordion}</div>
                    <div className="w-1/2">{configureColumnsList}</div>
                </div>
                <div className="border-b"></div>
                <div className="flex gap-3 px-3 mt-3">
                    <Button
                        useUpperCase={true}
                        onClick={() => setConfigureColumns(configureColumns)}
                        label={t('action.reset')}
                        className="w-1/4"
                    />
                    <Button
                        useUpperCase={true}
                        onClick={() => {
                            loadHeaders()
                            modalService.closeModalHandling()
                        }}
                        label={t('action.apply', { value: '' })}
                        variant="brand"
                        className="w-3/4"
                    />
                </div>
            </div>
        </Modal>
    )
}

export default ConfigureColumnsModal
