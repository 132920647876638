/* eslint-disable no-unused-vars */
import '../purchase-order.style.css'
import Button from 'components/button/button.component'
import SimpleTable from 'components/simple-table/simple-table.component'
import { usePODetail } from './purchase-order-detail.service'
import { IPoLineItem } from './purchase-order-detail.interface'
import Pagination from 'components/pagination/pagination.component'
import './purchase-order-detail.style.css'
import StatusCard from 'components/status-card/status-card.component'
import PoHistoryModal from './modal/po-history-modal.component'
import { useNavigate } from 'react-router-dom'
import UpdatePOStatusModal from './modal/update-po-confirmation.component'
import CancelationInputModal from './modal/cancelation-input-po.components'
import CancelationReasonModal from './modal/cancelation-reason-po.components'
import { Toast } from 'components/toast/toast.component'
import Spinner from 'components/spinner/spinner.component'
import { FormikProvider } from 'formik'
import FormDropdown from 'components/form-dropdown/form-dropdown.component'
import EditModalSupplierModal from './modal/edit-supplier-modal.component'
import RelatedShipmentModal from './modal/related-shipment-modal.component'
import RequestDetachPOModal from './modal/request-detach-po-confirmation.component'
import DetachReattachConfirmationModal from './modal/detach-reattach-modal.component'

const PurchaseOrderDetail = () => {
    const navigate = useNavigate()
    const {
        poDetailData,
        loadingDetailData,
        poHistoryModalService,
        poHistoryError,
        poStatusModalService,
        loadingHistoryData,
        poHistoryData,
        buttonStatus,
        organization,
        poCancelInputModalService,
        poCancelodalService,
        poLinesData,
        poLinesMeta,
        headerLineItems,
        formik,
        user,
        isEditLineItems,
        countryList,
        toast,
        formikContactSupplier,
        editContactSupplierModalService,
        loadingUpdatePoLine,
        loadingLineData,
        poRelatedShipmentModal,
        relatedShipment,
        requestDetachPoModalService,
        detachReattachModalService,
        isShipmentExists,
        isAbleReattach,
        isDetatchRequestLoading,
        getComponentDetail,
        changeStatus,
        setIsEditLineItems,
        chageFromGoodsAll,
        requestDetatchPo,
        detachReattachPo,
    } = usePODetail()
    const spinnerShow = loadingDetailData ? '' : 'hidden'
    const detailShow = !loadingDetailData ? 'opacity-100' : 'opacity-0'
    const isButtonStatusDisabled = (): boolean => {
        if (user.organizationCode === 'jpl') {
            if (isShipmentExists) {
                return true
            } else {
                return false
            }
        }
        return false
    }
    const editLineItemButton = () => {
        if (!isEditLineItems) {
            return (
                <Button
                    onClick={() => {
                        setIsEditLineItems(true)
                    }}
                    label="Edit Line Items"
                    useUpperCase={true}
                    className="w-[180px]"
                    icon="ri-edit-2-line"
                />
            )
        } else {
            return (
                <div className="flex gap-3">
                    <div className="flex items-center  ">
                        <FormDropdown
                            key={'change-all'}
                            parentDivClassName="w-[200px] !mb-0 "
                            options={countryList}
                            name={`data-header`}
                            isSearchable={true}
                            onClick={(value: any) => {
                                chageFromGoodsAll(value)
                            }}
                        />
                    </div>
                    <Button
                        onClick={() => {
                            formik.handleSubmit()
                        }}
                        isLoading={loadingUpdatePoLine}
                        label="save"
                        useUpperCase={true}
                        className="w-[180px]"
                        icon="ri-save-line"
                        variant="brand"
                    />
                    <Button
                        onClick={() => {
                            setIsEditLineItems(false)
                        }}
                        useUpperCase={true}
                        className="font-bold"
                        icon="ri-close-line"
                    />
                </div>
            )
        }
    }
    return (
        <>
            <div
                className={`${spinnerShow} flex w-full items-center justify-center content-full-height`}
            >
                <Spinner label="Please Wait..." />
            </div>
            <div
                className={`${detailShow} flex flex-wrap gap-x-4 sm:gap-y-2 h-[calc(100vh-131px)] purchase-order-detail opacity-0 transition-opacity duration-500 `}
            >
                {/* Flex 1 */}
                <div className="flex-initial min-w-[250px]  sm:w-[calc(100vw-1rem)] flex themes-bg-white container-border container-global">
                    <div className="w-full h-full flex flex-col justify-between">
                        <div>
                            <div className="font-medium p-4 border-b themes-border-color text-size-M">
                                {loadingDetailData ? (
                                    'Loading Data'
                                ) : (
                                    <>
                                        <div className="inline-block mr-2">
                                            {' '}
                                            PO-{poDetailData.poNo}
                                        </div>
                                        <StatusCard
                                            status={poDetailData.status}
                                        />
                                    </>
                                )}
                            </div>

                            <div className=" p-4 text-left flex flex-col gap-y-4 h-[calc(100vh-255px)] overflow-auto">
                                {getComponentDetail()}
                                <div
                                    className="themes-text-link"
                                    onClick={() => {
                                        poHistoryModalService.openModalHandling()
                                    }}
                                >
                                    VIEW PO HISTORY
                                </div>
                            </div>
                        </div>

                        <div className="p-4 flex justify-between text-size-L border-t border-gray-400">
                            <Button
                                label="BACK"
                                onClick={() => {
                                    navigate(-1)
                                }}
                            />
                            {!buttonStatus.isButtonVisible ? null : (
                                <Button
                                    label={buttonStatus.label}
                                    onClick={() => {
                                        buttonStatus.onClick &&
                                            buttonStatus.onClick()
                                    }}
                                    variant={buttonStatus.variant}
                                    isDisabled={isButtonStatusDisabled()}
                                />
                            )}
                        </div>
                    </div>
                </div>

                <div className="flex-grow flex md:gap-y-4 gap-x-4 md:w-[calc(100vw-900px)]  sm:w-[calc(100vw-1rem)] themes-bg-white container-border p-4 container-global">
                    {/* Flex 2.1 */}
                    <div className="flex flex-grow flex-col justify-between border rounded themes-border-color overflow-auto h-[calc(100vh-151px)]">
                        <FormikProvider value={formik}>
                            <div className="p-4 font-normal text-size-L border-b themes-border-color flex justify-between">
                                <div>Line Items</div>
                                <div>
                                    {user.organizationCode === 'jpl' ? (
                                        <></>
                                    ) : (
                                        editLineItemButton()
                                    )}
                                </div>
                            </div>
                            <div className="h-full overflow-auto">
                                <SimpleTable<IPoLineItem>
                                    data={poLinesData || []}
                                    headers={headerLineItems}
                                    loading={loadingLineData}
                                />
                            </div>
                            <div className="border-t themes-border-color flex justify-between items-center">
                                <Pagination
                                    meta={poLinesMeta}
                                    previousHandling={(page) => {
                                        console.log('hit prev API -> ' + page)
                                    }}
                                    nextHandling={(page) => {
                                        console.log('hit Next API -> ' + page)
                                    }}
                                />

                                {poDetailData.status !== 'In Progress' ? (
                                    <></>
                                ) : user.organizationCode === 'jpl' ? (
                                    <Button
                                        className="mr-4"
                                        label={'DETACH ALL SHIPMENT'}
                                        variant="brand-inverse"
                                        onClick={() => {
                                            detachReattachModalService.openModalHandling()
                                        }}
                                        isDisabled={!isShipmentExists}
                                    />
                                ) : (
                                    <Button
                                        className="mr-4"
                                        label={
                                            isShipmentExists
                                                ? 'REQUEST TO DETACH'
                                                : 'REQUEST TO CHANGE STATUS'
                                        }
                                        isLoading={isDetatchRequestLoading}
                                        onClick={() => {
                                            requestDetachPoModalService.openModalHandling()
                                        }}
                                    />
                                )}
                            </div>
                        </FormikProvider>
                    </div>
                </div>

                {/* Modal Section */}
                <PoHistoryModal
                    modalService={poHistoryModalService}
                    componentDetail={getComponentDetail}
                    status={poDetailData.status}
                    isLoading={loadingHistoryData}
                    errorMessage={poHistoryError}
                    data={poHistoryData}
                />

                <UpdatePOStatusModal
                    data={poDetailData}
                    modalService={poStatusModalService}
                    onSubmit={() => {
                        changeStatus()
                        if (toast) Toast(toast)
                    }}
                    organization={organization}
                />

                <CancelationInputModal
                    componentDetail={getComponentDetail}
                    data={poDetailData}
                    modalService={poCancelInputModalService}
                    onSubmit={(val) => {
                        console.log(val)

                        changeStatus(val)
                        if (toast) Toast(toast)
                    }}
                />

                <CancelationReasonModal
                    componentDetail={getComponentDetail}
                    data={poDetailData}
                    modalService={poCancelodalService}
                />
                <EditModalSupplierModal
                    formik={formikContactSupplier}
                    modalService={editContactSupplierModalService}
                />

                <RelatedShipmentModal
                    modalService={poRelatedShipmentModal}
                    data={relatedShipment}
                />

                <RequestDetachPOModal
                    data={poDetailData}
                    isShipmentExists={isShipmentExists}
                    modalService={requestDetachPoModalService}
                    onSubmit={() => {
                        requestDetatchPo()
                    }}
                />

                <DetachReattachConfirmationModal
                    data={poDetailData}
                    isReattach={isAbleReattach}
                    modalService={detachReattachModalService}
                    onSubmit={() => {
                        detachReattachPo()
                    }}
                />
            </div>
        </>
    )
}

export default PurchaseOrderDetail
