/* eslint-disable no-unused-vars */
import Tab from 'components/tab/tab.component'
import { tabConsigneeOrConsignorHeaders } from '../booking-details.static'
import { ITabItem } from 'components/tab/tab.interface'
import { IBookingDetails } from '../booking-details.interface'
import Textarea from 'components/text-area/text-area.component'
import Input from 'components/input/input.component'
import { useTranslation } from 'react-i18next'

const Details = ({
    getDetailsConsignorContent,
    getDetailsConsigneeContent,
    consignorTabFilter,
    consigneeTabFilter,
    setConsignorTabFilter,
    setConsigneeTabFilter,
    data,
}: {
    getDetailsConsignorContent: () => JSX.Element
    getDetailsConsigneeContent: () => JSX.Element
    consignorTabFilter: ITabItem
    consigneeTabFilter: ITabItem
    setConsignorTabFilter: (data: ITabItem) => void
    setConsigneeTabFilter: (data: ITabItem) => void
    data: IBookingDetails
}) => {
    const { t } = useTranslation()
    return (
        <div className="flex gap-3 p-3">
            <div className="w-1/4">
                <div>{t('bookings.consignor')}</div>
                <Tab
                    items={tabConsigneeOrConsignorHeaders}
                    tabFilter={consignorTabFilter}
                    onChange={(item) => {
                        setConsignorTabFilter(item)
                    }}
                />
                <div className="border-b"></div>
                <div>{getDetailsConsignorContent()}</div>
                <div className="my-2">{t('bookings.origin')}</div>
                <div className="flex flex-col gap-3">
                    <Input
                        label={t('bookings.origin_port')}
                        value={data.originPort}
                        disabled={true}
                    />
                    <Input
                        label={t('bookings.etd_shipment')}
                        value={data.etdShipment}
                        disabled={true}
                    />
                    <Input
                        label={t('bookings.estimated_time_of_pickup')}
                        value={data.estimatedTimeOfPickup}
                        disabled={true}
                    />
                    <Input
                        label={t('bookings.pickup_required_by')}
                        value={data.pickupRequiredBy}
                        disabled={true}
                    />
                    <Input
                        label={t('bookings.pickup_cartage_advised')}
                        value={data.pickupCartageAdvised}
                        disabled={true}
                    />
                    <Input
                        label={t('bookings.pickup_cartage_completed')}
                        value={data.pickupCartageCompleted}
                        disabled={true}
                    />
                </div>
            </div>
            <div className="w-1/4">
                <div>{t('bookings.consignor')}</div>
                <Tab
                    items={tabConsigneeOrConsignorHeaders}
                    tabFilter={consigneeTabFilter}
                    onChange={(item) => {
                        setConsigneeTabFilter(item)
                    }}
                />
                <div className="border-b"></div>
                <div>{getDetailsConsigneeContent()}</div>
                <div className="my-2">{t('bookings.destination')}</div>
                <div className="flex flex-col gap-3">
                    <Input
                        label={t('bookings.destination_port')}
                        value={data.destinationPort}
                        disabled={true}
                    />
                    <Input
                        label={t('bookings.etd_shipment')}
                        value={data.etdShipment}
                        disabled={true}
                    />
                    <Input
                        label={t('bookings.estimated_time_of_pickup')}
                        value={data.estimatedTimeOfPickup}
                        disabled={true}
                    />
                    <Input
                        label={t('bookings.delivery_required_by')}
                        value={data.deliveryRequiredBy}
                        disabled={true}
                    />
                    <Input
                        label={t('bookings.delivery_cartage_advised')}
                        value={data.deliveryCartageAdvised}
                        disabled={true}
                    />
                    <Input
                        label={t('bookings.delivery_cartage_completed')}
                        disabled={true}
                        value={data.deliveryCartrageCompleted}
                    />
                </div>
            </div>
            <div className="w-1/4">
                <div className="mb-2">{t('bookings.charges')}</div>
                <div className="flex flex-col gap-3">
                    <Input
                        label={t('bookings.incoterm')}
                        value={data.incoTerm}
                        disabled={true}
                    />
                    <Input
                        label={t('bookings.additional_terms')}
                        value={data.additionalTerms}
                        disabled={true}
                    />
                    <Input
                        label={t('bookings.goods_value')}
                        value={data.goodsValue}
                        disabled={true}
                    />
                    <Input
                        label={t('bookings.goods_value_currency')}
                        value={data.goodsValueCurrency}
                        disabled={true}
                    />
                    <Input
                        label={t('bookings.insurance_value')}
                        value={data.insuranceValue}
                        disabled={true}
                    />
                    <Input
                        label={t('bookings.insurance_value_currency')}
                        value={data.insuranceValueCurrency}
                        disabled={true}
                    />
                    <Input
                        label={t('bookings.charges_apply')}
                        value={data.chargesApply}
                        disabled={true}
                    />
                </div>
            </div>
            <div className="w-1/4">
                <div className="mb-2">{t('bookings.shipment_information')}</div>
                <div className="grid grid-cols-2 gap-3">
                    <Input
                        label={t('bookings.shippers_ref')}
                        value={data.shippersRef}
                        disabled={true}
                        parentInputClassName="col-span-2"
                    />
                    <Input
                        label={t('bookings.transport_mode')}
                        value={data.transportMode}
                        disabled={true}
                    />
                    <Input
                        label={t('bookings.container_mode')}
                        value={data.containerMode}
                        disabled={true}
                    />
                    <Input
                        label={t('bookings.service_level')}
                        value={data.serviceLevel}
                        disabled={true}
                        parentInputClassName="col-span-2"
                    />
                    <Input label="Packs" disabled={true} value={data.packs} />
                    <Input
                        label={t('bookings.outer_pack_unit')}
                        value={data.outerPackUnit}
                        disabled={true}
                    />
                    <Input
                        label={t('products.volume_measure')}
                        value={data.volumeMeasure.qty}
                        disabled={true}
                        parentInputClassName="col-span-2"
                    />
                    <Input
                        label={t('products.weight_measure')}
                        value={data.weightMeasure.qty}
                        disabled={true}
                        parentInputClassName="col-span-2"
                    />
                    <Input
                        label={t('bookings.chargeable_weight_volume')}
                        disabled={true}
                        value={data.chargeableWeightOrVolume.qty}
                        parentInputClassName="col-span-2"
                    />
                    <Textarea
                        label={t('products.goods_description')}
                        value={data.goodsDescription}
                        disabled={true}
                        containerClassName="col-span-2"
                    />
                    <Textarea
                        label={t('bookings.marks_and_numbers')}
                        value={data.marksAndNumbers}
                        disabled={true}
                        containerClassName="col-span-2"
                    />
                </div>
            </div>
        </div>
    )
}

export default Details
