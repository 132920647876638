// import DatePicker from 'components/date-picker/date-picker.component'
import Dropdown from 'components/dropdown/dropdown.component'
import Input from 'components/input/input.component'
import { useTranslation } from 'react-i18next'

const ShipmentFilter = ({
    isGenerateReport = false,
}: {
    isGenerateReport?: boolean
}) => {
    const { t } = useTranslation()
    return (
        <div
            className={`flex flex-col gap-5 filter-shipment p-4 overflow-auto ${isGenerateReport && '!py-2'} `}
        >
            <div className="flex-col w-full">
                <Input
                    label={t('quotes.shipment_id')}
                    placeholder={t('action.enter', {
                        value: t('quotes.shipment_id'),
                    })}
                />
            </div>
            <div className="flex-col w-full">
                <Dropdown
                    options={[
                        {
                            label: 'IDJKT - Jakarta, Java',
                            value: 'IDJKT - Jakarta, Java',
                        },
                        {
                            label: 'THBKK - Bangkok',
                            value: 'THBKK - Bangkok',
                        },
                        {
                            label: 'USLAX - Los Angeles',
                            value: 'USLAX - Los Angeles',
                        },
                        {
                            label: 'USNYC - New York',
                            value: 'USNYC - New York',
                        },
                    ]}
                    label={t('quotes.origin_port')}
                    onClick={() => {}}
                    placeholder={t('action.select', {
                        value: t('quotes.origin_port'),
                    })}
                    isClearable={true}
                    isMultiSelect={true}
                    isSearchable={true}
                />
            </div>
            <div className="flex-col w-full">
                <Dropdown
                    options={[
                        {
                            label: 'AUMEL - Melbourne',
                            value: 'AUMEL - Melbourne',
                        },
                    ]}
                    label={t('quotes.destination_port')}
                    onClick={() => {}}
                    placeholder={t('action.select', {
                        value: t('quotes.destination_port'),
                    })}
                    isClearable={true}
                    isMultiSelect={true}
                    isSearchable={true}
                />
            </div>
            <div className="flex-col w-full">
                <Dropdown
                    options={[
                        {
                            label: 'UTC',
                            value: 'utc',
                        },
                    ]}
                    label={t('quotes.system_create_time_utc')}
                    placeholder={t('action.select', {
                        value: t('quotes.system_create_time_utc'),
                    })}
                    onClick={() => {}}
                    isClearable={true}
                    isMultiSelect={true}
                    isSearchable={true}
                />
            </div>
            <div className="flex-col w-full">
                <Dropdown
                    options={[
                        {
                            label: 'OneByOne Logistical PTY LTD',
                            value: 'OneByOne Logistical PTY LTD',
                        },
                    ]}
                    onClick={() => {}}
                    placeholder={t('action.add', {
                        value: t('action.filter'),
                    })}
                    isClearable={true}
                    isMultiSelect={true}
                    isSearchable={true}
                />
            </div>
        </div>
    )
}

export default ShipmentFilter
