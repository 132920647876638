import { useState } from 'react'
import Details from './content/details.component'
import { ITabItem } from 'components/tab/tab.interface'
import { useModal } from 'components/modal/modal.service'
import PackLines from './content/pack-lines.component'
import Notes from './content/notes.component'
import Milestones from './content/milestones.component'
import Event from './content/event.component'
import EDocumentation from './content/e-documentation.component'
import ConfigureColumnsModal from 'components/configure-columns-modal/configure-columns-modal.component'
import {
    IEventsTracking,
    IMilestonesTracking,
    INotesTracking,
    IPackLinesTracking,
} from '../trackings.interface'
import {
    EventsTrackingConfigureColumns,
    MilestonesTrackingConfigureColumns,
    NotesTrackingConfigureColumns,
    PaackLinesTrackingConfigureColumns,
} from './trackings-details.static'
import { ISTColumn } from 'components/simple-table/simple-table.interface'

export const useTrackingDetails = () => {
    const [headersPackLines, setHeadersPackLines] = useState<
        ISTColumn<IPackLinesTracking>[]
    >([])
    const [headersNotes, setHeadersNotes] = useState<
        ISTColumn<INotesTracking>[]
    >([])
    const [headersMilestones, setHeadersMilestones] = useState<
        ISTColumn<IMilestonesTracking>[]
    >([])
    const [headersEvents, setHeadersEvents] = useState<
        ISTColumn<IEventsTracking>[]
    >([])
    const [tabFilter, setTabFilter] = useState<ITabItem>({
        label: 'View Trackings Details',
        key: 'status',
        value: 'details',
        className: 'brand',
    })

    // modal
    const ConfigureColumnsModalServiceNotes = useModal()
    const ConfigureColumnsModalServicePackLines = useModal()
    const ConfigureColumnsModalServiceMilestones = useModal()
    const ConfigureColumnsModalServiceEvents = useModal()

    const getContentTrackingDetails = (tabFilter: ITabItem) => {
        const items: Record<string, JSX.Element> = {
            details: Details(),
            packLines: PackLines(),
            eDocs: EDocumentation(),
            notes: Notes(),
            milestones: Milestones(),
            events: Event(),
        }
        return items[tabFilter?.value] || <></>
    }

    // segment general
    const allModalPackLines = (
        <>
            <ConfigureColumnsModal<IPackLinesTracking>
                modalService={ConfigureColumnsModalServicePackLines}
                columns={PaackLinesTrackingConfigureColumns}
                setHeaders={setHeadersPackLines}
            />
        </>
    )

    const allModalNotes = (
        <>
            <ConfigureColumnsModal<INotesTracking>
                modalService={ConfigureColumnsModalServiceNotes}
                columns={NotesTrackingConfigureColumns}
                setHeaders={setHeadersNotes}
            />
        </>
    )

    const allModalMilestones = (
        <>
            <ConfigureColumnsModal<IMilestonesTracking>
                modalService={ConfigureColumnsModalServiceMilestones}
                columns={MilestonesTrackingConfigureColumns}
                setHeaders={setHeadersMilestones}
            />
        </>
    )

    const allModalEvents = (
        <>
            <ConfigureColumnsModal<IEventsTracking>
                modalService={ConfigureColumnsModalServiceEvents}
                columns={EventsTrackingConfigureColumns}
                setHeaders={setHeadersEvents}
            />
        </>
    )

    return {
        getContentTrackingDetails,
        setTabFilter,
        tabFilter,
        headersPackLines,
        headersNotes,
        headersMilestones,
        headersEvents,
        allModalMilestones,
        allModalPackLines,
        allModalEvents,
        allModalNotes,
        ConfigureColumnsModalServiceMilestones,
        ConfigureColumnsModalServiceNotes,
        ConfigureColumnsModalServicePackLines,
        ConfigureColumnsModalServiceEvents,
    }
}
