import React from 'react'
import { ResponsiveContainer } from 'recharts'

interface ProgressBarProps {
    data: {
        value: number
        color: string
        textTopRight?: string
        textBotRight?: string
        textTopLeft?: string
        textBotLeft?: string
    }[]
}

const ProgressBar: React.FC<ProgressBarProps> = ({ data }) => {
    const total = data.reduce((sum, item) => sum + item.value, 0)
    const intervals = []

    // Generate intervals
    for (let i = 0; i <= total; i += 10) {
        intervals.push(i)
    }
    return (
        <ResponsiveContainer width="100%" height="100%">
            <div className="flex-grow flex flex-col item-start h-full">
                <div className="flex-grow my-[8%]">
                    <div className="relative w-full bg-gray-300 h-6 flex rounded-none">
                        {data.map((item, index) => {
                            const percentage = (item.value / total) * 100
                            const showTextTopRight =
                                item.textTopRight &&
                                (index === 0 ||
                                    item.color !== data[index - 1].color)
                            const showTextBotRight =
                                item.textBotRight &&
                                (index === data.length - 1 ||
                                    item.color !== data[index + 1].color)
                            const showTextTopLeft =
                                item.textTopLeft &&
                                (index === 0 ||
                                    item.color !== data[index - 1].color)
                            const showTextBotLeft =
                                item.textBotLeft &&
                                (index === data.length - 1 ||
                                    item.color !== data[index + 1].color)

                            return (
                                <div
                                    key={index}
                                    className={`relative  h-[150%]   themes-bg-${item.color}`}
                                    style={{ width: `${percentage}%` }}
                                    title={`Item ${index + 1}: ${percentage.toFixed(2)}%`}
                                >
                                    {showTextTopRight && (
                                        <div
                                            className="absolute themes-text-gray-v5 text-[12px] top-[-37px] h-4  text-sm text-center w-[50px]"
                                            style={{ right: '0%' }}
                                        >
                                            {item.textTopRight}
                                        </div>
                                    )}
                                    {showTextBotRight && (
                                        <div
                                            className="absolute themes-text-gray-v5 text-[12px] bottom-[-37px] h-4 text-sm text-center flex w-[50px]"
                                            style={{ right: '0%' }}
                                        >
                                            {item.textBotRight}
                                        </div>
                                    )}
                                    {showTextTopLeft && (
                                        <div
                                            className="absolute themes-text-gray-v5 text-[12px] top-[-37px] h-4  text-sm text-center w-[50px]"
                                            style={{ left: '0%' }}
                                        >
                                            {item.textTopLeft}
                                        </div>
                                    )}
                                    {showTextBotLeft && (
                                        <div
                                            className="absolute themes-text-gray-v5 text-[12px] bottom-[-37px] h-4 text-sm text-center flex w-[50px]"
                                            style={{ left: '0%' }}
                                        >
                                            {item.textBotLeft}
                                        </div>
                                    )}
                                    {/* Text in the middle */}
                                    <div className="absolute top-1/2 transform -translate-y-1/2 left-1/2 -translate-x-1/2 text-sm text-white">
                                        {item.value >= 1 && item.value}
                                    </div>
                                    {/* Progress Bar */}
                                    <div className="absolute top-[-120%] bottom-[-120%] h-[350%] left-0 w-0.3 border-l-2 border-dashed themes-border-color" />
                                </div>
                            )
                        })}
                        {/* Add dashed line at the end */}
                        <div className="absolute top-[-180%] bottom-[-180%] h-[530%] right-0 w-0.3 border-l-2 border-dashed themes-border-color" />
                    </div>
                </div>
                <div className="flex justify-between">
                    {intervals.map((interval, index) => (
                        <div key={index}>{interval}</div>
                    ))}
                </div>
            </div>
        </ResponsiveContainer>
    )
}

export default ProgressBar
