/* eslint-disable no-unused-vars */
import Modal from 'components/modal/modal.component'
import { IPODetail } from '../purchase-order-detail.interface'
import { IUseModal } from 'components/modal/modal.service'
import Button from 'components/button/button.component'

const RequestDetachPOModal = ({
    modalService,
    data,
    isShipmentExists,
    onSubmit,
}: {
    data: IPODetail
    isShipmentExists: boolean
    modalService: IUseModal
    onSubmit: () => void
}) => {
    const poNo = data.poNo
    const label = isShipmentExists
        ? 'REQUEST DETACH PO NO ' + poNo
        : 'REQUEST CHANGE PO STATUS NO ' + poNo
    const btnLabel = isShipmentExists
        ? 'YES, DETACH'
        : 'YES, REQUEST TO CHANGE STATUS'
    const desc = isShipmentExists
        ? 'This will remove all shipments from this Purchase Order and a confirmation email will be sent to JPL. Are you sure you want to proceed?'
        : 'This will change this PO status from “In Progress” to “Open”. Are you sure you want to proceed?'

    return (
        //for JPL
        <Modal
            isModalOpen={modalService.isModalOpen}
            className="lg:w-1/3 md:w-1/2 mobile:w-1/2"
        >
            <div className="flex flex-col gap-4 justify-content">
                <div className="font-bold text-size-L">{label}</div>
                <div className="border-b border-gray-40"></div>
                <div className="themes-text-gray-v3 whitespace-pre-wrap">
                    {desc}
                </div>
                <div className="border-b border-gray-40"></div>
                <div className="flex justify-content gap-4">
                    <div className="w-full">
                        <Button
                            label="CANCEL"
                            variant="default"
                            onClick={() => {
                                modalService.closeModalHandling()
                            }}
                            className="w-full"
                        />
                    </div>
                    <div className="w-full">
                        <Button
                            label={btnLabel}
                            variant="brand"
                            onClick={() => {
                                modalService.closeModalHandling()
                                onSubmit()
                            }}
                            className="w-full"
                        />
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default RequestDetachPOModal
