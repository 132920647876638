import { ITableCardRow } from '../table-card.interface'

export function TableCardRow({
    ...props
}: ITableCardRow) {
    const IconRow = props.data.typeTransport === 'SEA' ? ' ri-ship-line' : props.data.typeTransport === 'AIR' ? 'ri-plane-line' : 'ri-truck-line'
    const total = props.data.mediaTransit.reduce((sum: number, item: any,) => sum + item.value, 0);

    return (
        <>
            <div className='grid grid-cols-12 justify-between  mb-[10px] px-[16px] py-[10px] border-[1px] rounded-md'>
                <div className='col-span-2 flex'>
                    <div className='flex item-center'>
                        <i className={`${IconRow} text-[28px] themes-text-brand-v5`} />
                        <div className='ml-5'>
                            <div className='text-[12px] themes-text-gray-v5'>Departure Port</div>
                            <div className='text-[16px] font-bold'> {props.data.description}</div>
                        </div>
                    </div>
                </div>
                <div className='col-span-2 flex'>
                    <div className='flex item-center flex-col'>
                        <div className='text-[12px] themes-text-gray-v5'>Arival Port</div>
                        <div className='text-[16px] font-bold'> {props.data.arivalPort}</div>
                    </div>
                </div>
                <div className='col-span-8 flex '>
                    <div className='flex item-center flex-col'>
                        <div className='text-[12px] themes-text-gray-v5'>Total Shipment</div>
                        <div className='text-[16px] font-bold'> {props.data.totalShipment}</div>
                    </div>
                    <div className='flex-grow flex flex-col item-start h-full'>
                        <div className="flex-grow mx-5">
                            <div className='text-[12px] themes-text-gray-v5'>Median Transit Time</div>
                            <div className="relative bg-gray-300 h-4 flex rounded-none">
                                {props.data.mediaTransit.map((item: any, index: number) => {
                                    const percentage = (item.value / total) * 100;

                                    return (
                                        <div
                                            key={index}
                                            className={`relative h-full themes-bg-${item.color}`}
                                            style={{ width: `${percentage}%` }}
                                            title={`Item ${index + 1}: ${percentage.toFixed(2)}%`}
                                        >
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                    <div className='flex item-center flex-col'>
                        <div className='text-[12px] themes-text-gray-v5'>Days</div>
                        <div className='text-[16px] font-bold'> {total}</div>
                    </div>
                </div>
            </div>
        </>
    )
}
