/* eslint-disable no-unused-vars */
import { ErrorMessage, useField } from 'formik'
import MeasurementInput from 'components/measurement-input/measurement-input.component'
import { IFormMeasurementInput } from './form-measurement-input.interface'
import { IMeasurement } from 'components/measurement-input/measurement-input.interface'

function FormMeasurementInput({
    options = [],
    parentDivClassName = '',
    showErrorMessage = true,
    ...props
}: IFormMeasurementInput) {
    //formik
    const [field, meta, helpers] = useField(props.name)

    const measurement: IMeasurement = {
        qty: field?.value?.qty,
        unit: field?.value?.unit,
    }
    const borderColorClass =
        meta.error && meta.touched ? 'themes-border-red' : ''

    const isError = meta.error && meta.touched
    const errorClassMessage = isError ? '' : 'hidden'

    return (
        <div className={`${parentDivClassName}`}>
            <MeasurementInput
                options={options}
                measurement={measurement}
                setMeasurement={(change) => {
                    console.log('change', change)

                    helpers.setValue(change)
                }}
                borderError={borderColorClass}
                {...props}
            />
            {showErrorMessage && (
                <div
                    className={`ml-small-x text-size-XS text-red-500 ${errorClassMessage}`}
                >
                    <ErrorMessage name={props.name} component="p">
                        {(msg) => <p>{msg}</p>}
                    </ErrorMessage>
                </div>
            )}
        </div>
    )
}

export default FormMeasurementInput
