/* eslint-disable no-unused-vars */
import Button from 'components/button/button.component'
import SimpleTable from 'components/simple-table/simple-table.component'
import { IBookingPackLines } from '../../booking-details/booking-details.interface'
import { packLineHeaders } from '../../booking-details/booking-details.static'
import { metaDummy } from '../../bookings.dummy'
import FormInput from 'components/form-input/form-input.component'
import FormDropdown from 'components/form-dropdown/form-dropdown.component'
import FormMeasurementInput from 'components/form-measurement-input/form-measurement-input.component'
import { FormikContextType, FormikProvider } from 'formik'
import {
    ICreateBookingPackLinesValidation,
    createBookingPackLinesInitialValue,
} from 'form-validation/ifb/booking-details.validation'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

const PackLines = ({
    packLinesData,
    formik,
    rowOnClick,
    setPackLineParams,
}: {
    packLinesData: IBookingPackLines[]
    formik: FormikContextType<ICreateBookingPackLinesValidation>
    rowOnClick: (data: IBookingPackLines) => void
    setPackLineParams: (data: 'update' | 'add' | 'duplicate' | 'delete') => void
}) => {
    const [isNewForm, setIsNewForm] = useState<boolean>(true)
    const { t } = useTranslation()
    return (
        <div className="h-full grid grid-cols-12">
            <div className="col-span-9">
                <SimpleTable<IBookingPackLines>
                    headers={packLineHeaders}
                    data={packLinesData || []}
                    usePagination
                    nextHandling={(page) => {
                        console.log('hit Next API -> ' + page)
                    }}
                    previousHandling={(page) => {
                        console.log('hit Prev API -> ' + page)
                    }}
                    meta={metaDummy}
                    onRowClick={(data) => {
                        setIsNewForm(false)
                        rowOnClick(data)
                    }}
                />
            </div>
            <FormikProvider value={formik}>
                <div className="col-span-3 border-y-2 flex flex-col justify-between">
                    <div>
                        <div className="flex themes-text-gray-v5 justify-between items-center p-3 text-[16px] border-b-2">
                            {!isNewForm ? (
                                <>
                                    <span>{t('bookings.selected_pack_line')}</span>
                                    <div className="text-[24px] flex">
                                        <div className="border-x-2 px-2">
                                            <i
                                                className="ri-file-copy-line themes-text-hover-green-v3 cursor-pointer"
                                                onClick={() => {
                                                    setPackLineParams(
                                                        'duplicate',
                                                    )
                                                    formik.submitForm()
                                                }}
                                            />
                                        </div>
                                        <div className="pl-2">
                                            <i
                                                className="ri-delete-bin-line themes-text-hover-red-v3 cursor-pointer"
                                                onClick={() => {
                                                    setPackLineParams('delete')
                                                    formik.submitForm()
                                                    setIsNewForm(true)
                                                }}
                                            />
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <>{t('bookings.add_new_pack_line')}</>
                            )}
                        </div>
                        <div className="p-3 grid grid-cols-2 gap-3">
                            <FormInput
                                label={t('bookings.packs')}
                                name={`packs`}
                                placeholder={t('action.enter', {
                                    value: t('bookings.packs'),
                                })}
                                parentDivClassName="!mb-0"
                            />
                            <FormDropdown
                                label={t('bookings.package_type')}
                                name={`packageType`}
                                placeholder={t('action.select', {
                                    value: t('bookings.package_type'),
                                })}
                                parentDivClassName="!mb-0"
                            />
                            <FormMeasurementInput
                                options={[{ label: 'Meters', value: 'M' }]}
                                name={t('bookings.length_measure')}
                                label="Length Measure"
                            />
                            <FormMeasurementInput
                                options={[{ label: 'Meters', value: 'M' }]}
                                name={'widthMeasure'}
                                label={t('bookings.width_measure')}
                            />
                            <FormMeasurementInput
                                options={[{ label: 'Meters', value: 'M' }]}
                                name={t('bookings.height_measure')}
                                label="Height Measure"
                            />
                            <FormMeasurementInput
                                options={[{ label: 'Kilogram', value: 'KG' }]}
                                name={t('bookings.weight_measure')}
                                label="Weight Measure"
                            />
                            <FormMeasurementInput
                                options={[
                                    { label: 'Cubic Meters', value: 'M3' },
                                ]}
                                name={'volumeMeasure'}
                                label={t('bookings.volume_measure')}
                            />
                            <FormInput
                                label={t('bookings.goods_description')}
                                name={`goodsDescription`}
                                placeholder={t('action.enter', {
                                    value: t('bookings.goods_description'),
                                })}
                                parentDivClassName="!mb-0 col-span-2"
                            />
                        </div>
                    </div>
                    <div className="flex flex-col gap-3 p-3">
                        <Button
                            onClick={() => {
                                if (isNewForm) {
                                    setPackLineParams('add')
                                } else {
                                    setPackLineParams('update')
                                }
                                formik.submitForm()
                            }}
                            label={
                                isNewForm
                                    ? t('bookings.add_new_pack_line')
                                    : t('bookings.update_selected_pack_line')
                            }
                            variant="brand"
                            isDisabled={isNewForm ? false : !formik.dirty}
                        />
                        <Button
                            onClick={() => {
                                rowOnClick(createBookingPackLinesInitialValue)
                                formik.resetForm()
                                setIsNewForm(true)
                            }}
                            label={t('action.discard_changes')}
                            variant="red-inverse"
                            isDisabled={!isNewForm ? false : !formik.dirty}
                        />
                    </div>
                </div>
            </FormikProvider>
        </div>
    )
}

export default PackLines
