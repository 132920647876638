/* eslint-disable no-unused-vars */
import { ITabItem } from 'components/tab/tab.interface'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { tabDetailHeaders } from './shipment-details.static'
import Details from './contents/details'
import PackLines from './contents/packLines'
import Orders from './contents/orders'
import RelatedInvoices from './contents/related-invoices'
import Milestones from 'pages/ifb/bookings/booking-details/contents/milestones.components'
import Events from 'pages/ifb/bookings/booking-details/contents/events.components'
import Tracking from 'pages/ifb/bookings/booking-details/contents/tracking.component'
import Notes from 'pages/ifb/bookings/booking-details/contents/notes.component'
import Messages from 'pages/ifb/bookings/booking-details/contents/messages.component'
import EDocs from 'pages/ifb/bookings/booking-details/contents/e-docs.component'
import Documents from 'pages/ifb/bookings/booking-details/contents/documents.component'
import { useAppDispatch } from 'store'
import { useSelector } from 'react-redux'
import {
    setShipmentDetailsData,
    shipmentDetailsDataSelector,
} from '../shipments.slice'
import { Toast } from 'components/toast/toast.component'
import { getShipmentDetails } from 'repository/ifb/shipment.repository'

const useShipmentDetails = () => {
    const { id } = useParams()
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    // selectors
    const shipmentDetailsData = useSelector(shipmentDetailsDataSelector)

    // states
    const [tabFilter, setTabFilter] = useState<ITabItem>(tabDetailHeaders[0])
    const [loadingDetailData, setLoadingDetailData] = useState(true)

    // get data detail
    const loadDetailData = async () => {
        if (!id || id === '') {
            return
        }

        try {
            setLoadingDetailData(true)
            const actionResult = await getShipmentDetails(id)
            dispatch(setShipmentDetailsData(actionResult))
            setLoadingDetailData(false)
        } catch (e: any) {
            console.log(e)
            setLoadingDetailData(false)
            const errorMessage = e.message
            Toast({
                header: 'Failed Get Detail Shipments',
                message: errorMessage,
                type: 'error',
            })
            setTimeout(() => {
                navigate('/shipments')
            }, 100)
        }
    }

    // functions
    const getContent = () => {
        const contents: Record<string, JSX.Element> = {
            details: (
                <Details
                    data={shipmentDetailsData}
                    laoding={loadingDetailData}
                />
            ),
            packLines: <PackLines />,
            orders: <Orders />,
            relatedInvoices: <RelatedInvoices />,
            documents: <Documents />,
            eDocs: <EDocs />,
            messages: <Messages />,
            notes: <Notes />,
            milestones: <Milestones />,
            events: <Events />,
            tracking: <Tracking />,
        }
        return contents[tabFilter.value]
    }

    // Use Effect ----------------------------------------------
    useEffect(() => {
        if (!id) return
        loadDetailData()
    }, [id])

    return {
        navigate,
        getContent,
        setTabFilter,
        shipmentDetailsData,
        tabFilter,
    }
}

export default useShipmentDetails
