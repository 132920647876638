/* eslint-disable no-unused-vars */
import { useState } from "react";

interface IToggle {
    lableEnable: string
    lableDisable: string
    classnNames?: string

}

const Toggle = (props: IToggle) => {
    const [isEnable, setIsEnable] = useState<boolean>(true);

    const classActive = 'themes-bg-brand-v2 themes-text-brand-v4'
    const classNotActive = 'themes-text-gray-v4'
    const enableClassname = isEnable ? classActive : classNotActive
    const disableClassname = isEnable ? classNotActive : classActive

    return (
        <div className={`${props?.classnNames || ''} flex p-1 border items-center rounded justify-evenly text-size-S`}>
            <div className={`flex justify-center w-1/2 py-1 rounded cursor-pointer ${enableClassname}`} onClick={() => setIsEnable(true)}>{props.lableEnable}</div>
            <div className={`flex justify-center w-1/2 py-1 rounded cursor-pointer ${disableClassname}`} onClick={() => setIsEnable(false)}>{props.lableDisable}</div>
        </div>
    );
}

export default Toggle