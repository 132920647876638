import { AreaChart, ResponsiveContainer, Area } from 'recharts';

function LineCharts({ data, className }: any) {
  return (
    <div className={className}>
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart width={730} height={250} data={data}
          margin={{
            top: 20,
            right: 0,
            left: 0,
            bottom: 5
          }}>
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#D9D9D9" stopOpacity={0.5} />
            </linearGradient>
          </defs>
          <Area type="monotone" dataKey="total" stroke="#8D99A5" fillOpacity={1} fill="url(#colorUv)" />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  )
}

export default LineCharts