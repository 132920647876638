import SimpleTable from 'components/simple-table/simple-table.component'
import { DocumentsHeader } from '../create-booking.static'
import useCreateBooking from '../create-booking.service'

const Documents = ({ actionHandling }: { actionHandling: () => JSX.Element }) => {
    const { addEDocModalComponent, addEDocModalService } = useCreateBooking()
    return (
        <div className="flex flex-col w-full p-4 gap-4 h-[calc(100vh-280px)] overflow-y-auto">
            <div className="flex w-full gap-4">
                <div className="flex w-full">
                    <span className="text-size-L font-bold">eDocs</span>
                </div>
            </div>
            <div className="flex w-full gap-4">
                <SimpleTable
                    headers={DocumentsHeader}
                    data={[]}
                    customBuildParam2={actionHandling}
                    isAddRow={true}
                    addRowLabel="Add new Document"
                    addRowClick={() => {
                        addEDocModalService.openModalHandling()
                    }}
                />
                {addEDocModalComponent}
            </div>
        </div>
    )
}

export default Documents
