import Button from 'components/button/button.component'
import { useTranslation } from 'react-i18next'
import { NavigateFunction } from 'react-router-dom'

const BookBy = ({
    navigate,
    onClick,
}: {
    navigate: NavigateFunction
    onClick: () => void
}) => {
    const { t } = useTranslation()
    return (
        <div>
            <div className="p-3 h-[calc(100vh-10.7rem)] overflow-auto flex flex-col gap-3">
                <div>{t('bookings.book_by_packing_type')}</div>
                <div className="grid grid-cols-3 gap-3">
                    <div
                        className="cursor-pointer border rounded leading-tight themes-border-brand-v4 flex flex-col items-center py-16 themes-text-brand-v4"
                        onClick={onClick}
                    >
                        <i className="ri-pages-line text-[51px]"></i>
                        <div className="text-size-L">{t('bookings.loose')}</div>
                    </div>
                    <div
                        className="cursor-pointer border rounded leading-tight themes-border-brand-v4 flex flex-col items-center py-16 themes-text-brand-v4"
                        onClick={onClick}
                    >
                        <i className="ri-archive-2-fill text-[51px]"></i>
                        <div className="text-size-L">{t('bookings.full_container_load')}</div>
                    </div>
                    <div
                        className="cursor-pointer border rounded leading-tight themes-border-brand-v4 flex flex-col items-center py-16 themes-text-brand-v4"
                        onClick={onClick}
                    >
                        <i className="ri-luggage-cart-fill text-[51px]"></i>
                        <div className="text-size-L">{t('bookings.full_truck_load')}</div>
                    </div>
                </div>
                <div>{t('bookings.book_by_mode')}</div>
                <div className="grid grid-cols-5 gap-3">
                    <div
                        className="cursor-pointer border rounded leading-tight themes-border-brand-v4 flex flex-col items-center py-16 themes-text-brand-v4"
                        onClick={onClick}
                    >
                        <i className="ri-flight-takeoff-fill text-[51px]"></i>
                        <div className="text-size-L">{t('bookings.air')}</div>
                    </div>
                    <div
                        className="cursor-pointer border rounded leading-tight themes-border-brand-v4 flex flex-col items-center py-16 themes-text-brand-v4"
                        onClick={onClick}
                    >
                        <i className="ri-ship-2-fill text-[51px]"></i>
                        <div className="text-size-L">{t('bookings.sea')}</div>
                    </div>
                    <div
                        className="cursor-pointer border rounded leading-tight themes-border-brand-v4 flex flex-col items-center py-16 themes-text-brand-v4"
                        onClick={onClick}
                    >
                        <i className="ri-truck-fill text-[51px]"></i>
                        <div className="text-size-L">{t('bookings.road')}</div>
                    </div>
                    <div
                        className="cursor-pointer border rounded leading-tight themes-border-brand-v4 flex flex-col items-center py-16 themes-text-brand-v4"
                        onClick={onClick}
                    >
                        <i className="ri-caravan-fill text-[51px]"></i>
                        <div className="text-size-L">{t('bookings.rail')}</div>
                    </div>
                    <div
                        className="cursor-pointer border rounded leading-tight themes-border-brand-v4 flex flex-col items-center py-16 themes-text-brand-v4"
                        onClick={onClick}
                    >
                        <i className="ri-e-bike-2-fill text-[51px]"></i>
                        <div className="text-size-L">{t('bookings.courier')}</div>
                    </div>
                </div>
            </div>
            <div className="border-b"></div>
            <div className="p-3">
                <Button
                    onClick={() => navigate(-1)}
                    label={t('action.back')}
                    className="w-btnRegularWidth"
                />
            </div>
        </div>
    )
}

export default BookBy
