import { IMeta } from 'common/common.interface'
import { IBookings } from './bookings.interface'

export const BookingDataDummy: IBookings[] = [
    {
        shipmentID: 'S00001041',
        transportMode: 'Airplane',
        goodsDescription: 'Doodad Pro',
        shippersReference: 'SR#ZX872W',
        origin: 'AUMEL - Melbourne, VIC, Australia',
        destination: 'JPTYO - Tokyo, 13, Japan',
        packs: '154',
        weightMeasure: '10.5 KG',
        volumeMeasure: '2.5 M3',
        masterBill: 'MBN123456',
        loadPort: 'Port of Los Angeles',
        status: 'confirmed',
    },
    {
        shipmentID: 'S00001042',
        transportMode: 'Airplane',
        goodsDescription: 'Gismo Ultra',
        shippersReference: 'REF#AB123C',
        origin: 'JPYOK - Yokohama, 14, Japan',
        destination: 'USLAX - Los Angeles, CA, USA',
        packs: '154',
        weightMeasure: '10.5 KG',
        volumeMeasure: '2.5 M3',
        masterBill: 'MBN123456',
        loadPort: 'Port of Los Angeles',
        status: 'booked',
    },
    {
        shipmentID: 'S00001043',
        transportMode: 'Airplane',
        goodsDescription: 'Gismo Ultra',
        shippersReference: 'SHPREF#XY98',
        origin: 'AUMEL - Melbourne, VIC, Australia',
        destination: 'JPTYO - Tokyo, 13, Japan',
        packs: '154',
        weightMeasure: '10.5 KG',
        volumeMeasure: '2.5 M3',
        masterBill: 'MBN123456',
        loadPort: 'Port of Los Angeles',
        status: 'waitListed',
    },
    {
        shipmentID: 'S00001044',
        goodsDescription: 'Gismo Ultra',
        transportMode: 'Ship',
        shippersReference: 'SHIP-X789Z ',
        origin: 'JPYOK - Yokohama, 14, Japan',
        destination: 'USLAX - Los Angeles, CA, USA',
        packs: '154',
        weightMeasure: '10.5 KG',
        volumeMeasure: '2.5 M3',
        masterBill: 'MBN123456',
        loadPort: 'Port of Los Angeles',
        status: 'confirmed',
    },
    {
        shipmentID: 'S00001045',
        transportMode: 'Ship',
        goodsDescription: 'Whatchamacallit Mini',
        shippersReference: 'SHPREF#KL55A',
        origin: 'AUMEL - Melbourne, VIC, Australia',
        destination: 'JPTYO - Tokyo, 13, Japan',
        packs: '154',
        weightMeasure: '10.5 KG',
        volumeMeasure: '2.5 M3',
        masterBill: 'MBN123456',
        loadPort: 'Port of Los Angeles',
        status: 'booked',
    },
    {
        shipmentID: 'S00001046',
        transportMode: 'Ship',
        goodsDescription: 'Whatchamacallit Mini',
        shippersReference: 'REF-BC672P SHIPREF#JP943',
        origin: 'JPYOK - Yokohama, 14, Japan',
        destination: 'USLAX - Los Angeles, CA, USA',
        packs: '154',
        weightMeasure: '10.5 KG',
        volumeMeasure: '2.5 M3',
        masterBill: 'MBN123456',
        loadPort: 'Port of Los Angeles',
        status: 'waitListed',
    },
    {
        shipmentID: 'S00001047',
        transportMode: 'Truck',
        goodsDescription: 'Thingamajig Deluxe',
        shippersReference: 'SHPREF-QR67D',
        origin: 'AUMEL - Melbourne, VIC, Australia',
        destination: 'JPTYO - Tokyo, 13, Japan',
        packs: '154',
        weightMeasure: '10.5 KG',
        volumeMeasure: '2.5 M3',
        masterBill: 'MBN123456',
        loadPort: 'Port of Los Angeles',
        status: 'confirmed',
    },
    {
        shipmentID: 'S00001048',
        transportMode: 'Truck',
        goodsDescription: 'Thingamajig Deluxe',
        shippersReference: 'SR#ZZ33A',
        origin: 'JPYOK - Yokohama, 14, Japan',
        destination: 'USLAX - Los Angeles, CA, USA',
        packs: '154',
        weightMeasure: '10.5 KG',
        volumeMeasure: '2.5 M3',
        masterBill: 'MBN123456',
        loadPort: 'Port of Los Angeles',
        status: 'booked',
    },
    {
        shipmentID: 'S00001049',
        transportMode: 'Truck',
        goodsDescription: 'Gismo Ultra',
        shippersReference: 'REF#DE987R',
        origin: 'AUMEL - Melbourne, VIC, Australia',
        destination: 'JPTYO - Tokyo, 13, Japan',
        packs: '154',
        weightMeasure: '10.5 KG',
        volumeMeasure: '2.5 M3',
        masterBill: 'MBN123456',
        loadPort: 'Port of Los Angeles',
        status: 'waitListed',
    },
    {
        shipmentID: 'S00001050',
        transportMode: 'Airplane',
        goodsDescription: 'Gismo Ultra',
        shippersReference: 'SHIPREF-ML22K',
        origin: 'JPYOK - Yokohama, 14, Japan',
        destination: 'USLAX - Los Angeles, CA, USA',
        packs: '154',
        weightMeasure: '10.5 KG',
        volumeMeasure: '2.5 M3',
        masterBill: 'MBN123456',
        loadPort: 'Port of Los Angeles',
        status: 'confirmed',
    },
    {
        shipmentID: 'S00001051',
        transportMode: 'Airplane',
        goodsDescription: 'Doodad Pro',
        shippersReference: 'SHPREF#NO876',
        origin: 'AUMEL - Melbourne, VIC, Australia',
        destination: 'JPTYO - Tokyo, 13, Japan',
        packs: '154',
        weightMeasure: '10.5 KG',
        volumeMeasure: '2.5 M3',
        masterBill: 'MBN123456',
        loadPort: 'Port of Los Angeles',
        status: 'booked',
    },
    {
        shipmentID: 'S00001052',
        transportMode: 'Ship',
        goodsDescription: 'Gadget X',
        shippersReference: 'REF$XY223A',
        origin: 'JPYOK - Yokohama, 14, Japan',
        destination: 'USLAX - Los Angeles, CA, USA',
        packs: '154',
        weightMeasure: '10.5 KG',
        volumeMeasure: '2.5 M3',
        masterBill: 'MBN123456',
        loadPort: 'Port of Los Angeles',
        status: 'waitListed',
    },
    {
        shipmentID: 'S00001053',
        transportMode: 'Ship',
        goodsDescription: 'Gadget X',
        shippersReference: 'REF#KJ9232',
        origin: 'AUMEL - Melbourne, VIC, Australia',
        destination: 'JPTYO - Tokyo, 13, Japan',
        packs: '154',
        weightMeasure: '10.5 KG',
        volumeMeasure: '2.5 M3',
        masterBill: 'MBN123456',
        loadPort: 'Port of Los Angeles',
        status: 'confirmed',
    },
    {
        shipmentID: 'S00001054',
        transportMode: 'Truck',
        goodsDescription: 'Gadget X',
        shippersReference: 'REF$GG4502',
        origin: 'JPYOK - Yokohama, 14, Japan',
        destination: 'USLAX - Los Angeles, CA, USA',
        packs: '154',
        weightMeasure: '10.5 KG',
        volumeMeasure: '2.5 M3',
        masterBill: 'MBN123456',
        loadPort: 'Port of Los Angeles',
        status: 'booked',
    },
    {
        shipmentID: 'S00001055',
        transportMode: 'Truck',
        goodsDescription: 'Doodad Pro',
        shippersReference: 'REF%100SGS',
        origin: 'AUMEL - Melbourne, VIC, Australia',
        destination: 'JPTYO - Tokyo, 13, Japan',
        packs: '154',
        weightMeasure: '10.5 KG',
        volumeMeasure: '2.5 M3',
        masterBill: 'MBN123456',
        loadPort: 'Port of Los Angeles',
        status: 'waitListed',
    },
]

export const metaDummy: IMeta = {
    current_page: 1,
    last_page: 1,
    from: 1,
    to: 50,
    total_Items: 15,
    per_page: 0,
    total_page: 1,
    index_end: 15,
    index_start: 1,
}
