import { ISTColumn } from 'components/simple-table/simple-table.interface'
import { ITabItem } from 'components/tab/tab.interface'
import {
    IBookingEDocs,
    IBookingEvent,
    IBookingMilestone,
    IBookingNote,
    IBookingPackLines,
    IBookingRelatedOrder,
} from './booking-details.interface'

// segment Details
export const tabDetailHeaders: ITabItem[] = [
    {
        label: 'Details',
        labelTranslationCode: 'tab_details.details',
        key: 'status',
        value: 'details',
        className: 'brand',
    },
    {
        label: 'Pack Lines',
        labelTranslationCode: 'tab_details.pack_lines',
        key: 'status',
        value: 'packLines',
        className: 'brand',
    },
    {
        label: 'Documents',
        labelTranslationCode: 'tab_details.documents',
        key: 'status',
        value: 'documents',
        className: 'brand',
    },
    {
        label: 'eDocs',
        labelTranslationCode: 'tab_details.edocs',
        key: 'status',
        value: 'eDocs',
        className: 'brand',
    },
    {
        label: 'Messages',
        labelTranslationCode: 'tab_details.messages',
        key: 'status',
        value: 'messages',
        className: 'brand',
    },
    {
        label: 'Notes',
        labelTranslationCode: 'tab_details.notes',
        key: 'status',
        value: 'notes',
        className: 'brand',
    },
    {
        label: 'Milestones',
        labelTranslationCode: 'tab_details.milestones',
        key: 'status',
        value: 'milestones',
        className: 'brand',
    },
    {
        label: 'Events',
        labelTranslationCode: 'tab_details.events',
        key: 'status',
        value: 'events',
        className: 'brand',
    },
    {
        label: 'Tracking',
        labelTranslationCode: 'tab_details.tracking',
        key: 'status',
        value: 'tracking',
        className: 'brand',
    },
    {
        label: 'Related Orders',
        labelTranslationCode: 'tab_details.related_orders',
        key: 'status',
        value: 'relatedOrders',
        className: 'brand',
    },
]

export const tabConsigneeOrConsignorHeaders: ITabItem[] = [
    {
        label: 'Pickup Address',
        labelTranslationCode: 'bookings.pickup_address',
        key: 'status',
        value: 'pickupAddress',
        className: 'brand',
    },
    {
        label: 'Documentary Address',
        labelTranslationCode: 'bookings.documentary_address',
        key: 'status',
        value: 'documentaryAddress',
        className: 'brand',
    },
]

export const packLineHeaders: ISTColumn<IBookingPackLines>[] = [
    {
        label: 'Packs',
        labelTranslation: 'bookings.packs',
        accessor: 'packs',
    },
    {
        label: 'Weight Measure',
        labelTranslation: 'bookings.weight_measure',
        accessor: 'weightMeasure',
        customBuild: (data, x, y, z, rowData) => {
            if (rowData) {
                const { qty, unit } = rowData.weightMeasure
                return (
                    <>
                        {qty} {unit}
                    </>
                )
            } else {
                return <></>
            }
        },
    },
    {
        label: 'Goods Description',
        labelTranslation: 'bookings.goods_description',
        accessor: 'goodsDescription',
    },
    {
        label: 'Length Measure',
        labelTranslation: 'bookings.length_measure',
        accessor: 'lengthMeasure',
        customBuild: (data, x, y, z, rowData) => {
            if (rowData) {
                const { qty, unit } = rowData.lengthMeasure
                return (
                    <>
                        {qty} {unit}
                    </>
                )
            } else {
                return <></>
            }
        },
    },
    {
        label: 'Width Measure',
        labelTranslation: 'bookings.width_measure',
        accessor: 'widthMeasure',
        customBuild: (data, x, y, z, rowData) => {
            if (rowData) {
                const { qty, unit } = rowData.widthMeasure
                return (
                    <>
                        {qty} {unit}
                    </>
                )
            } else {
                return <></>
            }
        },
    },
    {
        label: 'Height Measure',
        labelTranslation: 'bookings.height_measure',
        accessor: 'heightMeasure',
        customBuild: (data, x, y, z, rowData) => {
            if (rowData) {
                const { qty, unit } = rowData.heightMeasure
                return (
                    <>
                        {qty} {unit}
                    </>
                )
            } else {
                return <></>
            }
        },
    },
    {
        label: 'Volume Measure',
        labelTranslation: 'bookings.volume_measure',
        accessor: 'volumeMeasure',
        customBuild: (data, x, y, z, rowData) => {
            if (rowData) {
                const { qty, unit } = rowData.volumeMeasure
                return (
                    <>
                        {qty} {unit}
                    </>
                )
            } else {
                return <></>
            }
        },
    },
]

export const bookingEDocsHeaders: ISTColumn<IBookingEDocs>[] = [
    {
        label: 'File Name',
        labelTranslation: 'bookings.fileName',
        accessor: 'fileName',
    },
    {
        label: 'Description',
        labelTranslation: 'bookings.description',
        accessor: 'description',
    },
    {
        label: 'Added',
        labelTranslation: 'bookings.added',
        accessor: 'added',
    },
]

export const bookingNoteHeaders: ISTColumn<IBookingNote>[] = [
    {
        accessor: 'noteText',
        label: 'Note Text',
        labelTranslation: 'bookings.note_text',
    },
    {
        accessor: 'description',
        label: 'Description',
        labelTranslation: 'bookings.description',
    },
]

export const bookingMilestoneHeaders: ISTColumn<IBookingMilestone>[] = [
    {
        label: 'Description',
        accessor: 'description',
        labelTranslation: 'bookings.description',
    },
    {
        label: 'Milestone Event',
        accessor: 'milestoneEvent',
        labelTranslation: 'bookings.milestone_event',
    },
    {
        label: 'Estimated',
        accessor: 'estimated',
        labelTranslation: 'bookings.estimated',
    },
    {
        label: 'Actual Start',
        accessor: 'actualStart',
        labelTranslation: 'bookings.actual_start',
    },
]

export const bookingEventHeaders: ISTColumn<IBookingEvent>[] = [
    {
        label: 'Event Time',
        accessor: 'eventTime',
        labelTranslation: 'bookings.event_time',
    },
    {
        label: 'Event',
        accessor: 'event',
        labelTranslation: 'bookings.event',
    },
    {
        label: 'Reference',
        accessor: 'reference',
        labelTranslation: 'bookings.reference',
    },
]

export const bookingRelatedOrderHeaders: ISTColumn<IBookingRelatedOrder>[] = [
    {
        label: 'Order No.',
        accessor: 'orderNo',
        labelTranslation: 'bookings.order_no',
    },
    {
        label: 'Order Date',
        accessor: 'orderDate',
        labelTranslation: 'bookings.order_date',
    },
    {
        label: 'Booking Confirmation Ref',
        labelTranslation: 'bookings.booking_confirmation_ref',
        accessor: 'bookingConfirmationRef',
    },
    {
        label: 'Booking Confirmation Date',
        labelTranslation: 'bookings.booking_confirmation_date',
        accessor: 'bookingConfirmationDate',
    },
    {
        label: 'Order Status',
        labelTranslation: 'bookings.order_status',
        accessor: 'orderStatus',
    },
    {
        label: 'Ex Works Required By',
        labelTranslation: 'bookings.ex_works_required_by',
        accessor: 'exWorksRequiredBy',
    },
    {
        label: 'Required in Store',
        labelTranslation: 'bookings.required_in_store',
        accessor: 'requiredInStore',
    },
    {
        label: 'Transport Mode',
        labelTranslation: 'bookings.transport_mode',
        accessor: 'transportMode',
    },
    {
        label: 'Container Mode',
        labelTranslation: 'bookings.container_mode',
        accessor: 'containerMode',
    },
    {
        label: 'Goods Description',
        labelTranslation: 'bookings.goods_description',
        accessor: 'goodsDescription',
    },
]
