import { IResponseData } from 'common/common.interface'
import {
    IShipment,
    IShipmentDetails,
    IShipmentFilter,
} from './interface/shipment.interface'
import { endpoints } from 'common/endpoints'
import { fetch } from 'common/common.service'

export const getShipmentsData = async (filter: IShipmentFilter) => {
    try {
        const response = await fetch<
            IResponseData<IShipment[]>,
            IShipmentFilter
        >({
            endpoint: endpoints.jpl.shipment_getAll,
            params: filter,
        })
        return response
    } catch (error: any) {
        console.error(error)
        const err = error as IResponseData<IShipment[]>
        err.isSuccess = false
        return err
    }
}

export const getShipmentDetails = async (shipmentId: string) => {
    try {
        const response = await fetch<IResponseData<IShipmentDetails>, string>({
            endpoint: `${endpoints.ifb.shipment_getAll}/${shipmentId}`,
        })
        return response
    } catch (error: any) {
        console.error(error)
        const err = error as IResponseData<IShipmentDetails>
        err.isSuccess = false
        return err
    }
}
