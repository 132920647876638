import { ISteps } from 'components/progression-step/progression-step.interface'
import { useRef, useState } from 'react'
import { items } from './create-booking.static'
import Documents from './content/documents.component'
import GoodsDetails from './content/goods-details.component'
import Address from './content/address.component'
import AddEDocumentModal from './components/add-e-document-modal.component'
import { useModal } from 'components/modal/modal.service'
import { Toast } from 'components/toast/toast.component'
import { IDropdownItem } from 'components/dropdown/dropdown.interface'
import Button from 'components/button/button.component'

const useCreateBooking = () => {
    const [stepFilter, setStepFilter] = useState<ISteps>({
        textIcon: '01',
        label: 'Address',
        value: 'address',
        status: 'unfinished',
    })

    const nextCompo = () => {
        const x = items.findIndex((item) => item.value === stepFilter.value)
        setStepFilter(items[x + 1])
        console.log('X: ', x)
    }

    const previousCompo = () => {
        const x = items.findIndex((item) => item.value === stepFilter.value)
        setStepFilter(items[x - 1])
        console.log('X: ', x)
    }

    const getContentCreateBooking = (step: ISteps) => {
        const items: any = {
            address: Address(),
            goodsDetails: GoodsDetails({
                actionHandling: () => {
                    {
                        return (
                            <div className="pr-4 flex items-center gap-x-2">
                                <Button
                                    onClick={() => { }}
                                    variant="red-inverse"
                                    icon="ri-delete-bin-line !p-0"
                                    className={`!p-0 !h-[1.8rem] !w-[55px]`}
                                />
                                <Button
                                    onClick={() => { }}
                                    variant="default"
                                    icon="ri-edit-2-line !p-0"
                                    className={`!p-0 !h-[1.8rem] !w-[55px]`}
                                />
                            </div>
                        )
                    }
                }
            }),
            documents: Documents({
                actionHandling: () => {
                    {
                        return (
                            <div className="pr-4 flex items-center gap-x-2">
                                <Button
                                    onClick={() => { }}
                                    variant="red-inverse"
                                    icon="ri-delete-bin-line !p-0"
                                    className={`!p-0 !h-[1.8rem] !w-[55px]`}
                                />
                                <Button
                                    onClick={() => { }}
                                    variant="default"
                                    icon="ri-edit-2-line !p-0"
                                    className={`!p-0 !h-[1.8rem] !w-[55px]`}
                                />
                            </div>
                        )
                    }
                }
            }),
        }

        return <>{items[step?.value]}</>
    }

    const addEDocModalService = useModal()
    const inputRef = useRef<HTMLInputElement>(null)

    const [selectedFile, setSelectedFile] = useState<File | null>(null)
    const [eDocsType, setEDocsType] = useState<IDropdownItem>()

    // handle  file on input
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0]
            if (validateFile(file)) {
                setSelectedFile(file)
            } else {
                // Reset input value to clear the selected file
                if (inputRef.current) {
                    inputRef.current.value = ''
                }
            }
        }
    }

    // validate file when upload file
    const validateFile = (file: File): boolean => {
        // Validasi jenis file
        let messageError
        const allowedExtensions = [
            '.png',
            '.jpg',
            '.jpeg',
            '.pdf',
            '.xls',
            '.xlsx',
        ]
        const fileExtension = file?.name?.split('.')?.pop()?.toLowerCase()

        if (!allowedExtensions.includes(`.${fileExtension}`)) {
            messageError = 'Only PNG, JPG, PDF, XLS and XLSX files are allowed.'
        }

        // Validasi ukuran file (dalam bytes)
        const maxSize = 3 * 1024 * 1024 // 3MB
        if (file.size > maxSize) {
            messageError = 'File size exceeds 3MB limit.'
        }

        if (messageError) {
            Toast({
                header: 'Error Selected File',
                message: messageError,
                type: 'error',
            })
            return false
        }

        return true
    }

    // handle browse/choose file for upload
    const onChooseFile = () => {
        if (inputRef.current) {
            inputRef.current.click()
        }
    }

    // handle drag upload file
    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault()
    }

    // handle drop upload file
    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault()
        if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
            const file = event.dataTransfer.files[0]
            if (validateFile(file)) {
                setSelectedFile(file)
            }
        }
    }

    const submitUploadFile = async () => {
        console.log('Unavailable upload file')
    }

    const addEDocModalComponent = (
        <AddEDocumentModal
            modalService={addEDocModalService}
            handleFileChange={handleFileChange}
            onChooseFile={onChooseFile}
            handleDragOver={handleDragOver}
            handleDrop={handleDrop}
            selectedFile={selectedFile}
            inputRef={inputRef}
            eDocsType={eDocsType}
            setEDocsType={setEDocsType}
            onSubmit={submitUploadFile}
        />
    )

    return {
        stepFilter,
        addEDocModalComponent,
        addEDocModalService,
        setStepFilter,
        getContentCreateBooking,
        nextCompo,
        previousCompo,
    }
}
export default useCreateBooking
