import Button from 'components/button/button.component'
import { ISimpleTable } from './simple-table.interface'
import './simple-table.style.css'
import SpinnerTable from 'components/spinner-table/spinner-table.component'
import EmptyResult from 'components/empty-result/empty-result.component'
import { useState } from 'react'
import Pagination from 'components/pagination/pagination.component'
import { useTranslation } from 'react-i18next'

function SimpleTable<T>({
    headers,
    data,
    customBuildParam2 = () => <></>,
    isAddRow = false,
    useNumber = false,
    variant = 'white',
    customBuildParam4 = () => ({
        func1: () => { },
    }),
    ...props
}: ISimpleTable<T>) {
    const { t } = useTranslation()
    const [active, setActive] = useState<number>(0)
    const cursorClass = props.onRowClick ? 'cursor-pointer' : ''
    const loadingMessage = `loading Data . . .`
    const notFoundMessage = `No data found`
    const headerLength = useNumber ? headers.length : headers?.length - 1
    const useTitleClass = props.title ? '' : 'rounded-t'
    const useFooterClass = props.footer ? '' : 'rounded-b'

    return (
        <>
            {props.title && (
                <div className="themes-bg-white border-t rounded-t border-x p-3">
                    {props.title}
                </div>
            )}
            <div
                className={`simple-table-container border h-full overflow-auto justify-between themes-text-gray-v5 variant-${variant} ${useTitleClass} ${useFooterClass}`}
            >
                {props.loading === true ? (
                    <div className="h-full">
                        <SpinnerTable
                            className="w-full"
                            message={loadingMessage}
                        />
                    </div>
                ) : data.length <= 0 && !isAddRow ? (
                    <div className="h-full">
                        <EmptyResult
                            className="w-full"
                            message={notFoundMessage}
                        />
                    </div>
                ) : (
                    <table className="simple-table table-auto">
                        <thead>
                            <tr>
                                {!useNumber ? <></> : <th>No.</th>}
                                {headers.map((column, idx) => {
                                    const label = column.labelTranslation
                                        ? t(column.labelTranslation)
                                        : column.label
                                    const header =
                                        column.showLabel === false ? (
                                            <></>
                                        ) : column.customHeader ? (
                                            column.customHeader()
                                        ) : (
                                            label
                                        )
                                    return (
                                        <th
                                            className="themes-bg-white z-[1]"
                                            key={idx}
                                        >
                                            <div className="row-container">
                                                {header}
                                            </div>
                                        </th>
                                    )
                                })}
                            </tr>
                        </thead>
                        <tbody className="overflow-auto" id="table-body">
                            {data.map((row: any, idx2) => {
                                const activeClass =
                                    props.onRowClick && active === idx2
                                        ? 'active'
                                        : ''
                                return (
                                    <tr
                                        key={idx2}
                                        className={`${cursorClass}`}
                                        onClick={() => {
                                            if (props.onRowClick) {
                                                props.onRowClick(row)
                                                setActive(idx2)
                                            }
                                        }}
                                    >
                                        {!useNumber ? (
                                            <></>
                                        ) : (
                                            <td>{idx2 + 1}</td>
                                        )}

                                        {headers.map((column, idx3) => {
                                            return (
                                                <td
                                                    key={`${idx3}-${column.accessor as string}`}
                                                    className={`${activeClass}`}
                                                >
                                                    <div
                                                        className={`row-container w-max ${column?.isComponent ? '' : 'max-w-[200px] overflow-hidden text-ellipsis'} flex items-center`}
                                                    >
                                                        {column.customBuild
                                                            ? column.customBuild(
                                                                row[
                                                                column
                                                                    .accessor
                                                                ],
                                                                customBuildParam2,
                                                                idx2,
                                                                customBuildParam4,
                                                                row,
                                                            )
                                                            : row[
                                                            column
                                                                .accessor
                                                            ] || '-'}
                                                    </div>
                                                </td>
                                            )
                                        })}
                                    </tr>
                                )
                            })}
                            {/* add row */}
                            {isAddRow && (
                                <tr>
                                    <td colSpan={headerLength} className="p-0 ">
                                        {props.addRowLabel
                                            ? props.addRowLabel
                                            : 'Add new row'}
                                    </td>
                                    <td className="pr-4 w-[65px]">
                                        <div className="w-full flex justify-center">
                                            <Button
                                                onClick={() => {
                                                    props?.addRowClick &&
                                                        props.addRowClick()
                                                }}
                                                icon="ri-add-fill"
                                                className={`!p-0 !w-[55px] !h-[1.8rem]`}
                                                variant="brand-inverse"
                                            />
                                        </div>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                )}
            </div>
            {props.footer && (
                <div className="themes-bg-white p-3 border-x border-b rounded-b">
                    {props.footer}
                </div>
            )}
            {props.usePagination && (
                <div className="border-t themes-border-color flex justify-between">
                    <Pagination
                        meta={props.meta}
                        previousHandling={(page) => {
                            props?.previousHandling &&
                                props.previousHandling(page)
                        }}
                        nextHandling={(page) => {
                            props?.nextHandling && props.nextHandling(page)
                        }}
                    />
                    <div className="m-4 flex gap-2">
                        {props?.additionalButtonBottom}
                    </div>
                </div>
            )}
        </>
    )
}

export default SimpleTable
