import React from 'react';
import './item-list-info.style.css'
import { useTranslation } from 'react-i18next';
import { TranslationCode } from '../../@types/translation-code';

interface Item {
    color: string;
    label: string;
    labelTranslation?: TranslationCode;
}

interface ItemListInfoProps {
    itemListInfo: Item[];
}

const ItemListInfo: React.FC<ItemListInfoProps> = ({ itemListInfo }) => {
    const { t } = useTranslation()
    return (
        <div className="flex">
            {itemListInfo.map((item, idx) => (
                <div key={`item-list-info-${idx}`} className="flex items-center ml-2 text-[12px]">
                    <div className={`w-2 h-2 mr-1 themes-bg-${item.color} rounded-full`} />
                    <div className='min-w-max text-title themes-text-gray-v6'>
                        {item.labelTranslation ? t(item.labelTranslation) : item.label}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default ItemListInfo;
