import Button from 'components/button/button.component'
import Overlay from 'components/overlay/overlay.component'
import { IUseOverlay } from 'components/overlay/overlay.service'
import '../shipments.style.css'

const ShipmentsFilterOverlay = ({
    filterOverlayService,
    componentFilter,
    resetFilter,
    applyFilter,
    removeTemporyFilter,
}: {
    filterOverlayService: IUseOverlay
    componentFilter: JSX.Element
    resetFilter: () => void
    applyFilter: () => void
    removeTemporyFilter: () => void
}) => {
    return (
        <Overlay
            isOverlayOpen={filterOverlayService.isOverlayOpen}
            className="filter flex h-[calc(100vh-14rem)] 2xl:h-[509px]"
        >
            <form
                className={`w-full flex flex-col`}
                onSubmit={(e) => {
                    e.preventDefault()
                    applyFilter()
                }}
            >
                <div className="flex font-bold text-size-L justify-between px-4 pt-4">
                    Filters
                    <Button
                        className="!w-4 !h-4 !border-0 mb-2"
                        icon="ri-close-fill"
                        onClick={() => {
                            filterOverlayService.closeOverlayHandling()

                            // remove temproray filter when close filter
                            removeTemporyFilter()
                        }}
                    />
                </div>
                <div className="border-b border-gray-40"></div>
                {componentFilter}
                <div className="border-b border-gray-40 mt-2"></div>
                <div className="w-full flex mt-2 gap-3 px-4 pb-4 pt-2">
                    <Button
                        onClick={() => {
                            resetFilter()
                        }}
                        label="RESET"
                        className="w-1/4"
                        variant="red-inverse"
                    />
                    <Button
                        onClick={() => { }}
                        type="submit"
                        label="APPLY FILTER"
                        className="w-3/4"
                        variant="brand"
                    />
                </div>
            </form>
        </Overlay>
    )
}

export default ShipmentsFilterOverlay
