import TabMaster from 'components/tab-master/tab-master.component'
import Table from 'components/table/table.component'
import useBookings from './bookings.service'
import { BookingStatusHeader } from './bookings.static'
import { IBookings } from './bookings.interface'
import { BookingDataDummy, metaDummy } from './bookings.dummy'
import BookingFilterOverlay from './components/booking-filter-overlay.component'
import BookingFilter from './components/booking-filter.component'
import { useTranslation } from 'react-i18next'

const Bookings = () => {
    const { t } = useTranslation()
    const {
        filterOverlayService,
        generateReportModalService,
        tabFilter,
        data,
        ConfigureColumnsModalService,
        allMNodal,
        loading,
        headers,
        navigate,
        setTabFilter,
    } = useBookings()

    return (
        <div className="container-global content-full-height flex">
            <div className="flex flex-grow flex-col w-[calc(100vw-20rem)] sm:w-[calc(100vw-4rem)]">
                <TabMaster
                    items={BookingStatusHeader}
                    tabFilter={tabFilter}
                    onChange={(item) => setTabFilter(item)}
                    //  search ----------------------------------------------------------------
                    useSearch={true}
                    placeHolderSearch={t('action.search', {
                        value: t('bookings.shipment_id'),
                    })}
                    onSearchSubmit={() => {}}
                    containerSearchClassName={'flex-1'}
                    //  button for overlay or something ----------------------------------------
                    iconButton1={{
                        onClick: () =>
                            filterOverlayService.toggleOverlayHandling(),
                        icon: 'ri-filter-2-line',
                        filterOverlayComponent: (
                            <BookingFilterOverlay
                                filterOverlayService={filterOverlayService}
                            />
                        ),
                    }}
                    iconButton2={{
                        onClick: () =>
                            ConfigureColumnsModalService.openModalHandling(),
                        icon: 'ri-settings-2-line',
                    }}
                    actionButton1={{
                        onClick: () => {
                            navigate('/create-booking')
                        },
                        label: t('menu.bookings_new').toUpperCase(),
                        className: '!text-size-S',
                    }}
                />
                <Table<IBookings>
                    headers={headers}
                    data={data}
                    loading={loading}
                    nextHandling={(page) => {
                        console.log('hit Next API -> ' + page)
                    }}
                    previousHandling={(page) => {
                        console.log('hit Prev API -> ' + page)
                    }}
                    meta={metaDummy}
                    moduleTitle={'Shipment'}
                    onRowClick={() => {
                        navigate('/booking-details')
                    }}
                    enableExport={true}
                    modalService={generateReportModalService}
                    components={<BookingFilter isGenerateReport={true} />}
                    resetFilter={() => {}}
                    getDataGenerate={() => {}}
                    generateReportData={BookingDataDummy}
                    GenerateReportHeaders={headers}
                    removeFilter={() => {}}
                />
            </div>
            {allMNodal}
        </div>
    )
}

export default Bookings
