/* eslint-disable no-unused-vars */
import FormInput from 'components/form-input/form-input.component'
import Tooltip from 'components/tooltip/tooltip.component'
import { FormikProvider, useFormik } from 'formik'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

export interface IAccordion {
    items: IAccordionItem[]
    header: string
    onSearchSubmit?: (search: string) => void
}

export interface IAccordionItem {
    title: string
    content: {
        subTitle: string
        subContent: JSX.Element
    }[]
}

const Accordion = ({ items, header, onSearchSubmit }: IAccordion) => {
    const { t } = useTranslation()
    const [openIndex, setOpenIndex] = useState<number | null>(null)
    const [search, setSearch] = useState<string>('')
    const heightContent = onSearchSubmit ? 'h-[calc(100vh-20rem)]' : 'h-full'

    const toggleAccordion = (index: number) => {
        setOpenIndex(openIndex === index ? null : index)
    }

    const formik = useFormik<{ searchTerm: string }>({
        initialValues: { searchTerm: '' },
        onSubmit: (values) => {
            onSearchSubmit && onSearchSubmit(values.searchTerm)
            setSearch(values.searchTerm)
        },
        validate: (values) => {
            const errors: any = {}
            if (
                values.searchTerm.length < 3 &&
                values.searchTerm.length !== 0
            ) {
                errors.searchTerm = 'Search term must be at least 3 characters'
            }
            return errors
        },
    })

    return (
        <div className={`${heightContent}`}>
            {onSearchSubmit && (
                <FormikProvider value={formik}>
                    <form
                        className="w-full flex justify-end mb-3"
                        onSubmit={(e) => {
                            e.preventDefault()
                            formik.handleSubmit()
                        }}
                    >
                        <Tooltip
                            text={'Search term must be at least 3 characters'}
                            isShow={formik.errors.searchTerm ? true : false}
                        >
                            <FormInput
                                autoComplete="auto-off"
                                hideError={true}
                                placeholder={t('action.search', {
                                    value: t('additional.items'),
                                })}
                                parentDivClassName="w-full min-w-[240px] mobile:min-w-0 mobile:w-full !mb-0"
                                icon="ri-search-line"
                                name="searchTerm"
                                onKeyDown={(
                                    event: React.KeyboardEvent<HTMLInputElement>,
                                ) => {
                                    if (
                                        event.key === 'Backspace' &&
                                        formik.values.searchTerm.length === 1
                                    ) {
                                        formik.values.searchTerm = ''
                                        formik.handleSubmit()
                                    }
                                }}
                            />
                        </Tooltip>
                    </form>
                </FormikProvider>
            )}
            <div className="border rounded h-full flex flex-col">
                <div className=" font-bold themes-text-gray-v5 p-3 border-b h-12">
                    {header}
                </div>
                <div className="overflow-auto">
                    {items.map((item, index) => {
                        const iconAccordion =
                            openIndex === index
                                ? 'ri-arrow-down-s-line'
                                : 'ri-arrow-up-s-line'
                        return (
                            <div key={index} className={`border-b`}>
                                {!search && (
                                    <button
                                        className={`flex items-center w-full text-left p-3 gap-3 focus:outline-none h-12`}
                                        onClick={() => toggleAccordion(index)}
                                    >
                                        <i
                                            className={`${iconAccordion} text-size-XL`}
                                        ></i>
                                        {item.title}
                                    </button>
                                )}
                                {openIndex === index &&
                                    item.content.map((subitem) => (
                                        <div className="border-t py-3 px-12">
                                            {subitem.subContent}
                                        </div>
                                    ))}
                                {search &&
                                    item.content.map((subitem) => (
                                        <div className="border-t p-3">
                                            {subitem.subContent}
                                        </div>
                                    ))}
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default Accordion
