import SimpleTable from 'components/simple-table/simple-table.component'
import { IShipmentOrder } from '../../shipments.interface'
import { shipmentOrderHeaders } from '../../shipments.static'
import { metaDummy, shipmentOrdersDummy } from '../../shipments.dummy'

const Orders = () => {
    return (
        <SimpleTable<IShipmentOrder>
            headers={shipmentOrderHeaders}
            data={shipmentOrdersDummy}
            usePagination
            nextHandling={(page) => {
                console.log('hit Next API -> ' + page)
            }}
            previousHandling={(page) => {
                console.log('hit Prev API -> ' + page)
            }}
            meta={metaDummy}
        />
    )
}

export default Orders
