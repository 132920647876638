import EstimationInfo from 'components/estimation-info/estimation-info'
import GoogleMap from 'components/google-map/google-map.component'
import { bookingTrackingDummy } from '../booking-details.dummy'

const Tracking = () => {
    return (
        <div className="h-full flex">
            <div className="w-3/12 p-3 border-r flex flex-col gap-3">
                <div className="leading-none">
                    <div className="themes-text-brand-v4">S00001580</div>
                    <div>10 KG</div>
                </div>
                <div className="flex justify-between">
                    <div className="themes-text-gray-v5">
                        <div>House Bill</div>
                        <div>Total Packages</div>
                        <div>Weight</div>
                        <div>Service Level</div>
                        <div>Origin Port</div>
                        <div>Destination Port</div>
                        <div>Container Mode</div>
                        <div>Voyage/ Flight #/ Truck Ref</div>
                    </div>
                    <div className="">
                        <div>S00001001</div>
                        <div>3</div>
                        <div>10 KG</div>
                        <div>Standard</div>
                        <div>IDTNJ</div>
                        <div>USOAK</div>
                        <div>LSE</div>
                        <div>GT020</div>
                    </div>
                </div>
            </div>
            <div className="w-4/12 p-3 border-r flex flex-col gap-3">
                <div className="themes-text-gray-v5">Tracking History</div>
                <EstimationInfo
                    initialCountryName={'Bankok'}
                    initialCountryCode={'THBKK'}
                    destinationCountryName={'USA'}
                    destinationCountryCode={'USNYC'}
                    ETD={'07 Mar 24'}
                    ETA={'16 Apr 24'}
                    iconMap
                />
                <div className="border-b"></div>
                <div>
                    {bookingTrackingDummy.map((data, idx) => {
                        return (
                            <div
                                key={`data-track-${idx}`}
                                className="flex items-start relative"
                            >
                                <div className="mr-2 flex flex-col items-center">
                                    <div className="rounded-full w-2 h-2 bg-blue-500 line-through z-10" />
                                    {idx !==
                                        bookingTrackingDummy.length - 1 && (
                                        <div className="absolute top-0 bottom-0 w-[2px] themes-bg-gray-v2" />
                                    )}
                                </div>
                                <div className="text-[14px] mt-[-4px] pb-5">
                                    <div className="font-bold">
                                        {data.label}
                                    </div>
                                    <div>{data.desc}</div>
                                    <div>{data.time}</div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
            <div className="w-5/12 ">
                <GoogleMap />
            </div>
        </div>
    )
}

export default Tracking
