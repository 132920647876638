import React from 'react'
import { IProgressionStepProps } from './progression-step.interface'
import './progression-step.style.css'
import useProgressionStep from './progression-step.service'

const ProgressionStep: React.FC<IProgressionStepProps> = ({
    steps,
    onChange,
    selectedItem,
}) => {
    const { labels, activeIndex, setActiveIndex } = useProgressionStep(
        steps,
        selectedItem,
    )

    return (
        <>
            {steps?.map((step, idx) => {
                const isActive = activeIndex === idx
                const classActive = !isActive
                    ? ''
                    : 'themes-text-brand-v5 themes-border-brand-v5'
                return (
                    <div
                        key={idx}
                        className={`flex justify-center gap-2 ${classActive}`}
                        onClick={() => {
                            setActiveIndex(idx)
                            onChange(step)
                        }}
                    >
                        {idx === 0 ? (
                            ''
                        ) : step.status === 'completed' ? (
                            <div className="w-28 mt-5 -mx-16">
                                <div className=" border-b-2 themes-border-brand-v5" />
                            </div>
                        ) : (
                            <div className="w-28 mt-5 -mx-16">
                                <div className=" border-b-2 themes-border-brand-v5" />
                            </div>
                        )}
                        <div className="flex flex-col gap-2 justify-center items-center w-48">
                            {step?.status === 'completed' ? (
                                <>
                                    <i className="ri-check-line text-size-L flex justify-center aspect-square border-2 text-white themes-border-brand-v5 themes-bg-brand-v5 rounded-full w-10 items-center"></i>
                                    <span className="text-size-S font-bold themes-text-brand-v5">
                                        {labels[idx]}
                                    </span>
                                </>
                            ) : (
                                <>
                                    <i
                                        className={`${step.icon} text-size-M not-italic p-1 flex justify-center aspect-square border-2 ${isActive ? 'themes-border-brand-v5' : ''} rounded-full w-10 items-center`}
                                    >
                                        {step.textIcon}
                                    </i>
                                    <span className="text-size-S font-normal">
                                        {labels[idx]}
                                    </span>
                                </>
                            )}
                        </div>
                    </div>
                )
            })}
        </>
    )
}

export default ProgressionStep
