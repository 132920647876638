import { IStatusCard } from './status-card.interface'
import { getStatusClass } from './status-card.service'

const StatusCard = ({ status }: IStatusCard) => {
    const statusClass = getStatusClass(status)
    return (
        <div className={`inline-block ${statusClass}`}>
            <span
                className={`font-normal pb-2 pt-3 px-[10px] rounded text-size-S`}
            >
                {status.toUpperCase()}
            </span>

        </div>

    )
}

export default StatusCard
