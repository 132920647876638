import Tab from 'components/tab/tab.component'
import useBookingDetails from './booking-details.service'
import { tabDetailHeaders } from './booking-details.static'
import Button from 'components/button/button.component'
import { useTranslation } from 'react-i18next'

const BookingDetails = () => {
    const { tabFilter, navigate, setTabFilter, getContent } =
        useBookingDetails()
    const { t } = useTranslation()
    return (
        <div className="container-global content-full-height flex">
            <div className="flex flex-grow flex-col w-[calc(100vw-20rem)] sm:w-[calc(100vw-4rem)]">
                <div className="flex justify-between items-center px-3">
                    <Tab
                        containerClassName="pt-3"
                        items={tabDetailHeaders}
                        tabFilter={tabFilter}
                        onChange={(item) => {
                            setTabFilter(item)
                        }}
                    />
                    <div className="flex gap-3">
                        <Button
                            onClick={() => {
                                navigate('/edit-booking')
                            }}
                            label={t('bookings.edit_booking')}
                            variant="brand-inverse"
                            className="w-btnRegularWidth"
                        />
                        <Button
                            onClick={() => navigate('/book-again')}
                            label={t('bookings.book_again')}
                            variant="brand-inverse"
                            className="w-btnRegularWidth"
                        />
                    </div>
                </div>
                <div className="border-b"></div>
                <div className="h-[calc(100vh-13rem)] overflow-auto">
                    {getContent()}
                </div>
                <div className="border-b"></div>
                <div className="p-3">
                    <Button
                        onClick={() => navigate(-1)}
                        label={t('action.back')}
                        variant="brand-inverse"
                        className="w-btnRegularWidth"
                    />
                </div>
            </div>
        </div>
    )
}

export default BookingDetails
