/* eslint-disable no-unused-vars */
import { ITabItem } from 'components/tab/tab.interface'
import { useState } from 'react'
import BookBy from './contents/book-by.component'
import FormCreateBooking from './contents/form-create-booking'
import { useNavigate } from 'react-router-dom'
import { formBookingStatusHeader } from './create-booking.static'
import Details from './contents/details.component'
import { useFormik } from 'formik'
import {
    ICreateBokingValidation,
    ICreateBookingPackLinesValidation,
    createBookingInitialValue,
    createBookingPackLinesInitialValue,
    createBookingPackLinesValidation,
    createBookingValidation,
} from 'form-validation/ifb/booking-details.validation'
import PackLines from './contents/pack-lines.component'
import { Toast } from 'components/toast/toast.component'
import { IBookingPackLines } from '../booking-details/booking-details.interface'

const useCreateBooking = () => {
    const navigate = useNavigate()

    // states
    const [tabFilter, setTabFilter] = useState<ITabItem>({
        label: 'Book By',
        key: 'status',
        value: 'bookBy',
        className: 'brand',
    })
    const [tabFormBooking, setTabFormBooking] = useState<ITabItem>(
        formBookingStatusHeader[0],
    )
    const [packLinesData, setPackLinesData] = useState<IBookingPackLines[]>([])
    const [packLineData, setPackLineData] = useState<IBookingPackLines>(
        createBookingPackLinesInitialValue,
    )
    const [packLineParams, setPackLineParams] = useState<
        'update' | 'add' | 'duplicate' | 'delete'
    >('update')

    // formik
    const formikCreateBooking = useFormik<ICreateBokingValidation>({
        validationSchema: createBookingValidation,
        initialValues: createBookingInitialValue,
        onSubmit: () => {
            Toast({
                header: 'Succes',
                message: 'Booking successfully created',
                type: 'success',
            })
            navigate('/bookings')
        },
    })

    const formikCreateBookingPackLines =
        useFormik<ICreateBookingPackLinesValidation>({
            validationSchema: createBookingPackLinesValidation,
            initialValues: packLineData,
            enableReinitialize: true,
            onSubmit: (values, { resetForm }) => {
                let updateData = []
                let resetValues = null

                switch (packLineParams) {
                    case 'add':
                        updateData = [
                            ...packLinesData,
                            { ...values, id: packLinesData.length + 1 },
                        ]
                        resetValues = createBookingPackLinesInitialValue
                        break
                    case 'update':
                        updateData = packLinesData.map((item) =>
                            item.id === values.id
                                ? { ...item, ...values }
                                : item,
                        )
                        resetValues = values
                        break
                    case 'duplicate':
                        updateData = [
                            ...packLinesData,
                            { ...values, id: packLinesData.length + 1 },
                        ]
                        break
                    case 'delete':
                        updateData = packLinesData.filter(
                            (item) => item.id !== values.id,
                        )
                        resetValues = createBookingPackLinesInitialValue
                        break
                    default:
                        updateData = packLinesData
                        break
                }

                setPackLinesData(updateData)
                if (resetValues !== null) {
                    resetForm({ values: resetValues })
                }
            },
        })

    // functions
    const getContent = () => {
        const contents: Record<string, JSX.Element> = {
            bookBy: <BookBy navigate={navigate} onClick={goToFormCreate} />,
            goToFormCreate: (
                <FormCreateBooking
                    getContentForm={getContentForm}
                    tabFilter={tabFormBooking}
                    setTabFilter={setTabFormBooking}
                    formik={formikCreateBooking}
                    navigate={navigate}
                />
            ),
        }
        return contents[tabFilter.value]
    }

    const getContentForm = () => {
        const contents: Record<string, JSX.Element> = {
            details: <Details />,
            packLines: (
                <PackLines
                    formik={formikCreateBookingPackLines}
                    packLinesData={packLinesData}
                    rowOnClick={(data) => {
                        setPackLineData(data)
                    }}
                    setPackLineParams={(data) => setPackLineParams(data)}
                />
            ),
        }
        return contents[tabFormBooking.value]
    }

    const goToFormCreate = () =>
        setTabFilter({
            label: 'Details',
            key: 'status',
            value: 'goToFormCreate',
            className: 'brand',
        })

    return { getContent }
}

export default useCreateBooking
