import { useCallback, useState } from 'react'
import { openInvoice, paymentInvoiceDetail } from '../../../pages/ifb/dashboard/row3/row3.dummy'
import { Pie, PieChart, ResponsiveContainer } from 'recharts'
import Detail from '../../../pages/ifb/dashboard/detail.component'
import Header from '../../../pages/ifb/dashboard/components/header.component'
import FilterSection from '../../../pages/ifb/dashboard/components/filter-section.component'
import { useTranslation } from 'react-i18next'

function DoughnutChart() {
    // eslint-disable-next-line no-unused-vars
    const [activeIndex, setActiveIndex] = useState<number>(0)
    const [isFilter, setIsFilter] = useState<boolean>(false)
    const { t } = useTranslation()
    const onPieEnter = useCallback(
        (_: any, index: number) => {
            setActiveIndex(index)
        },
        [setActiveIndex],
    )

    const renderColorfulLegendText = (data: any) => {
        return (
            <div className="row-span-11 ">
                <div className="text-gray-400 text-[12px] font-bold font-['Basis Grotesque Arabic Pro']">
                    {t('dashboard.legend')}
                </div>
                {data.map((item: any, index: number) => {
                    const { value, percentage, name, fill, textColor } = item
                    return (
                        <div key={index} className="flex flex-col">
                            <span
                                style={{
                                    color: '#596579',
                                    fontWeight: 500,
                                    fontSize: '14px',
                                }}
                            >
                                {t(name)}
                            </span>
                            <div className="text-gray-700 text-[14px] font-bold font-['Basis Grotesque Arabic Pro']">{`US$ ${value}`}</div>
                            <div
                                style={{ background: `${fill}` }}
                                className={`w-[113px] h-4 px-1 bg-red-100 rounded justify-center items-center gap-1 inline-flex`}
                            >
                                <div
                                    style={{ color: `${textColor}` }}
                                    className="text-[14px] font-bold font-['Basis Grotesque Arabic Pro'] leading-[18px]"
                                >
                                    {percentage}
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    }

    return (
        <div className="grid grid-rows-12 h-full">
            <Header
                className="h-5 row-span-1"
                title={t('dashboard.total_shipments_&_volume_by_mode')}
                filter={` ${t('dashboard.last_days', {
                    value: 7,
                })} (17/03/24 - 23/03/24)`}
                isFilter={isFilter}
                setIsFilter={setIsFilter}
            />

            {isFilter ? (
                <div className='lg:row-span-11 md:row-span-11 px-2'>
                    <FilterSection
                        className='grid gap-3 mt-6'
                        displayedInform={true}
                        filterBy={true}
                        dateRange={true}
                    />
                </div>
            ) : (
                <div className="grid grid-cols-2 row-span-11 gap-4">
                    <ResponsiveContainer
                        width="100%"
                        height="100%"
                        className="grid-cols-1"
                    >
                        <PieChart
                            className="content-center"
                            width={200}
                            height={200}
                            onMouseEnter={onPieEnter}
                        >
                            <Pie
                                data={openInvoice}
                                cx="50%"
                                cy="25%"
                                innerRadius={40}
                                outerRadius={95}
                                fill="#8884d8"
                                paddingAngle={0}
                                dataKey="value"
                            ></Pie>
                        </PieChart>
                    </ResponsiveContainer>
                    <div className="grid-cols-1 grid grid-rows-12 ">
                        <Detail
                            className="grid-cols-2 row-span-1 content-center"
                            data={paymentInvoiceDetail}
                        />
                        {renderColorfulLegendText(openInvoice)}
                    </div>
                </div>
            )}

        </div>
    )
}

export default DoughnutChart
