import React, { FC, useEffect, useState } from 'react';
import {
    BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Text
} from 'recharts';
import { IBarChartDoubleProps, ICustomizedXAxisTickProps, IRoundedBarProps } from '../rechart-interface';



const BarChartDouble: FC<IBarChartDoubleProps> = ({ data }) => {
    const [widthSize, setWidthSize] = useState(window.innerWidth);
    const [heightSize, setHeightSize] = useState(window.innerHeight);
    const [dimension, setDimension] = useState({
        width: widthSize,
        height: 235
    })

    const updateDimensions = () => {
        setWidthSize(window.innerWidth);
        setHeightSize(window.innerHeight);
        const newDimension = { ...dimension }
        if (heightSize === 1080) newDimension.height = 250
        else if (heightSize < 1440 && heightSize > 1300) {
            newDimension.height = 440
        }
        else if (heightSize === 911) newDimension.height = 320
        setDimension(newDimension)
    };

    useEffect(() => {
        window.addEventListener('resize', updateDimensions);
        return () => {
            window.removeEventListener('resize', updateDimensions);
        };
    });

    const RoundedBar = (props: IRoundedBarProps) => {
        const { x, y, width, height } = props;
        return (
            <g>
                <rect
                    x={x}
                    y={y}
                    width={width}
                    height={height}
                    fill={props.fill}
                    rx={5}
                    ry={5}
                />
            </g>
        );
    };


    const CustomizedXAxisTick = ({ x, y, payload, width }: ICustomizedXAxisTickProps) => {
        if (payload === undefined) {
            if (width === undefined) width = 0;
            payload = { value: "" };
        }
        const words = payload.value.split(" ");
        const maxWordsPerLine = 3;
        let lines = [];
        for (let i = 0; i < words.length; i += maxWordsPerLine) {
            lines.push(words.slice(i, i + maxWordsPerLine).join(" "));
        }

        const isTextOverlapping = width ? width < lines.join("").length * 15 : 0;

        return (
            <g transform={`translate(${x},${y})`}>
                {isTextOverlapping ? null : (
                    lines.map((line, index) => (
                        <text
                            key={index}
                            x={width ? width / 1000 : 0}
                            y={index * 16}
                            dy={16}
                            textAnchor="middle"
                            fill="#666"
                            fontSize={heightSize < 900 ? 9 : 12}
                        >
                            {line}
                        </text>
                    ))
                )}
            </g>
        );
    };

    const CustomizedLabel = (props: any) => {
        const { x, y, width, height, value } = props;
        return (
            <Text x={x + width / 2} y={y + height / 2} dy={5} fill="#fff" textAnchor="middle">
                {value}
            </Text>
        );
    };

    return (
        <ResponsiveContainer width="100%" height="100%">
            <BarChart
                data={data.item}
                margin={{
                    top: 0,
                    right: 0,
                    left: 0,
                    bottom: -30,
                }}
            >
                <CartesianGrid stroke="#f5f5f5" />
                <XAxis
                    dataKey="name"
                    interval={0}
                    height={70}
                    tick={<CustomizedXAxisTick />} />
                <YAxis type={'number'} />
                <Tooltip />
                <Bar dataKey="pv" fill={data.barColor1} shape={<RoundedBar />} label={<CustomizedLabel />} />
                <Bar dataKey="uv" fill={data.barColor2} shape={<RoundedBar />} label={<CustomizedLabel />} />
            </BarChart>
        </ResponsiveContainer>
    );
};

export default BarChartDouble;
