import { IManageUser } from './manage-users.interface'
import { IMeta } from 'common/common.interface'

export const ManageUsersDataDummy: IManageUser[] = [
    {
        userID: 'UserID001',
        userName: 'Darlene Osinski',
        userEmail: 'darlene.osinski@gmail.com',
        userJobTitle: 'Admin Staff',
        userJobCategory: 'Logistics',
        userRole: 'Admin',
        userOrganizationName: 'International Freightbridge',
        userStatus: 'Active',
        userLastActive: '19/04/2024, 15:00',
        userGender: 'Male',
        userCountry: 'Indonesia',
        userCity: 'Jakarta',
        userAddress: 'Jl. Imam Bonjol No. 14',
        userMobilePhone: '+612345678910',
        userWorkPhone: '+6234567891011',
        userHomePhone: '+634567891011',
        userUsername: 'Darlene Osinski',
        userPassword: 'Test1',
        userActivity: [
            {
                timestamp: '12/20/24 15:00:00',
                module: 'Booking',
                action: 'Create Booking',
                description: 'Created new booking',
            },
            {
                timestamp: '12/21/24 16:00:00',
                module: 'Quotation',
                action: 'Edit Quotation',
                description: 'Edit quotations no 0000001041',
            },
            {
                timestamp: '12/22/24 17:00:00',
                module: 'Our Service',
                action: 'Submit Requirement',
                description: 'Submit new requirement',
            },
        ],
    },
    {
        userID: 'UserID002',
        userName: 'Bonnie Considine',
        userEmail: 'bonnie.considine@gmail.com',
        userJobTitle: 'Admin Staff',
        userJobCategory: 'Operations ',
        userRole: 'Admin',
        userOrganizationName: 'International Freightbridge',
        userStatus: 'Active',
        userLastActive: '19/04/2024, 15:00',
        userGender: 'Female',
        userCountry: 'Indonesia',
        userCity: 'Jakarta',
        userAddress: 'Jl. Imam Bonjol No. 14',
        userMobilePhone: '+612345678910',
        userWorkPhone: '+6234567891011',
        userHomePhone: '+634567891011',
        userUsername: 'Bonnie Considine',
        userPassword: 'Test1',
        userActivity: [
            {
                timestamp: '12/20/24 15:00:00',
                module: 'Booking',
                action: 'Create Booking',
                description: 'Created new booking',
            },
            {
                timestamp: '12/21/24 16:00:00',
                module: 'Quotation',
                action: 'Edit Quotation',
                description: 'Edit quotations no 0000001041',
            },
            {
                timestamp: '12/22/24 17:00:00',
                module: 'Our Service',
                action: 'Submit Requirement',
                description: 'Submit new requirement',
            },
        ],
    },
    {
        userID: 'UserID003',
        userName: 'Sara Gutmann',
        userEmail: 'sara.gutsmann@gmail.com',
        userJobTitle: 'Customer Service',
        userJobCategory: 'Logistics',
        userRole: 'Regular User',
        userOrganizationName: 'International Freightbridge',
        userStatus: 'Active',
        userLastActive: '19/04/2024, 15:00',
        userGender: 'Female',
        userCountry: 'Indonesia',
        userCity: 'Jakarta',
        userAddress: 'Jl. Imam Bonjol No. 14',
        userMobilePhone: '+612345678910',
        userWorkPhone: '+6234567891011',
        userHomePhone: '+634567891011',
        userUsername: 'Sara Gutmann',
        userPassword: 'Test1',
        userActivity: [
            {
                timestamp: '12/20/24 15:00:00',
                module: 'Booking',
                action: 'Create Booking',
                description: 'Created new booking',
            },
            {
                timestamp: '12/21/24 16:00:00',
                module: 'Quotation',
                action: 'Edit Quotation',
                description: 'Edit quotations no 0000001041',
            },
            {
                timestamp: '12/22/24 17:00:00',
                module: 'Our Service',
                action: 'Submit Requirement',
                description: 'Submit new requirement',
            },
        ],
    },
    {
        userID: 'UserID004',
        userName: 'Alex Ratke',
        userEmail: 'alex.ratke@gmail.com',
        userJobTitle: 'Customer Service',
        userJobCategory: 'Operations ',
        userRole: 'Regular User',
        userOrganizationName: 'International Freightbridge',
        userStatus: 'Active',
        userLastActive: '19/04/2024, 15:00',
        userGender: 'Male',
        userCountry: 'Indonesia',
        userCity: 'Jakarta',
        userAddress: 'Jl. Imam Bonjol No. 14',
        userMobilePhone: '+612345678910',
        userWorkPhone: '+6234567891011',
        userHomePhone: '+634567891011',
        userUsername: 'Alex Ratke',
        userPassword: 'Test1',
        userActivity: [
            {
                timestamp: '12/20/24 15:00:00',
                module: 'Booking',
                action: 'Create Booking',
                description: 'Created new booking',
            },
            {
                timestamp: '12/21/24 16:00:00',
                module: 'Quotation',
                action: 'Edit Quotation',
                description: 'Edit quotations no 0000001041',
            },
            {
                timestamp: '12/22/24 17:00:00',
                module: 'Our Service',
                action: 'Submit Requirement',
                description: 'Submit new requirement',
            },
        ],
    },
    {
        userID: 'UserID005',
        userName: 'Rolando Rosenbaum',
        userEmail: 'rolando.roesenbaum@gmail.com',
        userJobTitle: 'Warehouse Manager',
        userJobCategory: 'Logistics',
        userRole: 'Admin',
        userOrganizationName: 'International Freightbridge',
        userStatus: 'Suspended',
        userLastActive: '19/04/2024, 15:00',
        userGender: 'Male',
        userCountry: 'Indonesia',
        userCity: 'Jakarta',
        userAddress: 'Jl. Imam Bonjol No. 14',
        userMobilePhone: '+612345678910',
        userWorkPhone: '+6234567891011',
        userHomePhone: '+634567891011',
        userUsername: 'Rolando Rosenbaum',
        userPassword: 'Test1',
        userActivity: [
            {
                timestamp: '12/20/24 15:00:00',
                module: 'Booking',
                action: 'Create Booking',
                description: 'Created new booking',
            },
            {
                timestamp: '12/21/24 16:00:00',
                module: 'Quotation',
                action: 'Edit Quotation',
                description: 'Edit quotations no 0000001041',
            },
            {
                timestamp: '12/22/24 17:00:00',
                module: 'Our Service',
                action: 'Submit Requirement',
                description: 'Submit new requirement',
            },
        ],
    },
    {
        userID: 'UserID006',
        userName: 'Lorene Wuckert',
        userEmail: 'lorene.wuckert@gmail.com',
        userJobTitle: 'Warehouse Manager',
        userJobCategory: 'Operations',
        userRole: 'Admin',
        userOrganizationName: 'International Freightbridge',
        userStatus: 'Suspended',
        userLastActive: '19/04/2024, 15:00',
        userGender: 'Female',
        userCountry: 'Indonesia',
        userCity: 'Jakarta',
        userAddress: 'Jl. Imam Bonjol No. 14',
        userMobilePhone: '+612345678910',
        userWorkPhone: '+6234567891011',
        userHomePhone: '+634567891011',
        userUsername: 'Lorene Wuckert',
        userPassword: 'Test1',
        userActivity: [
            {
                timestamp: '12/20/24 15:00:00',
                module: 'Booking',
                action: 'Create Booking',
                description: 'Created new booking',
            },
            {
                timestamp: '12/21/24 16:00:00',
                module: 'Quotation',
                action: 'Edit Quotation',
                description: 'Edit quotations no 0000001041',
            },
            {
                timestamp: '12/22/24 17:00:00',
                module: 'Our Service',
                action: 'Submit Requirement',
                description: 'Submit new requirement',
            },
        ],
    },
    {
        userID: 'UserID007',
        userName: 'Barbara Block',
        userEmail: 'barbara.block@gmail.com',
        userJobTitle: 'Logistics Coordinator',
        userJobCategory: 'Logistics',
        userRole: 'Regular User',
        userOrganizationName: 'International Freightbridge',
        userStatus: 'Suspended',
        userLastActive: '19/04/2024, 15:00',
        userGender: 'Female',
        userCountry: 'Indonesia',
        userCity: 'Jakarta',
        userAddress: 'Jl. Imam Bonjol No. 14',
        userMobilePhone: '+612345678910',
        userWorkPhone: '+6234567891011',
        userHomePhone: '+634567891011',
        userUsername: 'Barbara Block',
        userPassword: 'Test1',
        userActivity: [
            {
                timestamp: '12/20/24 15:00:00',
                module: 'Booking',
                action: 'Create Booking',
                description: 'Created new booking',
            },
            {
                timestamp: '12/21/24 16:00:00',
                module: 'Quotation',
                action: 'Edit Quotation',
                description: 'Edit quotations no 0000001041',
            },
            {
                timestamp: '12/22/24 17:00:00',
                module: 'Our Service',
                action: 'Submit Requirement',
                description: 'Submit new requirement',
            },
        ],
    },
    {
        userID: 'UserID008',
        userName: 'Elias Keebler',
        userEmail: 'elias.keebler@gmail.com',
        userJobTitle: 'Customer Service',
        userJobCategory: 'Operations ',
        userRole: 'Regular User',
        userOrganizationName: 'International Freightbridge',
        userStatus: 'Suspended',
        userLastActive: '19/04/2024, 15:00',
        userGender: 'Male',
        userCountry: 'Indonesia',
        userCity: 'Jakarta',
        userAddress: 'Jl. Imam Bonjol No. 14',
        userMobilePhone: '+612345678910',
        userWorkPhone: '+6234567891011',
        userHomePhone: '+634567891011',
        userUsername: 'Elias Keebler',
        userPassword: 'Test1',
        userActivity: [
            {
                timestamp: '12/20/24 15:00:00',
                module: 'Booking',
                action: 'Create Booking',
                description: 'Created new booking',
            },
            {
                timestamp: '12/21/24 16:00:00',
                module: 'Quotation',
                action: 'Edit Quotation',
                description: 'Edit quotations no 0000001041',
            },
            {
                timestamp: '12/22/24 17:00:00',
                module: 'Our Service',
                action: 'Submit Requirement',
                description: 'Submit new requirement',
            },
        ],
    },
    {
        userID: 'UserID009',
        userName: 'Dale Gottlieb',
        userEmail: 'dale.gottlieb@gmail.com',
        userJobTitle: 'Customer Service',
        userJobCategory: 'Logistics',
        userRole: 'Admin',
        userOrganizationName: 'International Freightbridge',
        userStatus: 'Active',
        userLastActive: '19/04/2024, 15:00',
        userGender: 'Male',
        userCountry: 'Indonesia',
        userCity: 'Jakarta',
        userAddress: 'Jl. Imam Bonjol No. 14',
        userMobilePhone: '+612345678910',
        userWorkPhone: '+6234567891011',
        userHomePhone: '+634567891011',
        userUsername: 'Dale Gottlieb',
        userPassword: 'Test1',
        userActivity: [
            {
                timestamp: '12/20/24 15:00:00',
                module: 'Booking',
                action: 'Create Booking',
                description: 'Created new booking',
            },
            {
                timestamp: '12/21/24 16:00:00',
                module: 'Quotation',
                action: 'Edit Quotation',
                description: 'Edit quotations no 0000001041',
            },
            {
                timestamp: '12/22/24 17:00:00',
                module: 'Our Service',
                action: 'Submit Requirement',
                description: 'Submit new requirement',
            },
        ],
    },
    {
        userID: 'UserID010',
        userName: 'Judy Schroeder',
        userEmail: 'judy.schroeder@gmail.com',
        userJobTitle: 'Admin Staff',
        userJobCategory: 'Operations ',
        userRole: 'Admin',
        userOrganizationName: 'International Freightbridge',
        userStatus: 'Suspended',
        userLastActive: '19/04/2024, 15:00',
        userGender: 'Male',
        userCountry: 'Indonesia',
        userCity: 'Jakarta',
        userAddress: 'Jl. Imam Bonjol No. 14',
        userMobilePhone: '+612345678910',
        userWorkPhone: '+6234567891011',
        userHomePhone: '+634567891011',
        userUsername: 'Judy Schroeder',
        userPassword: 'Test1',
        userActivity: [
            {
                timestamp: '12/20/24 15:00:00',
                module: 'Booking',
                action: 'Create Booking',
                description: 'Created new booking',
            },
            {
                timestamp: '12/21/24 16:00:00',
                module: 'Quotation',
                action: 'Edit Quotation',
                description: 'Edit quotations no 0000001041',
            },
            {
                timestamp: '12/22/24 17:00:00',
                module: 'Our Service',
                action: 'Submit Requirement',
                description: 'Submit new requirement',
            },
        ],
    },
    {
        userID: 'UserID011',
        userName: 'Gladys Boyer',
        userEmail: 'gladys.boyer@gmail.com',
        userJobTitle: 'Admin Staff',
        userJobCategory: 'Logistics',
        userRole: 'Regular User',
        userOrganizationName: 'International Freightbridge',
        userStatus: 'Active',
        userLastActive: '19/04/2024, 15:00',
        userGender: 'Female',
        userCountry: 'Indonesia',
        userCity: 'Jakarta',
        userAddress: 'Jl. Imam Bonjol No. 14',
        userMobilePhone: '+612345678910',
        userWorkPhone: '+6234567891011',
        userHomePhone: '+634567891011',
        userUsername: 'Gladys Boyer',
        userPassword: 'Test1',
        userActivity: [
            {
                timestamp: '12/20/24 15:00:00',
                module: 'Booking',
                action: 'Create Booking',
                description: 'Created new booking',
            },
            {
                timestamp: '12/21/24 16:00:00',
                module: 'Quotation',
                action: 'Edit Quotation',
                description: 'Edit quotations no 0000001041',
            },
            {
                timestamp: '12/22/24 17:00:00',
                module: 'Our Service',
                action: 'Submit Requirement',
                description: 'Submit new requirement',
            },
        ],
    },
    {
        userID: 'UserID012',
        userName: 'Eddie Kub',
        userEmail: 'eddie.kub@gmail.com',
        userJobTitle: 'Customer Service',
        userJobCategory: 'Operations ',
        userRole: 'Regular User',
        userOrganizationName: 'International Freightbridge',
        userStatus: 'Active',
        userLastActive: '19/04/2024, 15:00',
        userGender: 'Male',
        userCountry: 'Indonesia',
        userCity: 'Jakarta',
        userAddress: 'Jl. Imam Bonjol No. 14',
        userMobilePhone: '+612345678910',
        userWorkPhone: '+6234567891011',
        userHomePhone: '+634567891011',
        userUsername: 'Eddie Kub',
        userPassword: 'Test1',
        userActivity: [
            {
                timestamp: '12/20/24 15:00:00',
                module: 'Booking',
                action: 'Create Booking',
                description: 'Created new booking',
            },
            {
                timestamp: '12/21/24 16:00:00',
                module: 'Quotation',
                action: 'Edit Quotation',
                description: 'Edit quotations no 0000001041',
            },
            {
                timestamp: '12/22/24 17:00:00',
                module: 'Our Service',
                action: 'Submit Requirement',
                description: 'Submit new requirement',
            },
        ],
    },
    {
        userID: 'UserID013',
        userName: 'Vanessa McLaughlin',
        userEmail: 'vanessa@gmail.com',
        userJobTitle: 'Customer Service',
        userJobCategory: 'Logistics',
        userRole: 'Admin',
        userOrganizationName: 'International Freightbridge',
        userStatus: 'Suspended',
        userLastActive: '19/04/2024, 15:00',
        userGender: 'Female',
        userCountry: 'Indonesia',
        userCity: 'Jakarta',
        userAddress: 'Jl. Imam Bonjol No. 14',
        userMobilePhone: '+612345678910',
        userWorkPhone: '+6234567891011',
        userHomePhone: '+634567891011',
        userUsername: 'Vanessa McLaughlin',
        userPassword: 'Test1',
        userActivity: [
            {
                timestamp: '12/20/24 15:00:00',
                module: 'Booking',
                action: 'Create Booking',
                description: 'Created new booking',
            },
            {
                timestamp: '12/21/24 16:00:00',
                module: 'Quotation',
                action: 'Edit Quotation',
                description: 'Edit quotations no 0000001041',
            },
            {
                timestamp: '12/22/24 17:00:00',
                module: 'Our Service',
                action: 'Submit Requirement',
                description: 'Submit new requirement',
            },
        ],
    },
    {
        userID: 'UserID014',
        userName: 'Jose Legros',
        userEmail: 'jose.legros@gmail.com',
        userJobTitle: 'Office Manager',
        userJobCategory: 'Operations ',
        userRole: 'Admin',
        userOrganizationName: 'International Freightbridge',
        userStatus: 'Suspended',
        userLastActive: '19/04/2024, 15:00',
        userGender: 'Male',
        userCountry: 'Indonesia',
        userCity: 'Jakarta',
        userAddress: 'Jl. Imam Bonjol No. 14',
        userMobilePhone: '+612345678910',
        userWorkPhone: '+6234567891011',
        userHomePhone: '+634567891011',
        userUsername: 'Jose Legros',
        userPassword: 'Test1',
        userActivity: [
            {
                timestamp: '12/20/24 15:00:00',
                module: 'Booking',
                action: 'Create Booking',
                description: 'Created new booking',
            },
            {
                timestamp: '12/21/24 16:00:00',
                module: 'Quotation',
                action: 'Edit Quotation',
                description: 'Edit quotations no 0000001041',
            },
            {
                timestamp: '12/22/24 17:00:00',
                module: 'Our Service',
                action: 'Submit Requirement',
                description: 'Submit new requirement',
            },
        ],
    },
]

export const metaDummy: IMeta = {
    current_page: 1,
    last_page: 1,
    from: 1,
    to: 50,
    total_Items: 14,
    per_page: 0,
    total_page: 1,
    index_end: 14,
    index_start: 1,
}
