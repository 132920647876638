import { IDropdownItem } from "components/dropdown/dropdown.interface"
import { useState } from "react"
import { useNavigate } from "react-router-dom"

const useManageUserForm = () => {
    const navigate = useNavigate()

    const [organization, setOrganization] = useState<
        IDropdownItem[]
    >([
        { label: 'IFB - International Freightbridge ', value: 'ifb' },
    ])

    const [roleDataDropdown, setRoleDataDropdown] = useState<IDropdownItem[]>(
        [
            {
                label: 'Admin User',
                value: 'admin'
            },
            {
                label: 'Regular User',
                value: 'regular'
            }
        ]
    )

    const [gender, setGender] = useState<IDropdownItem[]>(
        [
            {
                label: 'Male',
                value: 'male'
            },
            {
                label: 'Female',
                value: 'female'
            }
        ]
    )

    return {
        organization,
        roleDataDropdown,
        gender,
        navigate,
        setOrganization,
        setRoleDataDropdown,
        setGender,
    }
}

export default useManageUserForm