import ButtonExport from 'components/button-export/button-export.component'
import Button from 'components/button/button.component'
import Modal from 'components/modal/modal.component'
import { IPoGenerateReportModal } from '../table-card.interface'

function PoGenerateReportModal<T>({
    modalService,
    resetFilter,
    components,
    moduleTitle,
    data,
    exportType,
    getDataGenerate,
    generateReportData,
    GenerateReportHeaders,
    removeFilter,
}: IPoGenerateReportModal<T>) {
    return (
        <Modal
            isModalOpen={modalService.isModalOpen}
            className="!w-1/3 rounded-[10px] !p-0 flex modal-generate-report"
        >
            <div className="flex flex-col gap-3 justify-content w-full">
                <div className="flex font-bold text-size-L justify-between px-4 pt-4">
                    {' '}
                    Generate Report {moduleTitle}
                    <Button
                        className="!w-4 !h-4 !border-0"
                        icon="ri-close-fill"
                        onClick={() => {
                            modalService?.closeModalHandling()
                            // remove temporary filter when close modal
                            removeFilter()
                        }}
                    />
                </div>
                <div className="border-b border-gray-40"></div>
                <div className="overflow-auto">{components}</div>
                <div className="border-b border-gray-40"></div>
                <div className="w-full flex  gap-3 px-4 pb-3">
                    <Button
                        onClick={async () => {
                            resetFilter && resetFilter()
                        }}
                        label="RESET"
                        className="w-1/4"
                        variant="red"
                    />
                    <ButtonExport
                        data={data}
                        module={moduleTitle}
                        headers={GenerateReportHeaders}
                        onExport={modalService?.closeModalHandling}
                        exportType={exportType}
                        getDataGenerate={getDataGenerate}
                        generateReportData={generateReportData}
                    />
                </div>
            </div>
        </Modal>
    )
}

export default PoGenerateReportModal
