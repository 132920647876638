import CardsDashboard from 'components/card-dashboard/card-dashboard.component'
import usePerformances from './performances.service'
import Dropdown from 'components/dropdown/dropdown.component'
import { IFilterPerformancesItems, ITransit } from './performances.interface'
import Button from 'components/button/button.component'
import {
    dataBarChart,
    dataBarChartTotalCoast,
    dataJointChart,
    dataSimpleLine,
    dataTransitTime,
    itemInfoBarChart,
    itemInfoBarChartCoast,
    itemInfoTransit,
    itemListInfoBarChart,
    itemListInfoJoinChart,
    itemListInfoPortMetics,
    optionsFilterPerformances,
    tabsConfig,
    tabsConfigTotalCoast,
    tabsConfigTransit,
} from './performances.static'
import ItemListInfo from 'components/item-list-info/item-list-info.component'
import Progress from './components/progress.component'
import {
    DataDummy,
    PeformanceSummary,
    metaDummy,
    progressDataChart,
} from './performances.dummy'
import ProgressBarChart from 'components/progress-bar-chart/progress-bar-chart.component'

import {
    SimpleLineChart,
    JointLineScatterChart,
    BarChartDouble,
    BarChartStacked,
} from 'components/rechart-component'
import TableCard from 'components/table-card/table-card.component'
import { useTranslation } from 'react-i18next'

function Performances() {
    const {
        data,
        formLine,
        tabFilter,
        setTabFilter,
        tabTotalCoast,
        setTabTotalCoast,
        deleteFormLine,
        tabTransit,
        setTabTransit,
        updateFilterType,
    } = usePerformances()
    const { t } = useTranslation()
    return (
        <div className="grid gap-2 lg:h-[calc(100vh-100px)] lg:grid-rows-12">
            <div className="grid gap-2 lg:grid-cols-2 lg:row-span-5 md:grid-cols-1">
                {/* Filter */}
                <div className="flex flex-col gap-2">
                    <div className="flex w-full justify-between bg-white rounded-md p-1">
                        {tabsConfig.map((tab) => (
                            <div
                                key={tab.id}
                                onClick={() => setTabFilter(tab.id)}
                                className={`
                                    p-2 rounded-md text-center flex-grow cursor-pointer text-[12px]
                                    ${tabFilter === tab.id ? 'themes-bg-brand-v2 themes-text-brand-v4 transition-colors duration-500' : ' themes-text-gray-v5 transition-colors duration-300'}
                                `}
                            >
                                {t(tab.label)}
                            </div>
                        ))}
                    </div>
                    <CardsDashboard className="flex flex-col flex-grow  text-[12px]">
                        <div className=" w-full px-[20px] py-[10px] border-b themes-border-color themes-text-gray-v3 font-light">
                            {t('action.filter')}
                        </div>
                        <div className="w-full px-[20px] py-[15px] flex-grow overflow-y-auto">
                            {formLine.map((data, idx) => {
                                return (
                                    <div
                                        key={`filter-item-volume-${idx}`}
                                        className="flex w-full justify-between gap-2 pb-2"
                                    >
                                        <div className="w-full">
                                            <Dropdown
                                                options={
                                                    optionsFilterPerformances
                                                }
                                                label={t('performance.filter_type')}
                                                onClick={(selectedOption) => {
                                                    if (selectedOption) {
                                                        updateFilterType(
                                                            idx,
                                                            selectedOption as IFilterPerformancesItems,
                                                        )
                                                    }
                                                }}
                                                placeholder="Select here"
                                                isClearable={true}
                                                isSearchable={true}
                                            />
                                        </div>

                                        <div
                                            className={`w-full ${data.filterType === '' && 'opacity-0'}`}
                                        >
                                            <Dropdown
                                                options={[
                                                    {
                                                        label: 'IDJKT - Jakarta, Java',
                                                        value: 'IDJKT - Jakarta, Java',
                                                    },
                                                    {
                                                        label: 'THBKK - Bangkok',
                                                        value: 'THBKK - Bangkok',
                                                    },
                                                    {
                                                        label: 'USLAX - Los Angeles',
                                                        value: 'USLAX - Los Angeles',
                                                    },
                                                    {
                                                        label: 'USNYC - New York',
                                                        value: 'USNYC - New York',
                                                    },
                                                ]}
                                                label={data.filter.label}
                                                onClick={() => { }}
                                                placeholder={`Select ${data.filter.label}`}
                                                isClearable={true}
                                                isSearchable={true}
                                            />
                                        </div>
                                        <div className="w-[50px]">
                                            {data.filter.label !== '' && (
                                                <i
                                                    className={`ri-delete-bin-line themes-text-hover-red-v3 text-[26px] cursor-pointer `}
                                                    onClick={() =>
                                                        deleteFormLine(idx)
                                                    }
                                                />
                                            )}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        <div className="w-full px-[20px] py-[10px] flex gap-2 justify-end border-t">
                            <Button
                                onClick={async () => { }}
                                label={t('performance.clear_all_filter')}
                                className=""
                                variant="brand-inverse"
                            />
                            <Button
                                onClick={() => { }}
                                label={t('performance.manage_layout')}
                                className=""
                                variant="brand-inverse"
                            />
                        </div>
                    </CardsDashboard>
                </div>
                {/* Regional Route */}
                <CardsDashboard className="flex flex-col pt-0">
                    {tabFilter === 'performance' ? (
                        <>
                            <div className="w-full py-[4.5px] border-b flex justify-between items-center">
                                <div className="themes-text-gray-v3 font-light text-[12px]">
                                    <div className="font-bold">
                                        {t('performance.average_delay_days')}
                                    </div>
                                    <div>{t('performance.monthly')}</div>
                                </div>
                                <ItemListInfo
                                    itemListInfo={itemListInfoJoinChart}
                                />
                            </div>
                            <div className="flex-grow w-full flex items-end">
                                <JointLineScatterChart data={dataJointChart} />
                            </div>
                        </>
                    ) : tabFilter === 'transit' ? (
                        <>
                            <div className="w-full py-[4.5px] border-b flex justify-between items-center">
                                <div className="themes-text-gray-v3 font-light text-[12px]">
                                    <div className="font-bold">
                                        {t('performance.transit_times_by')}
                                    </div>
                                    <div> {t('performance.monthly')}</div>
                                </div>
                                <ItemListInfo itemListInfo={itemInfoBarChart} />
                            </div>
                            <BarChartDouble data={dataTransitTime} />
                        </>
                    ) : (
                        <>
                            <div className="w-full pt-[4.5px] border-b flex justify-between items-center">
                                <div className="themes-text-gray-v3 font-light text-[12px]">
                                    <div className="font-bold">{t('performance.total_cost')}</div>
                                    <div className="flex gap-2">
                                        {tabsConfigTotalCoast.map((tab) => (
                                            <div
                                                key={tab.id}
                                                onClick={() =>
                                                    setTabTotalCoast(tab.id)
                                                }
                                                className={`
                                                cursor-pointer
                                                ${tabTotalCoast === tab.id
                                                        ? 'font-bold border-b-2 themes-border-brand-v4 themes-text-brand-v4 transition-colors duration-500'
                                                        : ' themes-text-gray-v5 transition-colors duration-300'
                                                    }
                                                `}
                                            >
                                                {t(tab.label)}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <ItemListInfo
                                    itemListInfo={itemInfoBarChartCoast}
                                />
                            </div>
                            <BarChartStacked data={dataBarChartTotalCoast} />
                        </>
                    )}
                </CardsDashboard>
            </div>
            {tabFilter === 'performance' ? (
                <>
                    <div className="grid gap-2 lg:grid-cols-2 lg:row-span-5 md:grid-cols-1">
                        <CardsDashboard className="flex flex-col pt-0">
                            <div className=" w-full py-[4.5px] border-b flex justify-between items-center">
                                <div className=" themes-text-gray-v3 font-light text-[12px]">
                                    <div className="font-bold">
                                        {t('performance.port_metrics')}
                                    </div>
                                    <div>{t('performance.monthly')}</div>
                                </div>
                            </div>
                            <div className="flex justify-end">
                                <div className=" border-2 py-1 px-2 rounded-md">
                                    <ItemListInfo
                                        itemListInfo={itemListInfoPortMetics}
                                    />
                                </div>
                            </div>
                            <ProgressBarChart data={progressDataChart} />
                        </CardsDashboard>
                        <CardsDashboard className="flex flex-col pt-0">
                            <div className=" w-full py-[4.5px] border-b flex justify-between items-center">
                                <div className=" themes-text-gray-v3 font-light text-[12px]">
                                    <div className="font-bold">
                                        {t('performance.average_delay_days')}
                                    </div>
                                    <div>{t('performance.monthly')}</div>
                                </div>
                                <ItemListInfo
                                    itemListInfo={itemListInfoBarChart}
                                />
                            </div>
                            <div className="flex-grow w-full flex items-end">
                                <BarChartStacked data={dataBarChart} useLabel />
                            </div>
                        </CardsDashboard>
                    </div>
                    <div className="flex h-full lg:row-span-2">
                        <div className="flex-grow gap-2 grid lg:grid-cols-6 lg:row-span-2 md:grid-cols-2">
                            {PeformanceSummary.map(
                                (data: any, index: number) => {
                                    return (
                                        <CardsDashboard
                                            key={index}
                                            className="overflow-hidden md:min-h-[100px] sm:min-h-[120px]"
                                        >
                                            <Progress data={data} />
                                        </CardsDashboard>
                                    )
                                },
                            )}
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <div className="flex h-full lg:row-span-7 ">
                        <CardsDashboard className="flex flex-col pt-0">
                            {tabFilter === 'transit' ? (
                                <>
                                    <div className="w-full pt-[4.5px] border-b flex justify-between items-center">
                                        <div className="themes-text-gray-v3 font-light text-[12px]">
                                            <div className="font-bold">
                                                {t('performance.transit_process')}
                                            </div>
                                            <div className="flex gap-2">
                                                {tabsConfigTransit.map(
                                                    (tab) => (
                                                        <div
                                                            key={tab.id}
                                                            onClick={() =>
                                                                setTabTransit(
                                                                    tab.id,
                                                                )
                                                            }
                                                            className={`
                                                cursor-pointer
                                                ${tabTransit === tab.id
                                                                    ? 'font-bold border-b-2 themes-border-brand-v4 themes-text-brand-v4 transition-colors duration-500'
                                                                    : ' themes-text-gray-v5 transition-colors duration-300'
                                                                }
                                                `}
                                                        >
                                                            {t(tab.label)}
                                                        </div>
                                                    ),
                                                )}
                                            </div>
                                        </div>
                                        <ItemListInfo
                                            itemListInfo={itemInfoTransit}
                                        />
                                    </div>
                                    <TableCard<ITransit>
                                        data={data}
                                        loading={false}
                                        summaryDetail={DataDummy}
                                        nextHandling={(page) => {
                                            console.log(
                                                'hit Next API -> ' + page,
                                            )
                                        }}
                                        previousHandling={(page) => {
                                            console.log(
                                                'hit Prev API -> ' + page,
                                            )
                                        }}
                                        meta={metaDummy}
                                        moduleTitle={'Trackings'}
                                        enableExport={false}
                                    />
                                </>
                            ) : (
                                <>
                                    <div className=" w-full py-[4.5px] border-b flex justify-between items-center">
                                        <div className=" themes-text-gray-v3 font-light text-[12px]">
                                            <div className="font-bold">
                                                {t('performance.coast_per_unit')}
                                            </div>
                                            <div>{t('performance.monthly')}</div>
                                        </div>
                                        <ItemListInfo
                                            itemListInfo={itemListInfoBarChart}
                                        />
                                    </div>
                                    <SimpleLineChart
                                        data={dataSimpleLine}
                                        colorPV={'#266EDC'}
                                        fillPV={'#266FDC62'}
                                    />
                                </>
                            )}
                        </CardsDashboard>
                    </div>
                </>
            )}
        </div>
    )
}

export default Performances
