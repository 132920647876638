/* eslint-disable no-unused-vars */
import { useState } from 'react'
import Select, { StylesConfig } from 'react-select'
import { IDropdown, IDropdownItem } from './dropdown.interface'
import './dropdown.style.css'
import AsyncSelect from 'react-select/async'

function Dropdown<T>({
    isLoading = false,
    isClearable = false,
    isSearchable = false,
    isMultiSelect = false,
    required = false,
    disabled = false,
    useBorder = true,
    isAsync = false,
    disabledVariant = 'gray',
    options,
    useUppercaseLabel,
    ...props
}: IDropdown<T>) {
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [focus, setFocus] = useState(false)
    const showLabelClass = !props.label || props.label === '' ? 'hidden' : ''
    const isRequired = required ? 'required' : ''
    const labelFocus = focus ? 'themes-text-brand-v5' : ''
    const borderFocus = focus
        ? `themes-border-brand-v5 `
        : `${props.parentDivClassname}`
    const borderDropdown = useBorder ? 'border' : 'border-none'

    // isloading
    if (isLoading) {
        options = [{ value: 'loading', label: 'Loading Data' }]
    }

    const customStyles: StylesConfig<IDropdownItem<T>> = {
        control: (base) => ({
            ...base,
            border: 'none',
            boxShadow: 'none',
            '&:hover': {
                border: 'none',
            },
            backgroundColor:
                disabled && disabledVariant === 'gray'
                    ? 'var(--loglines-gray-v1)'
                    : 'var(--loglines-white)',
            color:
                disabled && disabledVariant === 'gray'
                    ? 'var(--loglines-gray-v6)'
                    : 'var(--loglines-gray-v5)',
        }),
        placeholder: (base) => ({
            ...base,
            color: 'var(--loglines-gray-v4)',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
        }),
        menuPortal: (base) => ({
            ...base,
            zIndex: '99',
        }),
        menuList: (base) => ({
            ...base,
            '::-webkit-scrollbar': {
                width: '12px',
                height: '15px',
                marginBottom: '50px',
            },
            '::-webkit-scrollbar-track': {
                backgroundColor: 'transparent',
            },
            '::-webkit-scrollbar-thumb': {
                backgroundColor: 'var(--loglines-gray-v2)',
                border: '3px',
                borderStyle: 'solid',
                borderColor: 'transparent',
                borderRadius: '9px',
                backgroundClip: 'content-box',
            },
            '::-webkit-scrollbar-thumb:hover': {
                backgroundColor: 'var(--loglines-gray-v3)',
                borderRadius: '15px',
            },
            marginTop: useBorder ? '' : '-16px',
            backgroundColor: 'var(--loglines-white)',
            fontSize: '14px',
            lineHeight: '20px',
            borderRadius: '5px',
            border: '1px',
            borderColor: 'var(--loglines-brand-v5)',
            borderStyle: 'solid',
            position: 'relative',
        }),
        dropdownIndicator: (base, state) => ({
            ...base,
            transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : '',
            transition: 'transform 0.3s ease-in-out',
        }),
        clearIndicator: (base) => ({
            ...base,
            cursor: 'pointer',
        }),
        option: (base, { isSelected, isDisabled }) => ({
            ...base,
            textAlign: isLoading ? 'center' : 'left',
            backgroundColor: isSelected
                ? 'var(--loglines-brand-v2)'
                : 'inherit',
            '&:hover': {
                backgroundColor: 'var(--loglines-gray-v1)',
            },
            color: isDisabled
                ? 'var(--loglines-gray-v2)'
                : 'var(--loglines-gray-v6)',
            position: 'relative',
            cursor: 'pointer',
            paddingLeft: '8px',
            paddingTop: '4px',
            paddingBottom: '4px',
        }),
        multiValue: (base) => ({
            ...base,
            backgroundColor: '#BEEDFF',
            marginLeft: '4px',
            marginTop: '4px',
            marginBottom: '4px',
            color: '#0075A1',
            fontSize: '14px',
            borderRadius: '5px',
        }),
        multiValueRemove: (base) => ({
            ...base,
            borderRadius: '5px',
            '&:hover': {
                backgroundColor: 'unset',
                color: 'unset',
            },
        }),
        singleValue: (base) => ({
            ...base,
            color: 'var(--loglines-black)',
        }),
    }

    // comment because need discussion with dewa and vioni
    // const customSingleLabel = (
    //     props: SingleValueProps<
    //         IDropdownItem<T>,
    //         false,
    //         GroupBase<IDropdownItem<T>>
    //     >,
    // ) => {
    //     const { data } = props
    //     const [mainText, subText] = data.label.split('- ')
    //     return (
    //         <components.SingleValue {...props}>
    //             <div>
    //                 {mainText},{' '}
    //                 <span className="themes-text-gray-v5">{subText}</span>
    //             </div>
    //         </components.SingleValue>
    //     )
    // }

    return (
        <div className={`dropdownParent-style`}>
            <label className={`${showLabelClass}`}>
                <div className="dropdownLabelDiv-style">
                    <p className={`${isRequired} ${labelFocus}  `}>
                        {useUppercaseLabel ? props.label?.toUpperCase() : props.label}
                    </p>
                </div>
            </label>

            <div className={`${borderFocus} ${borderDropdown} dropDown`}>
                {!isAsync ? (
                    <Select
                        className={`${props.className}`}
                        classNamePrefix={'select'}
                        components={{
                            // ...(useCustomSingleLabel && {
                            //     SingleValue: customSingleLabel,
                            // }),
                            ...(props?.customOptionComponent && {
                                Option: props?.customOptionComponent,
                            }),
                        }}
                        closeMenuOnSelect={
                            isMultiSelect === true ? false : true
                        }
                        defaultValue={props?.defaultValue}
                        placeholder={props.placeholder}
                        value={props?.value}
                        isMulti={!isMultiSelect && false}
                        isDisabled={disabled}
                        isLoading={isLoading}
                        isClearable={isClearable}
                        isSearchable={isSearchable}
                        options={options}
                        isOptionDisabled={(options) =>
                            options?.value === 'loading'
                        }
                        menuPosition="fixed"
                        menuIsOpen={isOpen}
                        onMenuOpen={() => setIsOpen(true)}
                        onMenuClose={() => setIsOpen(false)}
                        maxMenuHeight={115}
                        onFocus={() => {
                            setFocus(true)
                        }}
                        onBlur={() => {
                            setFocus(false)
                        }}
                        onChange={(e: IDropdownItem<T> | null | any) => {
                            if (props.onClick) props.onClick(e)
                            if (props.additionalDataHandling)
                                props.additionalDataHandling(
                                    e?.additionalData ?? null,
                                )
                        }}
                        onInputChange={(value) => {
                            if (props.onInputChange)
                                return props.onInputChange(value)
                        }}
                        filterOption={(option, inputValue) => {
                            if (isLoading) {
                                return true
                            }
                            return option.label
                                .toUpperCase()
                                .includes(inputValue.toUpperCase())
                        }}
                        unstyled={false}
                        styles={customStyles}
                        theme={(theme) => ({
                            ...theme,
                            borderRadius: 4,
                        })}
                    />
                ) : (
                    <AsyncSelect
                        loadOptions={props?.loadOptions && props?.loadOptions}
                        className={`${props.className}`}
                        classNamePrefix={'select'}
                        // comment because there are still bugs
                        // components={animatedComponents}
                        closeMenuOnSelect={
                            isMultiSelect === true ? false : true
                        }
                        placeholder={props.placeholder}
                        value={props?.value}
                        isMulti={isMultiSelect}
                        isDisabled={disabled}
                        isLoading={isLoading}
                        isClearable={isClearable}
                        isSearchable={isSearchable}
                        options={options}
                        isOptionDisabled={(options) =>
                            options?.value === 'loading'
                        }
                        menuPosition="fixed"
                        menuIsOpen={isOpen}
                        onMenuOpen={() => setIsOpen(true)}
                        onMenuClose={() => setIsOpen(false)}
                        maxMenuHeight={115}
                        onFocus={() => {
                            setFocus(true)
                        }}
                        onBlur={() => {
                            setFocus(false)
                        }}
                        onChange={props?.onChange && props.onChange}
                        defaultOptions={props?.defaultValue}
                        unstyled={false}
                        styles={customStyles}
                        theme={(theme) => ({
                            ...theme,
                            borderRadius: 4,
                        })}
                    />
                )}
            </div>
        </div>
    )
}
export default Dropdown
