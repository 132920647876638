import { IResponseData } from "common/common.interface";
import { fetch } from "common/common.service"
import { endpoints } from "common/endpoints"
// import { Toast } from "components/toast/toast.component";
import { INewsData, IScfiData } from "pages/ifb/dashboard/dashboard.interface";


export const fetchNewsData = async () => {
    try {
        const response: IResponseData<INewsData[]> | undefined = await fetch({
            endpoint: endpoints.ifb.news_scrapper,
        });

        if (!response) {
            return []
        }

        if (response.isSuccess) {
            return response.data
        } else {
            // Toast({
            //     header: 'Error',
            //     message: 'Failed to fetch news data.',
            //     type: 'error',
            // })
            return [];
        }
    } catch (error: unknown) {
        if (error instanceof Error) {
            // Toast({
            //     header: 'News',
            //     message: `${error.message}`,
            //     type: 'error',
            // })
        } else {
            // Toast({
            //     header: 'News',
            //     message: `Unknown error occurred while fetching news data."`,
            //     type: 'error',
            // })
        }
        return [];
    }
};

export const fetchScfiData = async () => {
    try {
        const response: IResponseData<IScfiData[]> | undefined = await fetch({
            endpoint: endpoints.ifb.scfi_index,
        });

        if (!response) {
            return []
        }
        if (response.isSuccess) {
            return response.data
        } else {
            // Toast({
            //     header: 'SCFI',
            //     message: `Failed to fetch SCFI data.`,
            //     type: 'error',
            // })
            return [];
        }
    } catch (error: unknown) {
        if (error instanceof Error) {
            // Toast({
            //     header: 'SCFI data',
            //     message: `${error.message}`,
            //     type: 'error',
            // })
        } else {
            // Toast({
            //     header: 'SCFI data',
            //     message: `Unknown error occurred while fetching SCFI data."`,
            //     type: 'error',
            // })
        }
        return [];
    }
};
