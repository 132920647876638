import { IMeta } from 'common/common.interface'
import { IInventory } from './inventory.interface'

export const InventoryDataDummy: IInventory[] = [
    {
        warehouseName: 'HarborView Warehouse',
        partNumber: 'PN-001',
        partDescription: 'Widget Assembly',
        totalUnits: '100 units',
        inventoryStatus: 'In Stock',
        availableUnits: '50',
        committedUnits: '20',
        reservedUnits: '15',
        unitUQ: 'EA',
    },
    {
        warehouseName: 'Summit Storage Solutions',
        partNumber: 'PN-002',
        partDescription: 'Gearbox Unit',
        totalUnits: '250 units',
        inventoryStatus: 'In Stock',
        availableUnits: '100',
        committedUnits: '50',
        reservedUnits: '30',
        unitUQ: 'EA',
    },
    {
        warehouseName: 'Riverside Logistics Center',
        partNumber: 'PN-003',
        partDescription: 'Electronic Circuit Board',
        totalUnits: '500 units',
        inventoryStatus: 'In Stock',
        availableUnits: '200',
        committedUnits: '100',
        reservedUnits: '60',
        unitUQ: 'EA',
    },
    {
        warehouseName: 'MetroWare Warehouse',
        partNumber: 'PN-004',
        partDescription: 'Stainless Steel Shaft',
        totalUnits: '75 units',
        inventoryStatus: 'In Stock',
        availableUnits: '25',
        committedUnits: '10',
        reservedUnits: '25',
        unitUQ: 'EA',
    },
    {
        warehouseName: 'Valley Vista Warehouse',
        partNumber: 'PN-005',
        partDescription: 'Rubber Gasket Set',
        totalUnits: '200 units',
        inventoryStatus: 'On Order',
        availableUnits: '150',
        committedUnits: '30',
        reservedUnits: '40',
        unitUQ: 'EA',
    },
    {
        warehouseName: 'Coastal Cargo Warehouse',
        partNumber: 'PN-006',
        partDescription: 'Aluminum Frame',
        totalUnits: '150 units',
        inventoryStatus: 'On Order',
        availableUnits: '75',
        committedUnits: '70',
        reservedUnits: '50',
        unitUQ: 'EA',
    },
    {
        warehouseName: 'Prime Storage Facility',
        partNumber: 'PN-007',
        partDescription: 'Plastic Enclosure',
        totalUnits: '300 units',
        inventoryStatus: 'On Order',
        availableUnits: '300',
        committedUnits: '200',
        reservedUnits: '70',
        unitUQ: 'EA',
    },
    {
        warehouseName: 'UrbanHub Distribution Center',
        partNumber: 'PN-008',
        partDescription: 'Glass Display Panel',
        totalUnits: '400 units',
        inventoryStatus: 'On Order',
        availableUnits: '500',
        committedUnits: '150',
        reservedUnits: '20',
        unitUQ: 'EA',
    },
    {
        warehouseName: 'Skyline Storage Depot',
        partNumber: 'PN-009',
        partDescription: 'Copper Wire Bundle',
        totalUnits: '50 units',
        inventoryStatus: 'Out of Stock',
        availableUnits: '10',
        committedUnits: '5',
        reservedUnits: '35',
        unitUQ: 'EA',
    },
    {
        warehouseName: 'Pioneer Warehouse Solutions',
        partNumber: 'PN-010',
        partDescription: 'Nylon Fasteners Kit',
        totalUnits: '350 units',
        inventoryStatus: 'Out of Stock',
        availableUnits: '350',
        committedUnits: '80',
        reservedUnits: '45',
        unitUQ: 'EA',
    },
    {
        warehouseName: 'Evergreen Logistics Hub',
        partNumber: 'PN-011',
        partDescription: 'Carbon Fiber Component',
        totalUnits: '600 units',
        inventoryStatus: 'Out of Stock',
        availableUnits: '30',
        committedUnits: '25',
        reservedUnits: '55',
        unitUQ: 'EA',
    },
    {
        warehouseName: 'Horizon Storage Services',
        partNumber: 'PN-012',
        partDescription: 'Ceramic Heating Element',
        totalUnits: '125 units',
        inventoryStatus: 'Out of Stock',
        availableUnits: '80',
        committedUnits: '40',
        reservedUnits: '65',
        unitUQ: 'EA',
    },
    {
        warehouseName: 'Unity Warehouse Complex',
        partNumber: 'PN-013',
        partDescription: 'LED Light Module',
        totalUnits: '175 units',
        inventoryStatus: 'Others',
        availableUnits: '120',
        committedUnits: '120',
        reservedUnits: '80',
        unitUQ: 'EA',
    },
    {
        warehouseName: 'SwiftStock Warehousing Solutions',
        partNumber: 'PN-014',
        partDescription: 'LCD Screen',
        totalUnits: '100 units',
        inventoryStatus: 'Others',
        availableUnits: '50',
        committedUnits: '20',
        reservedUnits: '30',
        unitUQ: 'EA',
    },
    {
        warehouseName: 'PeakLogix Distribution Center',
        partNumber: 'PN-015',
        partDescription: 'OLED Display',
        totalUnits: '250 units',
        inventoryStatus: 'Others',
        availableUnits: '100',
        committedUnits: '50',
        reservedUnits: '30',
        unitUQ: 'EA',
    },
]

export const metaDummy: IMeta = {
    current_page: 1,
    last_page: 1,
    from: 1,
    to: 50,
    total_Items: 15,
    per_page: 0,
    total_page: 1,
    index_end: 15,
    index_start: 1,
}
