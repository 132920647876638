/* eslint-disable no-unused-vars */
import { useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { IListItem } from './list.component'
import Button from 'components/button/button.component'

const DraggabbleList = ({
    item,
    index,
    moveItem,
    moveItemUp,
    moveItemDown,
    itemLength,
}: {
    item: IListItem
    index: number
    moveItem: (dragIndex: number, hoverIndex: number) => void
    moveItemUp: (index: number) => void
    moveItemDown: (index: number) => void
    itemLength: number
}) => {
    const ItemTypes = {
        ITEM: 'item',
    }

    const ref = useRef<HTMLDivElement>(null)
    const [{ isDragging }, drag] = useDrag({
        type: ItemTypes.ITEM,
        item: { type: ItemTypes.ITEM, index },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    })

    const [, drop] = useDrop({
        accept: ItemTypes.ITEM,
        hover(
            draggedItem: { type: string; id: number; index: number },
            monitor,
        ) {
            if (!ref.current) {
                return
            }
            const dragIndex = draggedItem.index
            const hoverIndex = index

            if (dragIndex === hoverIndex) {
                return
            }

            const hoverBoundingRect = ref.current.getBoundingClientRect()
            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
            const clientOffset = monitor.getClientOffset()

            if (clientOffset) {
                const hoverClientY = clientOffset.y - hoverBoundingRect.top

                if (
                    (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) ||
                    (dragIndex > hoverIndex && hoverClientY > hoverMiddleY)
                ) {
                    return
                }

                moveItem(dragIndex, hoverIndex)
                draggedItem.index = hoverIndex // Necessary to update the state locally
            }
        },
    })

    drag(drop(ref))

    return (
        <div
            key={index}
            ref={ref}
            className="p-3 border-b cursor-pointer flex justify-between"
            style={{ opacity: isDragging ? 0.5 : 1 }}
        >
            <div className="flex gap-3 items-center">
                <i className="ri-draggable"></i>
                {item.content}
            </div>
            <div className="flex gap-2">
                <Button
                    onClick={() => {
                        moveItemUp(index)
                    }}
                    icon="ri-arrow-drop-up-fill text-size-XL"
                    className="w-btnIconWidth"
                    isDisabled={index === 0}
                />
                <Button
                    onClick={() => {
                        moveItemDown(index)
                    }}
                    icon="ri-arrow-drop-down-fill text-size-XL"
                    className="w-btnIconWidth"
                    isDisabled={index === itemLength - 1}
                />
            </div>
        </div>
    )
}

export default DraggabbleList
