import { IMeta } from 'common/common.interface'
import { IDeclaration } from './declarations.interface'

export const DeclarationDataDummy: IDeclaration[] = [
    {
        declarationNo: 'D00001011',
        branchCode: 'MEL - Melbourne',
        shipmentType: 'EXP',
        transportMode: 'SEA',
        vessel: 'Ocean Voyager',
        voyageOrFlightNo: 'VOY-1001',
        dateOfArrival: '25-Aug-23 00.00',
        origin: 'JPOSA - Osaka',
        finalDestination: 'CNSHK - Shekou Pt',
        houseBill: 'HR 1234',
        status: 'released',
    },
    {
        declarationNo: 'D00001012',
        branchCode: 'MEL - Melbourne',
        shipmentType: 'EXP',
        transportMode: 'AIR',
        vessel: 'Sea Serenity',
        voyageOrFlightNo: 'SER-2002',
        dateOfArrival: '25-Aug-23 00.00',
        origin: 'JPUKB - Kobe',
        finalDestination: 'CNSHK - Shekou Pt',
        houseBill: 'H.R. 5678',
        status: 'released',
    },
    {
        declarationNo: 'D00001013',
        branchCode: 'BRI - Brisbane',
        shipmentType: 'EXP',
        transportMode: 'AIR',
        vessel: 'Pacific Dreamer',
        voyageOrFlightNo: 'DRE-3003',
        dateOfArrival: '25-Aug-23 00.00',
        origin: 'JPOSA - Osaka',
        finalDestination: 'CNSHK - Shekou Pt',
        houseBill: 'H.R. 9012',
        status: 'released',
    },
    {
        declarationNo: 'D00001014',
        branchCode: 'BRI - Brisbane',
        shipmentType: 'EXP',
        transportMode: 'SEA',
        vessel: 'Atlantic Spirit',
        voyageOrFlightNo: 'SPI-4004',
        dateOfArrival: '25-Aug-23 00.00',
        origin: 'MYJHB - Johor Bahru',
        finalDestination: 'CNSHK - Shekou Pt',
        houseBill: 'HR 3456',
        status: 'released',
    },
    {
        declarationNo: 'D00001015',
        branchCode: 'MEL - Melbourne',
        shipmentType: 'EXP',
        transportMode: 'SEA',
        vessel: 'Coral Explorer',
        voyageOrFlightNo: 'EXP-5005',
        dateOfArrival: '25-Aug-23 00.00',
        origin: 'JPTYO - Tokyo',
        finalDestination: 'CNSHK - Shekou Pt',
        houseBill: 'H.R. 7890',
        status: 'released',
    },
    {
        declarationNo: 'D00001016',
        branchCode: 'BRI - Brisbane',
        shipmentType: 'EXP',
        transportMode: 'SEA',
        vessel: 'Maritime Majesty',
        voyageOrFlightNo: 'MAJ-6006',
        dateOfArrival: '19-Aug-23 00.00',
        origin: 'THBKK - Bangkok',
        finalDestination: 'CNSHK - Shekou Pt',
        houseBill: 'HR 123',
        status: 'released',
    },
    {
        declarationNo: 'D00001017',
        branchCode: 'MEL - Melbourne',
        shipmentType: 'EXP',
        transportMode: 'AIR',
        vessel: 'Voyager II',
        voyageOrFlightNo: 'VGR-7007',
        dateOfArrival: '19-Aug-23 00.00',
        origin: 'THBKK - Bangkok',
        finalDestination: 'CNSHK - Shekou Pt',
        houseBill: 'H.R. 456',
        status: 'hold',
    },
    {
        declarationNo: 'D00001018',
        branchCode: 'MEL - Melbourne',
        shipmentType: 'EXP',
        transportMode: 'AIR',
        vessel: 'Azure Horizon',
        voyageOrFlightNo: 'HZN-8008',
        dateOfArrival: '19-Aug-23 00.00',
        origin: 'THNAK - Nakhon Ratchasima Apt',
        finalDestination: 'CNSHK - Shekou Pt',
        houseBill: 'HR 789',
        status: 'hold',
    },
    {
        declarationNo: 'D00001019',
        branchCode: 'MEL - Melbourne',
        shipmentType: 'EXP',
        transportMode: 'AIR',
        vessel: 'Celestial Voyager',
        voyageOrFlightNo: 'CVG-9009',
        dateOfArrival: '19-Aug-23 00.00',
        origin: 'IDTJP - Tanjung Pinang',
        finalDestination: 'CNSHK - Shekou Pt',
        houseBill: 'H.R. 012',
        status: 'hold',
    },
    {
        declarationNo: 'D00001020',
        branchCode: 'BRI - Brisbane',
        shipmentType: 'EXP',
        transportMode: 'SEA',
        vessel: 'Coastal Wanderer',
        voyageOrFlightNo: 'WDR-1010',
        dateOfArrival: '19-Aug-23 00.00',
        origin: 'JPTYO - Tokyo',
        finalDestination: 'CNSHK - Shekou Pt',
        houseBill: 'HR 345',
        status: 'hold',
    },
    {
        declarationNo: 'D00001021',
        branchCode: 'MEL - Melbourne',
        shipmentType: 'EXP',
        transportMode: 'SEA',
        vessel: 'Nautical Odyssey',
        voyageOrFlightNo: 'ODS-1111',
        dateOfArrival: '16-Aug-23 00.00',
        origin: 'JPUKB - Kobe',
        finalDestination: 'CNSHK - Shekou Pt',
        houseBill: 'H.R. 678',
        status: 'hold',
    },
    {
        declarationNo: 'D00001022',
        branchCode: 'MEL - Melbourne',
        shipmentType: 'EXP',
        transportMode: 'SEA',
        vessel: 'Aqua Venture',
        voyageOrFlightNo: 'VNT-1212',
        dateOfArrival: '16-Aug-23 00.00',
        origin: 'JPOSA - Osaka',
        finalDestination: 'CNSHK - Shekou Pt',
        houseBill: 'HR 901',
        status: 'hold',
    },
    {
        declarationNo: 'D00001023',
        branchCode: 'BRI - Brisbane',
        shipmentType: 'EXP',
        transportMode: 'SEA',
        vessel: 'Island Navigator',
        voyageOrFlightNo: 'MTR-1313',
        dateOfArrival: '16-Aug-23 00.00',
        origin: 'JPUKB - Kobe',
        finalDestination: 'CNSHK - Shekou Pt',
        houseBill: 'H.R. 234',
        status: 'canceled',
    },
    {
        declarationNo: 'D00001024',
        branchCode: 'BRI - Brisbane',
        shipmentType: 'EXP',
        transportMode: 'AIR',
        vessel: 'Harbor Master',
        voyageOrFlightNo: 'HBM-1414',
        dateOfArrival: '16-Aug-23 00.00',
        origin: 'JPTYO - Tokyo',
        finalDestination: 'CNSHK - Shekou Pt',
        houseBill: 'HR 567',
        status: 'canceled',
    },
    {
        declarationNo: 'D00001025',
        branchCode: 'MEL - Melbourne',
        shipmentType: 'EXP',
        transportMode: 'AIR',
        vessel: 'Maritime Legacy',
        voyageOrFlightNo: 'LGC-1515',
        dateOfArrival: '16-Aug-23 00.00',
        origin: 'JPOSA - Osaka',
        finalDestination: 'CNSHK - Shekou Pt',
        houseBill: 'H.R. 890',
        status: 'canceled',
    },
]

export const metaDummy: IMeta = {
    current_page: 1,
    last_page: 1,
    from: 1,
    to: 50,
    total_Items: 15,
    per_page: 0,
    total_page: 1,
    index_end: 15,
    index_start: 1,
}
