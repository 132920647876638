import { IResponseData } from 'common/common.interface'
import {
    fetch,
    post,
    put,
    fecthBlob,
    deleteRequest,
} from 'common/common.service'
import {
    IGetShipmentFilterDropdownData,
    IGetShipmentFilterDropdownDataParams,
    IShipment,
    IShipmentsFilter,
} from 'pages/jpl/shipments/shipments.interface'
import {
    IInvoiceNumberValidation,
    IRouteCodeValidation,
} from 'form-validation/jpl/shipment-detail.validation'
import { Toast } from 'components/toast/toast.component'
import { IRouteCode } from '../common/interface/route-code.interface'
import { endpoints } from 'common/endpoints'

export const getShipmentsData = async (filter: IShipmentsFilter) => {
    try {
        const response = await fetch<
            IResponseData<IShipment[]>,
            IShipmentsFilter
        >({
            endpoint: endpoints.jpl.shipment_getAll,
            params: filter,
        })
        return response
    } catch (error: any) {
        console.error(error)
        const err = error as IResponseData<IShipment[]>
        err.isSuccess = false
        return err
    }
}

export const getShipmentDetails = async (shipmentId: string) => {
    try {
        const response = await fetch<IResponseData<IShipment>, string>({
            endpoint: `${endpoints.jpl.shipment_getAll}/${shipmentId}`,
        })
        return response
    } catch (error: any) {
        console.error(error)
        const err = error as IResponseData<IShipment>
        err.isSuccess = false
        return err
    }
}

export const getShipmentFilterDropdownData = async (
    params: IGetShipmentFilterDropdownDataParams | null,
) => {
    try {
        const response = await fetch<
            IResponseData<IGetShipmentFilterDropdownData>,
            IGetShipmentFilterDropdownDataParams | null
        >({
            endpoint: endpoints.jpl.shipment_getFilterDropdownOptions,
            params,
        })
        return response
    } catch (error: any) {
        console.error(error)
        const err = error as IResponseData<IGetShipmentFilterDropdownData>
        err.isSuccess = false
        return err
    }
}

export const updateInvoiceNo = async (props: IInvoiceNumberValidation) => {
    const url: string = `${endpoints.jpl.shipment_updateInvoice}?invoiceNo=${props.invoiceNo}&shipmentNo=${props.shipmentNo}`
    try {
        await put<IResponseData<any>, IInvoiceNumberValidation>({
            endpoint: url,
            payload: props,
        })
        Toast({
            header: 'Success',
            message: 'Success update invoice',
            type: 'success',
        })
    } catch (error: any) {
        console.error(error)
        Toast({
            header: 'Failed!',
            message: 'Failed Update invoice',
            type: 'error',
        })
    }
}

export const getRouteCode = async () => {
    try {
        const response = await fetch<IResponseData<IRouteCode[]>, any>({
            endpoint: endpoints.jpl.po_panRoute,
        })
        return response
    } catch (error: any) {
        console.error(error)
        Toast({
            header: 'Failed!',
            message: 'Failed get list of route code',
            type: 'error',
        })
    }
}

export const updateStatusShipment = async (
    shipmentId: string,
    payload: string,
) => {
    try {
        const response = await put<IResponseData<any>, string>({
            endpoint: endpoints.jpl.shipment_updateStatuShipment + shipmentId,
            payload,
        })
        return response
    } catch (error: any) {
        console.error(error)
        const err = error as IResponseData<IGetShipmentFilterDropdownData>
        err.isSuccess = false
        Toast({
            header: 'Failed!',
            message: 'Failed get list of route code',
            type: 'error',
        })
        return err
    }
}

export const updateRouteCode = async (props: IRouteCodeValidation) => {
    const url: string = `${endpoints.jpl.shipment_updateRouteCode}?routeCode=${props.routeCode}&shipmentNo=${props.shipmentNo}`
    try {
        await put<IResponseData<any>, IRouteCodeValidation>({
            endpoint: url,
            payload: props,
        })
        Toast({
            header: 'Success',
            message: 'Success update route code',
            type: 'success',
        })
    } catch (error: any) {
        console.error(error)
        Toast({
            header: 'Failed!',
            message: 'Failed Update Route Code',
            type: 'error',
        })
    }
}

export const uploadEdocs = async (
    params: {
        shipmentNo: string
        documentType: string
    },
    formData: FormData,
) => {
    try {
        await post<IResponseData<any>, any>({
            endpoint: endpoints.jpl.shipment_uploadEDocs,
            params: params,
            payload: formData,
            isFormData: true,
        })
        Toast({
            header: 'EDOCUMENT ADDED',
            message: 'Success updload eDoc',
            type: 'success',
        })
    } catch (error: any) {
        console.error(error)
        console.log(error)
        Toast({
            header: 'Failed!',
            message: 'Failed updload eDoc',
            type: 'error',
        })
    }
}

export const downloadEdocs = async (fileName: string, id: string) => {
    const params = { objectName: fileName, shipmentNo: id }
    try {
        const response = await fecthBlob<any, { objectName: string }>({
            endpoint: endpoints.jpl.shipment_downloadEDocs,
            params,
        })
        // Create a blob from the response data
        const blob = new Blob([response.data], {
            type: response.headers['content-type'],
        })

        // Create a temporary URL for the blob
        const url = window.URL.createObjectURL(blob)

        // Create a temporary link element
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', fileName) // Set the filename
        document.body.appendChild(link)

        // Trigger the click event to download the file
        link.click()

        // Clean up resources
        window.URL.revokeObjectURL(url)
        document.body.removeChild(link)
        Toast({
            header: 'Success',
            message: 'Success download eDoc',
            type: 'success',
        })
    } catch (error: any) {
        console.error(error)
        Toast({
            header: 'Failed!',
            message: 'Failed download eDoc',
            type: 'error',
        })
    }
}

export const deleteEdocs = async ({
    id,
    objectName,
    shipmentNo,
}: {
    id: string
    objectName: string
    shipmentNo: string
}) => {
    try {
        await deleteRequest<
            IResponseData<any>,
            { objectName: string; shipmentNo: string }
        >({
            endpoint: `${endpoints.jpl.shipment_deleteEDocs}${id}`,
            params: { objectName, shipmentNo },
        })
        Toast({
            header: 'EDOCUMENT DELETED',
            message: 'The eDoc has been successfully deleted.',
            type: 'success',
        })
    } catch (error: any) {
        console.error(error)
        console.log(error)
        Toast({
            header: 'Failed!',
            message: 'Failed Delete eDoc',
            type: 'error',
        })
    }
}
