import { ITabItem } from 'components/tab/tab.interface'
import { ITableColumn } from 'components/table/table.interface'
import { DeclarationDataDummy } from './declarations.dummy'
import { IDeclaration } from './declarations.interface'
import { IConfigureColumns } from 'components/configure-columns-modal/configure-column-modal.interface'

export const DeclarationStatusHeader: ITabItem[] = [
    {
        label: 'All Declarations',
        labelTranslationCode: 'status.all_declarations',
        totalData: DeclarationDataDummy.length,
        key: 'status',
        value: 'allCustomsDeclaration',
        className: 'brand',
    },
    {
        label: 'Released',
        labelTranslationCode: 'status.released',
        totalData: DeclarationDataDummy.filter((x) => x.status === 'released')
            .length,
        key: 'status',
        value: 'SEA',
        className: 'green',
    },
    {
        label: 'Hold',
        labelTranslationCode: 'status.hold',
        totalData: DeclarationDataDummy.filter((x) => x.status === 'hold')
            .length,
        key: 'status',
        value: 'AIR',
        className: 'yellow',
    },
    {
        label: 'Canceled',
        labelTranslationCode: 'status.canceled',
        totalData: DeclarationDataDummy.filter((x) => x.status === 'canceled')
            .length,
        key: 'status',
        value: 'LAND',
        className: 'red',
    },
    {
        label: 'Others',
        labelTranslationCode: 'status.others',
        totalData: DeclarationDataDummy.filter(
            (x: any) => x.transportMode === 'Others',
        ).length,
        key: 'status',
        value: 'Others',
        className: 'gray',
    },
]

export const DeclarationSummaryHeader: ITableColumn[] = [
    {
        label: 'Declaration No.',
        accessor: 'declarationNo',
    },
    {
        label: 'Branch Code',
        accessor: 'branchCode',
    },
    {
        label: 'Shipment Type',
        accessor: 'shipmentType',
    },
    {
        label: 'Transport Mode',
        accessor: 'transportMode',
    },
    {
        label: 'Vessel',
        accessor: 'vessel',
    },
    {
        label: 'Voyage/Flight No.',
        accessor: 'voyageOrFlightNo',
    },
    {
        label: 'Date of Arrival',
        accessor: 'dateOfArrival',
    },
    {
        label: 'Origin',
        accessor: 'origin',
    },
    {
        label: 'Final Destination',
        accessor: 'finalDestination',
    },
    {
        label: 'House Bill',
        accessor: 'houseBill',
    },
]

export const DeclarationConfigureColumns: IConfigureColumns<IDeclaration>[] = [
    {
        title: 'Flags',
        columns: [
            {
                statusColumn: 'selectedColumns',
                label: 'Declaration No.',
                labelTranslation: 'declaration.declaration_no',
                accessor: 'declarationNo',
            },
            {
                statusColumn: 'selectedColumns',
                label: 'Branch Code',
                labelTranslation: 'declaration.branch_code',
                accessor: 'branchCode',
            },
            {
                statusColumn: 'selectedColumns',
                label: 'Shipment Type',
                labelTranslation: 'declaration.shipment_type',
                accessor: 'shipmentType',
            },
            {
                statusColumn: 'selectedColumns',
                label: 'Transport Mode',
                labelTranslation: 'declaration.transport_mode',
                accessor: 'transportMode',
            },
            {
                statusColumn: 'selectedColumns',
                label: 'Vessel',
                labelTranslation: 'declaration.vessel',
                accessor: 'vessel',
            },
            {
                statusColumn: 'selectedColumns',
                label: 'Voyage/Flight No.',
                labelTranslation: 'declaration.voyage_or_flight_no',
                accessor: 'voyageOrFlightNo',
            },
            {
                statusColumn: 'selectedColumns',
                label: 'Date of Arrival',
                labelTranslation: 'declaration.date_of_arrival',
                accessor: 'dateOfArrival',
            },
            {
                statusColumn: 'selectedColumns',
                label: 'Origin',
                labelTranslation: 'declaration.origin',
                accessor: 'origin',
            },
            {
                statusColumn: 'selectedColumns',
                label: 'Final Destination',
                labelTranslation: 'declaration.final_destination',
                accessor: 'finalDestination',
            },
            {
                statusColumn: 'selectedColumns',
                label: 'House Bill',
                labelTranslation: 'declaration.house_bill',
                accessor: 'houseBill',
            },
        ],
    },
]
