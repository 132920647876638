/* eslint-disable no-unused-vars */
import {
    pillsShippingDetailsHeader,
    pillSAddressesDetailsHeader,
} from '../shipments-details.static'
import Pills, { IPillItem } from 'components/pills/pills.component'
import {
    IPillSAddressesDetailsHeader,
    IPillsShippingDetailsHeader,
} from '../shipments-detail.interface'

interface IDetails {
    setPillShippingDetails: (
        data: IPillItem<IPillsShippingDetailsHeader>,
    ) => void
    setPillAddressesDetails: (
        data: IPillItem<IPillSAddressesDetailsHeader>,
    ) => void
    getContentPillShippingDetails: JSX.Element
    getContentPillAddressesDetails: JSX.Element
}

const Details = ({
    setPillShippingDetails,
    setPillAddressesDetails,
    getContentPillShippingDetails,
    getContentPillAddressesDetails,
}: IDetails) => {
    return (
        <>
            <div className="flex gap-2 sm:flex-col sm:overflow-auto">
                <div className="border border-solid themes-border-color rounded flex-1 overflow-auto z-[1]">
                    <div className="p-3 font-bold text-size-L">
                        Shipping Details
                    </div>
                    <div className="border-b border-solid themes-border-color"></div>
                    <Pills<IPillsShippingDetailsHeader>
                        items={pillsShippingDetailsHeader}
                        onChange={(e) => {
                            setPillShippingDetails(e)
                        }}
                    />
                    <div className="overflow-auto h-[calc(100vh-26.6rem)] sm:h-full sm pl-3">
                        {getContentPillShippingDetails}
                    </div>
                </div>
                <div className="border border-solid themes-border-color rounded flex-1">
                    <div className="p-3 font-bold text-size-L">
                        Addresses Details
                    </div>
                    <div className="border-b border-solid themes-border-color"></div>
                    <Pills<IPillSAddressesDetailsHeader>
                        items={pillSAddressesDetailsHeader}
                        onChange={(e) => {
                            setPillAddressesDetails(e)
                        }}
                    />
                    <div
                        className="overflow-auto h-[calc(100vh-26.6rem)] sm:h-full pl-3 flex flex-col gap-y-2"
                        style={{ lineHeight: 'normal' }}
                    >
                        {getContentPillAddressesDetails}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Details
