import Input from 'components/input/input.component'
import { useTranslation } from 'react-i18next'

const Details = () => {
    const { t } = useTranslation()
    return (
        <div className="grid grid-cols-4 gap-3 px-5 py-4">
            <div className="flex flex-col">
                <div className="text-[16px] themes-text-gray-v5">
                    {t('receipts.order_details')}
                </div>
                <div className="flex flex-col h-full gap-3 pt-2">
                    <Input
                        label={t('orders.warehouse_name')}
                        placeholder={t('additional.not_available')}
                        disabled
                    />
                    <Input
                        label={t('orders.required_date')}
                        placeholder={t('additional.not_available')}
                        disabled
                    />
                    <Input
                        label={t('receipts.customer_ref')}
                        placeholder={t('additional.not_available')}
                        disabled
                    />
                    <Input
                        label={t('receipts.total_units')}
                        placeholder={t('additional.not_available')}
                        disabled
                    />
                    <Input
                        label={t('orders.order_status')}
                        placeholder={t('additional.not_available')}
                        disabled
                    />
                    <Input
                        label={t('receipts.service_level')}
                        placeholder={t('additional.not_available')}
                        disabled
                    />
                    <Input
                        label={t('receipts.transport_company_name')}
                        placeholder={t('additional.not_available')}
                        disabled
                    />
                    <Input
                        label={t('receipts.transport_ref')}
                        placeholder={t('additional.not_available')}
                        disabled
                    />
                </div>
            </div>
            <div>
                <div className="text-[16px] themes-text-gray-v5">
                    {t('orders.consognee_address')}
                </div>
                <div className="flex flex-col gap-3 pt-2">
                    <Input
                        label={t('invoices.company_name')}
                        placeholder={t('additional.not_available')}
                        disabled
                    />
                    <Input
                        label={t('users.address_detail')}
                        placeholder={t('additional.not_available')}
                        disabled
                    />
                    <Input
                        label={t('receipts.additional_address')}
                        placeholder={t('additional.not_available')}
                        disabled
                    />
                    <Input
                        label={t('users.user_contact')}
                        placeholder={t('additional.not_available')}
                        disabled
                    />
                    <div className="grid grid-cols-2 gap-3">
                        <Input
                            label={t('bookings.contact_phone')}
                            placeholder={t('additional.not_available')}
                            disabled
                        />
                        <Input
                            label={t('bookings.contact_mobile')}
                            placeholder={t('additional.not_available')}
                            disabled
                        />
                    </div>
                    <Input
                        label={t('bookings.contact_email_address')}
                        placeholder={t('additional.not_available')}
                        disabled
                    />
                </div>
            </div>
            <div>
                <div className="text-[16px] themes-text-gray-v5">
                    {t('receipts.goods_billed_to_address')}
                </div>
                <div className="flex flex-col gap-3 pt-2">
                    <Input
                        label={t('invoices.company_name')}
                        placeholder={t('additional.not_available')}
                        disabled
                    />
                    <Input
                        label={t('bookings.address')}
                        placeholder={t('additional.not_available')}
                        disabled
                    />
                    <Input
                        label={t('receipts.additional_address')}
                        placeholder={t('additional.not_available')}
                        disabled
                    />
                    <Input
                        label={t('bookings.contact_name')}
                        placeholder={t('additional.not_available')}
                        disabled
                    />
                    <div className="grid grid-cols-2 gap-3">
                        <Input

                            label={t('bookings.contact_phone')}
                            placeholder={t('additional.not_available')}
                            disabled
                        />
                        <Input
                            label={t('bookings.contact_mobile')}
                            placeholder={t('additional.not_available')}
                            disabled
                        />
                    </div>
                    <Input
                        label={t('bookings.contact_email_address')}
                        placeholder={t('additional.not_available')}
                        disabled
                    />
                </div>
            </div>
        </div>
    )
}

export default Details
