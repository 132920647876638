/* eslint-disable no-unused-vars */
import { useState } from 'react'
import './tab.style.css'
import { ITab } from './tab.interface'
import { useTranslation } from 'react-i18next'

const Tab = ({
    items,
    selectedItem,
    onChange,
    tabFilter,
    containerClassName = '',
}: ITab) => {
    const { t } = useTranslation()
    const getSelectedIndex = items.findIndex(
        (x) => x.value === selectedItem?.value,
    )
    const [activeIndex, setActiveIndex] = useState(
        getSelectedIndex < 0 ? 0 : getSelectedIndex,
    )

    return (
        <div className={`${containerClassName} header-status`}>
            {items.map((dt, idx) => {
                const isActive = tabFilter
                    ? dt.value === tabFilter.value
                    : activeIndex === idx
                const classActive = isActive ? 'active' : ''
                const paddingTop = dt.totalData !== undefined ? 'pt-2' : ''
                const className = 'themes-status-' + (dt.className ?? 'gray')
                const label = dt.labelTranslationCode
                    ? t(dt.labelTranslationCode)
                    : dt.label
                return (
                    <div
                        className={`item ${paddingTop} ${className} ${classActive}`}
                        key={idx}
                        onClick={() => {
                            setActiveIndex(idx)
                            onChange(dt)
                        }}
                    >
                        {label}
                        {dt.totalData !== undefined && (
                            <span>{dt.totalData}</span>
                        )}
                        <br />
                        <div
                            className={
                                'bottom-line transition duration-300 pt-[3px] mt-2'
                            }
                        ></div>
                    </div>
                )
            })}
        </div>
    )
}

export default Tab
