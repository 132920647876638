import Tab from "components/tab/tab.component"

import Button from "components/button/button.component"
import { useNavigate } from "react-router-dom"
import { useOrdersDetails } from "./orders-details.service"
import { TabOrders } from "./orders-details.static"

const OrdersDetails = () => {
    const navigate = useNavigate()

    const {
        tabFilter,
        setTabFilter,
        getContentOrdersDetails
    } = useOrdersDetails()

    return (
        <div className="content-full-height w-full rounded-lg themes-border-color bg-white  border-2 py-2 flex flex-col justify-between">
            <div className="flex flex-col">
                <div className="px-3">
                    <Tab
                        containerClassName="px-3 pt-3 overflow-auto"
                        items={TabOrders ?? []}
                        tabFilter={tabFilter}
                        onChange={(item) => setTabFilter(item)}
                    />
                </div>
                {getContentOrdersDetails(tabFilter)}
            </div>

            <div className="px-2 pt-2">
                <Button
                    onClick={() => { navigate('/Orders') }}
                    label="Back"
                    className="w-[10rem] ml-0"
                    variant="brand-inverse"
                />
            </div>
        </div>
    )
}

export default OrdersDetails
