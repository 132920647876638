import Button from 'components/button/button.component'
import { metaDummy } from '../../trackings.dummy'
import { notesData } from '../trackings-details.static'
import SimpleTable from 'components/simple-table/simple-table.component'
import Textarea from 'components/text-area/text-area.component'
import Dropdown from 'components/dropdown/dropdown.component'
import { useTrackingDetails } from '../trackings-details.service'
import { INotesTracking } from '../../trackings.interface'
import { useTranslation } from 'react-i18next'
const Notes = () => {
    const { allModalNotes, headersNotes, ConfigureColumnsModalServiceNotes } =
        useTrackingDetails()
    const { t } = useTranslation()
    return (
        <div className="h-[calc(100vh-13.5rem)] grid grid-cols-12">
            <div className="col-span-9">
                <SimpleTable<INotesTracking>
                    headers={headersNotes}
                    data={notesData}
                    usePagination
                    nextHandling={(page) => {
                        console.log('hit Next API -> ' + page)
                    }}
                    previousHandling={(page) => {
                        console.log('hit Prev API -> ' + page)
                    }}
                    meta={metaDummy}
                    additionalButtonBottom={
                        <>
                            <Button
                                onClick={() =>
                                    ConfigureColumnsModalServiceNotes.openModalHandling()
                                }
                                label="Configure Column"
                                className="w-[10rem] ml-0"
                                variant="brand-inverse"
                            />
                        </>
                    }
                />
                {allModalNotes}
            </div>
            <div className="col-span-3 border-y-2 flex flex-col justify-between">
                <div>
                    <div className="flex justify-between items-center px-3 py-2 text-[16px] border-b-2">
                        <span className="themes-text-gray-v5 ">
                            {t('tracking.selected_notes')}
                        </span>
                        <div className="text-[24px] flex">
                            <div className="border-x-2 px-2">
                                <i className="ri-file-copy-line themes-text-hover-green-v3" />
                            </div>
                            <div className="pl-2">
                                <i className="ri-delete-bin-line themes-text-hover-red-v3" />
                            </div>
                        </div>
                    </div>
                    <div className="px-3 py-3">
                        <Textarea
                            containerClassName={`h-full mb-3`}
                            value={'Compact, thorough notes for goods'}
                            onChange={() => { }}
                            label={t('tracking.note_text')}
                            placeholder={t('tracking.note_text')}
                            className="w-full min-h-[100px]"
                            resize
                        />
                        <Dropdown
                            options={[
                                {
                                    label: 'Detailed Goods Description',
                                    value: 'Detailed Goods Description',
                                },
                            ]}
                            label={t('tracking.description')}
                            onClick={() => { }}
                            placeholder={t('tracking.description')}
                            isClearable={true}
                            isMultiSelect={true}
                            isSearchable={true}
                        />
                    </div>
                </div>
                <div className="flex flex-col gap-2 p-3 border-t-2">
                    <Button
                        onClick={() => { }}
                        label={t('action.save_changes')}
                        className="w-full"
                        variant="brand"
                    />
                    <Button
                        onClick={() => { }}
                        label={t('action.discard_changes')}
                        className="w-full"
                        variant="red-inverse"
                    />
                </div>
            </div>
        </div>
    )
}

export default Notes
