import React, { FC, useMemo } from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { ISimpleLineChartProps } from '../rechart-interface';

const SimpleLineChart: FC<ISimpleLineChartProps> = ({ data, colorPV, fillPV, colorUV, fillUV, colorUT, fillUT }) => {
    const memoizedData = useMemo(() => data, [data]);

    return (
        <ResponsiveContainer width="100%" height="100%">
            <AreaChart
                width={500}
                height={300}
                data={memoizedData}
                margin={{ top: 10, right: 15, left: -25, bottom: -5 }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                {colorPV && fillPV && (
                    <Area type="monotone" dataKey="pv" stroke={colorPV} fill={fillPV} />
                )}
                {colorUV && fillUV && (
                    <Area type="monotone" dataKey="uv" stroke={colorUV} fill={fillUV} />
                )}
                {colorUT && fillUT && (
                    <Area type="monotone" dataKey="ut" stroke={colorUT} fill={fillUT} />
                )}
            </AreaChart>
        </ResponsiveContainer>
    );
};

export default SimpleLineChart;