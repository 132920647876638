import Button from 'components/button/button.component'
import ProgressionStep from 'components/progression-step/progression-step.component'
import { Toast } from 'components/toast/toast.component'
import { useNavigate } from 'react-router-dom'
import useCreateBooking from './create-booking.service'
import { items } from './create-booking.static'

const CreateBooking = () => {
    const navigate = useNavigate()
    const {
        stepFilter,
        setStepFilter,
        getContentCreateBooking,
        nextCompo,
        previousCompo,
    } = useCreateBooking()

    return (
        <>
            <div
                className={`booking-create flex flex-wrap gap-x-4 h-[calc(100vh-105px)] transition-opacity duration-500`}
            >
                <div className="flex-grow flex-col md:gap-y-4 gap-x-4 md:w-[calc(100vw-900px)] themes-bg-white container-border container-global overflow-x-hidden overflow-y-hidden">
                    <div className="flex w-full justify-between items-center p-4">
                        <div className="flex">
                            <span className="flex text-size-L font-bold">
                                CREATE BOOKING
                            </span>
                        </div>
                        <div className="flex">
                            <ProgressionStep
                                steps={items}
                                onChange={(step) => setStepFilter(step)}
                                selectedItem={stepFilter}
                            />
                        </div>
                        <div className="flex w-[160px]"></div>
                    </div>
                    <div className="border-b themes-border-color" />
                    {getContentCreateBooking(stepFilter)}
                    <div className="border-b themes-border-color" />
                    <div className="flex w-full p-4 justify-between">
                        <div className="flex">
                            <Button
                                className="w-btnRegularWidth"
                                label="BACK"
                                onClick={() => {
                                    navigate(-1)
                                }}
                            />
                        </div>
                        <div className="flex gap-4">
                            {stepFilter.value === 'address' ? (
                                ''
                            ) : (
                                <Button
                                    className="w-btnRegularWidth"
                                    label="PREVIOUS"
                                    onClick={() => {
                                        previousCompo()
                                    }}
                                    icon="ri-arrow-left-line"
                                />
                            )}
                            <Button
                                className="w-btnRegularWidth !text-size-S"
                                label={
                                    stepFilter.value === 'documents'
                                        ? 'SUBMIT'
                                        : 'NEXT'
                                }
                                onClick={() => {
                                    if (stepFilter.value === 'documents') {
                                        Toast({
                                            header: 'BOOKING CREATED',
                                            message: `Booking is created.\nShipment has booked.`,
                                            type: 'success',
                                        })
                                    }
                                    stepFilter.value === 'documents'
                                        ? navigate(-1)
                                        : nextCompo()
                                }}
                                iconSuffix={
                                    stepFilter.value === 'documents'
                                        ? ''
                                        : 'ri-arrow-right-line'
                                }
                                variant="brand"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default CreateBooking
