import { useState } from 'react'
import Details from './content/details.component'
import { ITabItem } from 'components/tab/tab.interface'
import { useModal } from 'components/modal/modal.service'
import EDocs from './content/e-docs.component'
import ConfigureColumnsModal from 'components/configure-columns-modal/configure-columns-modal.component'
import {
    IContainers,
    IDocumentsReceipts,
    ILines,
    IMilestones,
    IEvents,
    IReletedInvoice,
} from '../receipts.interface'
import { ISTColumn } from 'components/simple-table/simple-table.interface'
import {
    ContainersConfigureColumns,
    ContainersHeader,
    DocumentsReceiptsConfigureColumns,
    DocumentsReceiptsHeader,
    EventsConfigureColumns,
    EventsHeader,
    LinesConfigureColumns,
    LinesHeader,
    MilestonesConfigureColumns,
    MilestonesHeader,
    ReletedInvoiceConfigureColumns,
    ReletedInvoiceHeader,
} from './receipts-details.static'
import Documents from './content/documents.component'
import Containers from './content/containers.component'
import Lines from './content/lines.component'
import Milestones from './content/milestones.component'
import Event from './content/event.component'
import ReletedInvoice from './content/related-invoice.component'
import Message from './content/message.component'
import { IDropdownItem } from 'components/dropdown/dropdown.interface'

export const useReceiptsDetails = () => {
    const [headersDocuments, setHeadersDocuments] = useState<
        ISTColumn<IDocumentsReceipts>[]
    >(DocumentsReceiptsHeader)
    const [headersContainers, setHeadersContainers] =
        useState<ISTColumn<IContainers>[]>(ContainersHeader)
    const [headersLines, setHeadersLines] =
        useState<ISTColumn<ILines>[]>(LinesHeader)
    const [headersReletedInvoice, setHeadersReletedInvoice] =
        useState<ISTColumn<IReletedInvoice>[]>(ReletedInvoiceHeader)
    const [headersMilestones, setHeadersMilestones] =
        useState<ISTColumn<IMilestones>[]>(MilestonesHeader)
    const [headersEvents, setHeadersEvents] =
        useState<ISTColumn<IEvents>[]>(EventsHeader)
    const [fromPeople, setFromPeople] = useState<IDropdownItem<undefined>[]>([])

    const updatePeople = (addPeople: IDropdownItem<undefined>) => {
        if (
            addPeople &&
            !fromPeople.some((person) => person.value === addPeople.value)
        ) {
            setFromPeople((prevPeople) => [...prevPeople, addPeople])
        }
    }

    const removePerson = (person: IDropdownItem<undefined>) => {
        setFromPeople((prevPeople) =>
            prevPeople.filter((p) => p.value !== person.value),
        )
    }

    const [tabFilter, setTabFilter] = useState<ITabItem>({
        label: 'View Receipts Details',
        key: 'status',
        value: 'details',
        className: 'brand',
    })

    // modal
    const ConfigureColumnsModalServiceDocuments = useModal()
    const ConfigureColumnsModalServiceContainers = useModal()
    const ConfigureColumnsModalServiceLines = useModal()
    const ConfigureColumnsModalServiceReletedInvoice = useModal()
    const ConfigureColumnsModalServiceMilestones = useModal()
    const ConfigureColumnsModalServiceEvents = useModal()

    const getContentReceiptsDetails = (tabFilter: ITabItem) => {
        const items: Record<string, JSX.Element> = {
            details: Details(),
            container: Containers(),
            lines: Lines(),
            relatedInvoices: ReletedInvoice(),
            documents: Documents(),
            eDocs: EDocs(),
            messages: Message(),
            milestones: Milestones(),
            events: Event(),
        }
        return items[tabFilter?.value] || <></>
    }

    // // segment general
    const allModalDocuments = (
        <>
            <ConfigureColumnsModal<IDocumentsReceipts>
                modalService={ConfigureColumnsModalServiceDocuments}
                columns={DocumentsReceiptsConfigureColumns}
                setHeaders={setHeadersDocuments}
            />
        </>
    )

    const allModalContainers = (
        <>
            <ConfigureColumnsModal<IContainers>
                modalService={ConfigureColumnsModalServiceContainers}
                columns={ContainersConfigureColumns}
                setHeaders={setHeadersContainers}
            />
        </>
    )

    const allModalLines = (
        <>
            <ConfigureColumnsModal<ILines>
                modalService={ConfigureColumnsModalServiceLines}
                columns={LinesConfigureColumns}
                setHeaders={setHeadersLines}
            />
        </>
    )

    const allModalReletedInvoice = (
        <>
            <ConfigureColumnsModal<IReletedInvoice>
                modalService={ConfigureColumnsModalServiceReletedInvoice}
                columns={ReletedInvoiceConfigureColumns}
                setHeaders={setHeadersReletedInvoice}
            />
        </>
    )

    const allModalMilestones = (
        <>
            <ConfigureColumnsModal<IMilestones>
                modalService={ConfigureColumnsModalServiceMilestones}
                columns={MilestonesConfigureColumns}
                setHeaders={setHeadersMilestones}
            />
        </>
    )

    const allModalEvents = (
        <>
            <ConfigureColumnsModal<IEvents>
                modalService={ConfigureColumnsModalServiceEvents}
                columns={EventsConfigureColumns}
                setHeaders={setHeadersEvents}
            />
        </>
    )

    return {
        getContentReceiptsDetails,
        setTabFilter,
        tabFilter,
        // Documents
        allModalDocuments,
        headersDocuments,
        ConfigureColumnsModalServiceDocuments,
        //Containers
        allModalContainers,
        headersContainers,
        ConfigureColumnsModalServiceContainers,
        // Lines
        allModalLines,
        headersLines,
        ConfigureColumnsModalServiceLines,
        //ReletedInvoice
        allModalReletedInvoice,
        headersReletedInvoice,
        ConfigureColumnsModalServiceReletedInvoice,
        //Milestones,
        allModalMilestones,
        headersMilestones,
        ConfigureColumnsModalServiceMilestones,
        //Events
        allModalEvents,
        headersEvents,
        ConfigureColumnsModalServiceEvents,
        //massage
        fromPeople,
        updatePeople,
        removePerson,
    }
}
