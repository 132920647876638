import { useLogin } from "./login.service"
import LoginPage from "./common/login-page.component"
import IFBLoginPage from "./ifb/ifb-login-page.component"

const Login = (): JSX.Element => {
    const { client } = useLogin()
    if (client === '') {
        return <></>
    } else if (client === 'ifb') {
        return <IFBLoginPage />
    } else {
        return <LoginPage />

    }

}

export default Login