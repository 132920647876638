import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'store'
import { INewsData, IScfiData } from './dashboard.interface';

interface IDashboardState {
    news: INewsData[];
    scfi_index: IScfiData[];
}

export const initialState: IDashboardState = {
    news: [],
    scfi_index: [],
}

const dashboardSlice = createSlice({
    name: 'Dashboard',
    initialState,
    reducers: {
        setNews: (state, action: PayloadAction<INewsData[]>) => {
            state.news = action.payload;
        },
        setScfiIndex: (state, action: PayloadAction<IScfiData[]>) => {
            state.scfi_index = action.payload;
        }
    },
})

export const { setNews, setScfiIndex } = dashboardSlice.actions

export const newsSelector = (state: RootState) => state.dashboardIFB.news
export const scfiIndexSelector = (state: RootState) => state.dashboardIFB.scfi_index

export default dashboardSlice.reducer
