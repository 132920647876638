import FormDropdown from 'components/form-dropdown/form-dropdown.component'
import FormInput from 'components/form-input/form-input.component'
import FormMeasurementInput from 'components/form-measurement-input/form-measurement-input.component'
import FormTextarea from 'components/form-textarea/form-textarea.component'
import { useTranslation } from 'react-i18next'

const BookingInformation = () => {
    const { t } = useTranslation()
    return (
        <div>
            <div>{t('bookings.booking_information')}</div>
            <div className={`grid grid-rows-11 grid-flow-col gap-3 mt-3`}>
                <FormInput
                    label={t('bookings.shippers_ref')}
                    name={`shippersRef`}
                    placeholder={t('action.enter', {
                        value: t('bookings.shippers_ref'),
                    })}
                    parentDivClassName="!mb-0"
                />
                <FormInput
                    label={t('bookings.service_level')}
                    name={`serviceLevel`}
                    placeholder={t('action.enter', {
                        value: t('bookings.service_level'),
                    })}
                    parentDivClassName="!mb-0 "
                />
                <FormInput
                    label={t('bookings.incoterm')}
                    name={`incoTerm`}
                    placeholder={t('action.enter', {
                        value: t('bookings.incoterm'),
                    })}
                    parentDivClassName="!mb-0 "
                />
                <FormInput
                    label={t('bookings.additional_terms')}
                    name={`additionalTerms`}
                    placeholder={t('action.enter', {
                        value: t('bookings.additional_terms'),
                    })}
                    parentDivClassName="!mb-0 "
                />
                <FormInput
                    label={t('bookings.goods_value')}
                    name={`goodsValue`}
                    placeholder={t('action.enter', {
                        value: t('bookings.goods_value'),
                    })}
                    parentDivClassName="!mb-0"
                />
                <FormDropdown
                    label={t('bookings.goods_value_currency')}
                    name={`goodsValueCurrency`}
                    placeholder={t('action.select', {
                        value: t('bookings.goods_value_currency'),
                    })}
                    parentDivClassName="!mb-0"
                    options={[
                        {
                            label: 'AUD - Australian Dollar',
                            value: 'aud',
                        },
                    ]}
                />
                <FormInput
                    label={t('bookings.insurance_value')}
                    name={`insuranceValue`}
                    placeholder={t('action.enter', {
                        value: t('bookings.insurance_value'),
                    })}
                    parentDivClassName="!mb-0"
                />
                <FormDropdown
                    label={t('bookings.insurance_value_currency')}
                    name={`insuranceValueCurrency`}
                    placeholder={t('action.select', {
                        value: t('bookings.insurance_value_currency'),
                    })}
                    parentDivClassName="!mb-0"
                    options={[
                        {
                            label: 'AUD - Australian Dollar',
                            value: 'aud',
                        },
                    ]}
                />
                <FormInput
                    label={t('bookings.packs')}
                    name={`packs`}
                    placeholder={t('action.enter', {
                        value: t('bookings.packs'),
                    })}
                    parentDivClassName="!mb-0"
                />
                <FormDropdown
                    label={t('bookings.outer_pack_unit')}
                    name={`outerPackUnit`}
                    placeholder={t('action.select', {
                        value: t('bookings.outer_pack_unit'),
                    })}
                    parentDivClassName="!mb-0"
                    options={[
                        {
                            label: 'PLT - Pallet',
                            value: 'plt',
                        },
                    ]}
                />
                <FormMeasurementInput
                    options={[{ label: 'Cubic Meters', value: 'M3' }]}
                    name={'volumeMeasure'}
                    label={t('bookings.volume_measure')}
                    parentDivClassName=""
                    placeholder={t('action.enter', {
                        value: t('bookings.volume_measure'),
                    })}
                />
                <FormMeasurementInput
                    options={[{ label: 'Kilogram', value: 'KG' }]}
                    name={'weightMeasure'}
                    label={t('bookings.weight_measure')}
                    parentDivClassName=""
                    placeholder={t('action.enter', {
                        value: t('bookings.weight_measure'),
                    })}
                />
                <FormTextarea
                    name={'goodsDescription'}
                    label={t('bookings.goods_description')}
                    parentDivClassName="row-span-2"
                    placeholder={t('action.enter', {
                        value: t('bookings.goods_description'),
                    })}
                />
                <FormTextarea
                    name={'marksAndNumbers'}
                    label={t('bookings.marks_and_numbers')}
                    parentDivClassName="row-span-2"
                    placeholder={t('action.enter', {
                        value: t('bookings.marks_and_numbers'),
                    })}
                />
            </div>
        </div>
    )
}

export default BookingInformation
