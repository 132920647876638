export const jplEndpoints = {
    // po
    po_getAll: 'Po/List',
    po_bulkUpdateToOpen: 'Po/StatusBulk/Open',
    po_getDetail: 'Po',
    po_getFilterDropdownData: 'Po/filter-filling',
    po_sync: 'Po/UpdatePoShipments',
    po_lineGoods: 'Po/poline/goods',
    po_panRoute: 'PanRoute',

    // shipment
    shipment_getAll: 'UniversalShipment',
    shipment_getFilterDropdownOptions: 'UniversalShipment/GetDropdownData',
    shipment_updateStatuShipment: 'UniversalShipment/UpdateStatus/',
    shipment_updateInvoice: 'UniversalShipment/UpdateInvoice',
    shipment_updateRouteCode: 'UniversalShipment/UpdateRouteCode',
    shipment_uploadEDocs: 'UniversalShipment/upload',
    shipment_downloadEDocs: 'UniversalShipment/download',
    shipment_deleteEDocs: 'UniversalShipment/deleteFile/',

    // attach po
    attach_po: 'Po/Shipment',
}
