import CardsDashboard from 'components/card-dashboard/card-dashboard.component'
import MapWithTable from './map-with-table.component'
import TableOpenPO from './table-open-po.component'
import BarChartAnalytics from './bar-chart-analytics.component'
import { useSelector } from 'react-redux'
import { newsSelector } from '../dashboard.slice'
import useDashboard from '../dashboard.service'

export default function Row2() {
    const news = useSelector(newsSelector)
    const { isLoading } = useDashboard()

    return (
        <>
            {/* Map & Table */}
            <CardsDashboard className="2xl:row-span-12 lg:row-span-12 sm:col-span-12 col-span-2 !p-0">
                <MapWithTable />
            </CardsDashboard>

            {/* (Bar Chart) Departure Accuracy Analytics */}
            <CardsDashboard className="2xl:row-span-6 lg:row-span-6 col-span-2 sm:col-span-12 !pb-3">
                <BarChartAnalytics />
            </CardsDashboard>

            {/* (Table) Open PO Lists */}
            <CardsDashboard className="2xl:row-span-6 lg:row-span-6 col-span-2 sm:col-span-12">
                <TableOpenPO data={news || []} isLoading={isLoading} />
            </CardsDashboard>
        </>
    )
}
